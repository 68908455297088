<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- <SmileTwoTone style="fontSize:50px" /> -->
  <div :style="homeView"
       style='background-color:#ccc'>

    <div class="home-data">
      <!-- <div><a @click="updatePage">刷新</a></div> -->

      <div class="home-left-box">
        <!-- <div class='time-show'>{{ formattedTime }}</div> -->
        <div class='time-show'>
          <a-cascader style="width:auto"
                      :showArrow="false"
                      :bordered="false"
                      v-model:value="storeValue"
                      :options="options.contract"
                      placeholder="请选择门店"
                      @change='onContractChange' />

        </div>

        <div id="pieChart"
             class='left-box-pieChart'></div>
        <div class='left-card-box'>
          <div style="width: 100%;height:100%;padding-bottom:5px;background-color: white; ">
            <a-table style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;"
                     :columns="orderColumns"
                     :data-source="orderRef"
                     :pagination="false">

            </a-table>

          </div>

        </div>

      </div>
      <div class="home-right-box">
        <div class="center-container">
          <div style="width: 50%; height: 100%;padding-right:5px">
            <a-card title="门店地理位置"
                    :bodyStyle="bodyStyle"
                    size="small"
                    style="width: 100%;height:100%;">
              <!-- <template #extra><a href="#">more</a></template> -->
              <template #extra>
                <a @click='storeOverview'>总览</a>
              </template>
              <div id="map"
                   ref="map"
                   style="width: 100%; height: 90%"></div>
            </a-card>
          </div>
          <div style="width: 50%; height: 100%;padding-left:5px">
            <a-card size="small"
                    id='portCard'
                    style="width: 100%;height:100%"
                    :bodyStyle="bodyStyle"
                    title="端口流量"
                    :tab-list="tabList"
                    :active-tab-key="key"
                    @tabChange="key => onTabChange(key)">
              <div id='ethFlowChart'
                   style="height:100%"></div>
              <template #extra>
                <a href="#"></a>
              </template>
              {{ contentList[key] }}
            </a-card>
            <!-- <div class="box-title">端口流量</div> -->
            <!-- <div style='display:flex;align-items:center'>
              <div><a-cascader style="width:auto;margin-left:40px" :showArrow="false" :bordered="false"
                  v-model:value="storeValue" :options="options.contract" placeholder="请选择门店"
                  @change='onContractChange' /></div>
            </div> -->

          </div>
        </div>

        <div class='alarm-info'>

          <a-table style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;"
                   :columns="columns"
                   :data-source="data"
                   :pagination="false">

          </a-table>
        </div>
      </div>
    </div>
  </div>

</template>
  
<script setup>
import * as echarts from 'echarts';
import serviceAxios from '@/utils/serviceAxios';
import interfaces from '@/config';
import { reactive, ref, toRefs, onMounted, nextTick, markRaw, onUnmounted, watch } from 'vue';
import { message } from 'ant-design-vue';
import majiyongLogo from '@/assets/majiyongLogo.png';
import { useRouter, useRoute } from 'vue-router'
import bluePoint from '@/assets/bluePoint.png';
import { getStoreGatewayStatus } from '@/utils/getStoreGatewayStatus'
const route = useRoute();
const router = useRouter();
const bodyStyle = {
  width: "100%",
  height: '95%'
}


let ethFlowChart = '';
const MonitoringData = reactive({
  systemRunTime: "", //系统运行时间
  totalMemory: "", //总内存
  useMemory: "", //使用内存
  pingCheck: "",//ping检查
  deviceConnectivityChecking: "",//设备连通性检测

  eth0InterfaceStatus: "", //eth0接口状态

  memoryUtilization: [],//内存利用率
  memoryUtilizationDate: [],//内存利用率时间
  pingDelay: [],//ping响应延时
  pingLoss: [],//ping丢包
})
const ethFlowChartOption = {
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLabel: { // x轴标签
      interval: 0,
      formatter: function (value, index) {
        const originalData = ethFlowChartOption.xAxis.data;
        const dataLength = originalData.length;
        if (index === 0 || index === dataLength - 1 || index === Math.floor(dataLength / 2)) {
          return value;
        }
        return '';
      }

    },

  },
  yAxis: {
    type: 'value',
    name: "(bps)",

  },
  legend: {
    data: []
  },
  series: [],
  tooltip:
  {
    show: true
  },

};
let storeIds = ref([])
const initStoreId = (storeTree) => {
  if (storeTree.children != undefined) {
    storeIds.value.push(storeTree.value)
    initStoreId(storeTree.children[0])
  }
  else {
    storeIds.value.push(storeTree.value)
  }
}


// 定义存储格式化后时间的响应式变量
const formattedTime = ref('');

// 更新时间的函数
const updateTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  formattedTime.value = `${hours}:${minutes}:${seconds}`;
};




const windowWidth = ref(0);
const windowHeight = ref(0);
const homeView = ref("")

onMounted(() => {
  // 初始化屏幕尺寸
  updateWindowSize();
  // 添加 resize 事件监听器
  window.addEventListener('resize', updateWindowSize);

  initMap()
  get_statistical_data()
});

// 更新屏幕尺寸的函数
const updateWindowSize = () => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
  console.log(window.innerWidth, window.innerHeight)
  homeView.value = {
    // width: (window.innerWidth - 180) + 'px',
    height: (window.innerHeight - 100) + 'px'

  }

  console.log(homeView.value)

  let chartHeight = window.innerHeight / 2 - 150 + 'px'

  ethFlowChart = echarts.init(document.getElementById('ethFlowChart'))  //markRaw()
  ethFlowChart.resize({ height: chartHeight })

  pieChart = echarts.init(document.getElementById('pieChart'));
  console.log('pieChart111', pieChart)
  pieChart.resize()

};


onUnmounted(() => {
  clearInterval(updateTime);
  window.removeEventListener('resize', updateWindowSize);
});



const mapContainer = ref(null);
let map = null;
let mapInstance = ref()  //地图实例
const markerGeometries = [] //标记点
const coords = [] //扩充点 用于显示所有的点标记


//初始化地图
const initMap = async () => {
  //获取正常门店的数组
  const onlineStatus = await getStoreGatewayStatus()
  const onlineStatusList = onlineStatus['goodStoreIds']
  //获取所有门店的坐标数据
  serviceAxios({
    url: interfaces.coordinateAll,
    method: 'get'
  }).then(
    result => {
      console.log('result', result)
      if (result.data.message == 'success') {

        let labelGeometries = []
        let data = result.data.data
        data.forEach(item => {
          console.log('onlineStatusList.includes(item.id)', onlineStatusList.includes(item.id))
          //扩充点
          coords.push(
            new TMap.LatLng(item.location.lat, item.location.lng),
            new TMap.LatLng(item.location.lat + 0.01, item.location.lng + 0.01),
            new TMap.LatLng(item.location.lat - 0.01, item.location.lng - 0.01),
          )

          //点标记数据
          markerGeometries.push({
            "id": item.id,
            "styleId": onlineStatusList.includes(item.id) ? 'blueStyle' : "redStyle",
            "position": new TMap.LatLng(item.location.lat, item.location.lng),
            "properties": {
              "title": "marker2"
            }
          }
          )
          //标签数据
          labelGeometries.push({
            'id': item.id,
            'styleId': onlineStatusList.includes(item.id) ? 'blueStyle' : "redStyle",//样式id
            'position': new TMap.LatLng(item.location.lat, item.location.lng),
            'content': item.name,
          })
        })
        var center = new window.TMap.LatLng(31.140696, 121.358921)
        mapInstance.value = new window.TMap.Map("map", {
          zoom: 10,
          viewMode: '2D',
          // center: center,
          baseMap: {
            type: 'vector'
          }
        });
        console.log("mapInstance", mapInstance)

        //地图标记
        var markerLayer = new TMap.MultiMarker({
          map: mapInstance.value,
          //样式定义
          styles: {
            redStyle: new TMap.MarkerStyle({
              width: 25,
              height: 35,
              size: 35,
              anchor: { x: 16, y: 32 }
            }),
            blueStyle: new TMap.MarkerStyle({
              width: 25,
              height: 35,
              size: 35,
              src: bluePoint,
              anchor: { x: 16, y: 32 }
            })
          },
          //点标记数据数组
          geometries: markerGeometries
        });

        //地图文字标记
        var label = new TMap.MultiLabel({
          id: 'label-layer',
          map: mapInstance.value, //设置折线图层显示到哪个地图实例中
          //文字标记样式
          styles: {
            blueStyle: new TMap.LabelStyle({
              color: '#40514e', // 文字颜色
              size: 15, // 文字大小
              fontFamily: 'Arial, sans-serif', // 字体家族
              fontWeight: 'bold', // 字体粗细
              textShadow: { // 文字阴影
                color: '#333333', // 阴影颜色
                blur: 3, // 阴影模糊半径
                offset: { x: 1, y: 1 } // 阴影偏移
              },
              offset: { x: 0, y: 0 }, // 文字偏移，y 值为负数表示向上偏移
              angle: 0, // 文字旋转角度
              alignment: 'center', // 水平对齐方式
              verticalAlignment: 'middle', // 垂直对齐方式
            }),
            redStyle: new TMap.LabelStyle({
              color: '#e23e57', // 文字颜色
              size: 15, // 文字大小
              fontFamily: 'Arial, sans-serif', // 字体家族
              fontWeight: 'bold', // 字体粗细
              textShadow: { // 文字阴影
                color: '#333333', // 阴影颜色
                blur: 3, // 阴影模糊半径
                offset: { x: 1, y: 1 } // 阴影偏移
              },
              offset: { x: 0, y: 0 }, // 文字偏移，y 值为负数表示向上偏移
              angle: 0, // 文字旋转角度
              alignment: 'center', // 水平对齐方式
              verticalAlignment: 'middle', // 垂直对齐方式
            }),
          },
          //文字标记数据
          geometries: labelGeometries
        });

        //标记点点击事件  点击后跳转到门店详情
        markerLayer.on('click', function (evt) {
          console.log('evt', evt)
          let ids = []
          serviceAxios({
            url: interfaces.storeGetGatewayId,
            method: 'get',
            params: {
              store_id: evt.geometry.id

            }
          }).then(result => {
            if (result.data.message == 'success') {
              try {
                let data = result.data.data.gateway.network_interfaces
                console.log('data', data)
                data.forEach(item => {
                  ids.push(item.id)
                })
                ids = ids.join(',')
                router.push({

                  path: "/show",
                  query: { ids: ids, storeId: evt.geometry.id, gatewayId: result.data.data.gateway.id }

                })

              }
              catch (error) {

                console.log(error)

                message.error('该门店未绑定网关探测事件')
              }
            }
            else {

              message.error('该门店未绑定网关探测事件')
            }

          }).catch(
            error => {
              console.log(error)
            }
          )
        })

        //显示所有的标记点
        var latlngBounds = new TMap.LatLngBounds();
        //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
        for (var i = 0; i < coords.length; i++) {
          latlngBounds.extend(coords[i]);
        }

        storeGetTree()  //获取地区门店树

        //调用fitBounds自动调整地图显示范围
        mapInstance.value.fitBounds(latlngBounds);

      }

    }
  ).catch(
    error => {
      console.log
    }
  )
}

//显示所有的标记点
const storeOverview = () => {
  var latlngBounds = new TMap.LatLngBounds();
  //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
  for (var i = 0; i < coords.length; i++) {
    latlngBounds.extend(coords[i]);
  }
  //调用fitBounds自动调整地图显示范围
  mapInstance.value.fitBounds(latlngBounds);
}


//饼图数据
let pieChart = '';
const option = {
  title: {
    text: '主机可用性',
    subtext: '',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    top: "30%"
  },
  series: [
    {
      name: '主机可用性',
      type: 'pie',
      radius: '50%',
      center: ['60%', '50%'],
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: true, // 修改为true以显示标签
        position: 'center',
        formatter: function (params) {
          // 计算所有数据的总和
          const total = option.series[0].data.reduce((sum, item) => sum + item.value, 0);
          return `总数:${total}`;
        },
        textStyle: {
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        // { value: 1888, name: '客户的总数量' },
        { value: 88, name: '可用', itemStyle: { color: '#66E0E3' } },
        { value: 888, name: '不可用' },
        { value: 38, name: '未知' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
const get_statistical_data = () => {
  serviceAxios({
    url: interfaces.get_statistical_data,
    method: "post",
    data: {}
  }).then(result => {
    option.series[0].data[0].value = parseInt(result.data.data.online, 10)
    option.series[0].data[1].value = parseInt(result.data.data.offline, 10)
    option.series[0].data[2].value = parseInt(result.data.data.unknown, 10)


    pieChart.setOption(option);
  }).catch(error => {
    console.log(error)
    // message.error('请求异常，请联系管理员')
  })
}



//表格数据
const columns = [
  { title: '时间', dataIndex: 'alertTime', key: 'alertTime' },
  { title: '门店', dataIndex: 'storeName', key: 'storeName' },
  { title: '告警信息', dataIndex: 'alertMessage', key: 'alertMessage' },
  { title: '当前状态', dataIndex: 'alertStatus', key: 'alertStatus' },
  { title: '告警地址', dataIndex: 'alertIp', key: 'alertIp' },
  { title: '持续时长', dataIndex: 'duration', key: 'duration' },

]
const data = ref()
const get_home_data = () => {
  serviceAxios({
    url: interfaces.alertHistories,
    method: "get",
    data: {
      store_id: '',
      limit: 20,
    }
  }).then(result => {
    // homeData.forewarning_data = result.data.data
    data.value = result.data.data.map((item) => ({
      alertTime: item.alert_time,
      storeName: item.host_name,
      alertStatus: item.message.alert_type,
      alertIp: item.host_ip,
      // alertLevel: item.message['告警等级'],
      alertMessage: item.message['Problem name'],
      duration: item.message['Problem duration']
      // alertData: item.message['监控取值'],
    }))
  }).catch(error => {
    console.log(error)
    // message.error('请求异常，请联系管理员')
  })
}


//工单数据
const orderRef = ref()
const orderColumns = [
  { title: '门店', dataIndex: 'storeName', key: 'storeName' },
  { title: '工单状态', dataIndex: 'status', key: 'status' },
  { title: '创建时间', dataIndex: 'createTime', key: 'createTime' },


]

const getOrder = () => {
  serviceAxios({
    url: interfaces.constmerOrderPage,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let orderList = []
    result.data.data.list.forEach(item => {
      orderList.push({
        storeName: item.contract_user_name,
        createTime: item.create_time,
        status: item.status,
      })
    })
    orderRef.value = orderList
  }).catch(error => {
    console.log(error)
  })
}
getOrder()
get_home_data()

const updatePage = () => {
  get_home_data()
  get_statistical_data()
  message.success("刷新成功")
}

let legend = []



const options = reactive({
  contract: []
})


//
const storeGetTree = () => {
  serviceAxios({
    url: '/v1/tenant/store/get_tree',
    method: 'get'
  }).then(
    result => {
      let data = result.data.data.children
      data = transformKeys(data)

      options.contract = data
      console.log('options.contract', options.contract)
      initStoreId(options.contract[0])
      console.log('storeIds', storeIds.value)
      storeValue.value = storeIds.value
      onContractChange(storeValue.value)
    }
  ).catch(error => {
    console.log(error)
    // message.error("请求异常，请联系管理员")
  })
}


function transformKeys (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // 如果不是对象或为null，直接返回
  }

  if (Array.isArray(obj)) {
    return obj.map(transformKeys); // 如果是数组，则递归处理每个元素
  }

  const newObj = {}; // 创建一个新的对象来存放转换后的键值对
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey = key; // 初始化新键名为原键名

      // 根据键名进行替换
      if (key === 'name') newKey = 'label';
      else if (key === 'id') newKey = 'value';
      else if (key === 'stores' && obj['children'] == undefined) newKey = 'children';

      newObj[newKey] = transformKeys(obj[key]); // 递归处理值，如果值也是对象或数组
    }
  }

  return newObj;
}
const storeValue = ref()
const onContractChange = (value) => {
  console.log(value)
  const centerItem = markerGeometries.filter(item => item.id == value[value.length - 1])
  console.log("centerItem", centerItem)
  let position = centerItem[0].position
  mapInstance.value.panTo(new TMap.LatLng(position.lat, position.lng))
  let storeId = value[value.length - 1]
  serviceAxios({
    url: interfaces.storeGetGatewayId,
    method: 'get',
    params: {
      store_id: storeId
    }
  })
    .then(result => {
      if (result.data.message == 'success') {
        let interfacesIds = []
        try {
          result.data.data.gateway.network_interfaces.forEach(item => {
            interfacesIds.push(item.id)
          })
          ethFlowHistory(interfacesIds)
          console.log(interfacesIds)
        }
        catch (error) {
          console.log(error)
          message.error('该门店未配置接口数据，无法探测流量信息')
        }

      }
      else {
        message.error(result.data.message)
      }
    }).catch(
      error => {
        console.log(error)
      }
    )


}



let chartData = ref()
const ethFlowHistory = (ids) => {
  serviceAxios({
    url: interfaces.ethFlowHistory,
    method: 'post',
    data: {
      physical_interface_ids: ids
    }
  }).then(result => {

    chartData.value = result.data.data
    tabList.value = []
    for (let ethKey in result.data.data) {
      // console.log("ethKey", ethKey)
      tabList.value.push({
        key: ethKey,
        tab: ethKey,
      })
      for (let ethKeyItem in result.data.data[ethKey]) {
        chartData.value[ethKey][ethKeyItem] = chartData.value[ethKey][ethKeyItem]
      }
    }
    console.log("data.value", chartData.value)


    key.value = tabList.value[0].key

    ethFlowChartOption.yAxis = {
      type: 'value',
      name: 'KB/s',

    }
    ethFlowChartOption.series = []
    ethFlowChartOption.legend.data = []
    for (let key in chartData.value[tabList.value[0].key]) {
      ethFlowChartOption.legend.data.push(key)
      let seriesData = {
        name: key,
        type: 'line',
        // symbol: "none",
        symbolSize: 2,
        label: {
          normal: {
            show: false, // 默认情况下不显示标签
            position: 'top' // 标签显示在数据点上方
          },
          emphasis: {
            show: true // 当鼠标悬停在数据点上时显示标签
          }
        },
        data: [],
      }

      chartData.value[tabList.value[0].key][key].forEach(element => {
        seriesData.data.push([element.time.substring(element.time.indexOf(" ") + 1), element.value])
      });
      ethFlowChartOption.series.push(seriesData)

      ethFlowChartOption.xAxis.data = []

      ethFlowChartOption.series[0].data.forEach(item => {
        ethFlowChartOption.xAxis.data.push(item[0])
      })

    }
    console.log('ethFlowChartOption', ethFlowChartOption)
    console.log('ethFlowChart', ethFlowChart == true)



    // ethFlowChart = echarts.init(document.getElementById('ethFlowChart'))
    ethFlowChart.setOption(ethFlowChartOption, true)

    // message.success('数据已更新')

  }).catch(
    (error) => {
      // message.error('数据异常')
      console.log(error)
    }
  )
}
const key = ref('eth0');
const tabList = ref([

])
const contentList = {
  tab1: 'content1',
  tab2: 'content2',
};
const onTabChange = (value) => {
  console.log(value, chartData.value[value]);
  key.value = value;

  ethFlowChartOption.yAxis = {
    type: 'value',
    name: 'KB/s'
  }
  ethFlowChartOption.series = []
  ethFlowChartOption.legend.data = []
  for (let key in chartData.value[value]) {
    ethFlowChartOption.legend.data.push(key)
    let seriesData = {
      name: key,
      type: 'line',
      symbolSize: 2,
      label: {
        normal: {
          show: false, // 默认情况下不显示标签
          position: 'top' // 标签显示在数据点上方
        },
        emphasis: {
          show: true // 当鼠标悬停在数据点上时显示标签
        }
      },
      data: [],
    }

    chartData.value[value][key].forEach(element => {
      seriesData.data.push([element.time.substring(element.time.indexOf(" ") + 1), element.value])
    });
    ethFlowChartOption.series.push(seriesData)


    ethFlowChartOption.xAxis.data = []

    ethFlowChartOption.series[0].data.forEach(item => {
      ethFlowChartOption.xAxis.data.push(item[0])
    })


  }
  console.log('ethFlowChartOption', ethFlowChartOption)
  // ethFlowChart = echarts.init(ethFlowChart);
  ethFlowChart.setOption(ethFlowChartOption)




};


</script>  
  
<style lang="less" scoped>
.home-data {
  display: flex;
  height: 100%;

  .box-title {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  .host-info {
    width: 30%;
  }
  .center-container {
    display: flex;
    width: 100%;
    height: 50%;
    padding-bottom: 10px;
    // flex: 1;
    // margin: 0 20px;
  }
  .map-container {
    height: 90%;
    width: 90%;
  }
  .alarm-info {
    width: 100%;
    height: 50%;
    // flex: 1;
    background-color: white;
    border-radius: 5px;
    padding: 1px;
    overflow-y: auto;
  }
  .table-style {
    width: 100%;
    .table-head {
      height: 36px;
      font-size: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      .table-tr {
        height: 100%;
        font-size: 20px;
      }
    }
  }
}
.home-left-box {
  width: 30%;
  // margin-right: 5px;
  padding: 10px 5px 10px 10px;

  .time-show {
    display: flex;
    width: 100%;
    height: 10%;
    border-radius: 5px;
    background-color: white;

    justify-content: center;
    align-items: center;
    // font-weight: bold;
    // font-size: 30px;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
  }
  .left-box-pieChart {
    width: 100%;
    height: 30%;
    border-radius: 5px;
    padding: 10px 40px 0px 40px;
    background-color: white;
    margin-top: 10px;
  }
  .left-card-box {
    padding-top: 10px;
    width: 100%;
    height: 58%;
  }
}

.home-right-box {
  width: 70%;
  height: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
  padding: 10px 10px 10px 5px;
}

.forewarning-box {
  // background-color: rgb(75, 144, 212);
}

.card-div {
  text-align: center;
  // line-height: 0.5em;
  margin-bottom: 5px;
  margin-left: 20px;
  // margin-top: 20px;
}
.card-tag {
  width: 100px;
  text-align: center;
}
</style>