<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template >
  <div :style="homeView"
       style='background-color:#ccc'>
    <div style='height:100%;padding:10px'>
      <div style="display:flex; height:50%; ">
        <div style="width:30%;height:100%;font-size:17px;text-align:left;padding-right:10px">
          <a-card title="基础数据"
                  size="small"
                  style="width: 100%;height:100%">
            <!-- <template #extra><a href="#">more</a></template> -->
            <div class="messageShow-box">系统运行时间:{{ MonitoringData.systemRunTime }}</div>
            <div class="messageShow-box">内存利用率:{{ MonitoringData.menItem }}</div>
            <!-- <div class="messageShow-box">总内存:{{ MonitoringData.totalMemory }}</div> -->
            <!-- <div class="messageShow-box">使用内存:{{ MonitoringData.useMemory }}</div> -->
            <div class="messageShow-box">ping检查:{{ MonitoringData.pingCheck }}</div>
            <!-- <div class="messageShow-box">设备连通性检测:{{MonitoringData.deviceConnectivityChecking }}</div> -->
          </a-card>

        </div>

        <div class='cardChartContainer'>
          <a-card id='portCard'
                  style="width: 100%;height:100%"
                  :bodyStyle="bodyStyle"
                  title="端口流量"
                  :tab-list="tabList"
                  :active-tab-key="key"
                  @tabChange="key => onTabChange(key)">
            <div ref="ethFlowChart"
                 style="height:100%"></div>
            <template #extra>
              <a href="#">More</a>
            </template>
            {{ contentList[key] }}
          </a-card>
        </div>
      </div>
      <div style="width: 100%; height: 50%;margin-top:10px">
        <div style=" width: 100%; height: 100%; overflow:auto;box-shadow: 0 0 28px rgba(0,0,0,.07);">

          <a-table :columns="columns"
                   :data-source="data"
                   :pagination="false">
          </a-table>
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { ref, onMounted, onUnmounted, watch, reactive, markRaw } from 'vue';
import * as echarts from 'echarts';
import { message } from "ant-design-vue";
import serviceAxios from '@/utils/serviceAxios';
import interfaces from '@/config';
// import websocket from "@/utils/webSocket";
// import { storeAndGateway } from '@/store'
import { useRouter, useRoute } from 'vue-router'
const route = useRoute();
const router = useRouter();
// console.log('storeAndGateway', storeAndGateway.value)
// if (storeAndGateway.value.storeId == '') {
//   router.push('/customerView')
// }

const bodyStyle = {
  width: "100%",
  height: '90%'
}
// websocket.init_websocket()
const MonitoringData = reactive({
  systemRunTime: "", //系统运行时间
  menItem: '',//内存利用率
  pingCheck: "",//ping检查
  totalMemory: "", //总内存
  useMemory: "", //使用内存

  deviceConnectivityChecking: "",//设备连通性检测
  eth0InterfaceStatus: "", //eth0接口状态
  memoryUtilization: [],//内存利用率
  memoryUtilizationDate: [],//内存利用率时间
  pingDelay: [],//ping响应延时
  pingLoss: [],//ping丢包

  Port0UploadsTraffic: [], //端口0上传流量
  Port1UploadsTraffic: [], //端口1上传流量
  Port2UploadsTraffic: [], //端口2上传流量
  Port3UploadsTraffic: [], //端口3上传流量

  uploadInterfaceIte1: [], //Interface lte1): 上传流量
  uploadInterfaceIte2: [], //Interface lte2): 上传流量
  uploadInterfacebridge1: [], //Interface bridge1): 上传流量
  uploadInterfacepptp1: [], //Interface pptp-out1): 上传流量


  Port0UpdownloadsTraffic: [], //端口0下载流量
  Port1UpdownloadsTraffic: [], //端口1下载流量
  Port2UpdownloadsTraffic: [], //端口2下载流量
  Port3UpdownloadsTraffic: [], //端口3下载流量


  downloadInterfaceIte1: [], //Interface lte1): 下载流量
  downloadInterfaceIte2: [], //Interface lte2): 下载流量
  downloadInterfacebridge1: [], //Interface bridge1): 下载流量
  downloadInterfacepptp1: [], //Interface pptp-out1): 下载流量



})


const columns = [
  { title: '时间', dataIndex: 'alertTime', key: 'alertTime' },
  { title: '门店', dataIndex: 'storeName', key: 'storeName' },
  { title: '告警信息', dataIndex: 'alertMessage', key: 'alertMessage' },
  { title: '当前状态', dataIndex: 'alertStatus', key: 'alertStatus' },
  { title: '告警地址', dataIndex: 'alertIp', key: 'alertIp' },
  { title: '持续时长', dataIndex: 'duration', key: 'duration' },

]
const data = ref()
const get_home_data = () => {
  serviceAxios({
    url: interfaces.alertHistories,
    timeout: 30000,
    method: "get",
    params: {
      store_id: storeAndGateway.value.storeId,
      limit: 20,
    }
  }).then(result => {
    // homeData.forewarning_data = result.data.data
    data.value = result.data.data.map((item) => ({
      alertTime: item.alert_time,
      storeName: item.host_name,
      alertStatus: item.message.alert_type,
      alertIp: item.host_ip,
      // alertLevel: item.message['告警等级'],
      alertMessage: item.message['Problem name'],
      duration: item.message['Problem duration']
      // alertData: item.message['监控取值'],
    }))
  }).catch(error => {
    console.log(error)
    // message.error('请求异常，请联系管理员')
  })
}

// get_home_data()


const ethFlowChart = ref(null);





const ethFlowChartOption = {
  xAxis: {
    // 注： x轴不指定data,自动会从series取
    type: 'time',
    boundaryGap: false,
    axisLabel: { // x轴标签
      interval: 15,
    }

  },
  yAxis: {
    type: 'value',
    name: "(KB/s)",
    axisLabel: { // x轴标签
      interval: 4,
    }
  },
  legend: {
    data: []
  },
  series: [],
  tooltip:
  {
    show: true
  },

};





onMounted(() => {
  // 初始化屏幕尺寸
  updateWindowSize();
  // 添加 resize 事件监听器
  window.addEventListener('resize', updateWindowSize);
});
const storeAndGateway = ref({
  ids: [],
  storeId: ''
})
onMounted(() => {
  let ids = route.query.ids.split(',')
  let gatewayId = route.query.gatewayId
  ids = ids.map(Number)
  storeAndGateway.value.ids = ids
  storeAndGateway.value.storeId = route.query.storeId
  ethFlowHistory()
  get_home_data()
  getTimeAndMemory(Number(gatewayId))

});

const getTimeAndMemory = (id) => {
  serviceAxios({
    url: interfaces.gatewayTimeAndMemory,
    method: 'get',
    params: {
      gateway_id: id
    }
  }).then(result => {
    if (result.data.message == 'success') {
      const data = result.data.data
      MonitoringData.systemRunTime = data.runtime_item_id.value,
        MonitoringData.menItem = data.mem_item_id.value,
        MonitoringData.pingCheck = data.ping_item_id.value
    }
  }).catch()
}
let legend = []


const homeView = ref("")
const windowWidth = ref(0);
const windowHeight = ref(0);


// 更新屏幕尺寸的函数
const updateWindowSize = () => {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
  console.log(window.innerWidth, window.innerHeight)
  homeView.value = {
    height: (window.innerHeight - 100) + 'px'
  }
  let chartHeight = window.innerHeight / 2 - 150 + 'px'
  ethFlowChart.value = markRaw(echarts.init(ethFlowChart.value));  //markRaw()
  ethFlowChart.value.resize({ height: chartHeight })
  ethFlowChart.value.setOption(ethFlowChartOption);

  console.log('homeView.value', homeView.value)
};

// onUnmounted 钩子，在组件卸载前执行，用于清理副作用
onUnmounted(() => {
  // 移除 resize 事件监听器，防止内存泄漏
  window.removeEventListener('resize', updateWindowSize);
});
const key = ref('eth0');
const tabList = ref([

])
const onTabChange = (value) => {
  console.log(value, chartData.value[value]);
  key.value = value;

  ethFlowChartOption.yAxis = {
    type: 'value',
    name: 'KB/s'
  }
  ethFlowChartOption.series = []
  ethFlowChartOption.legend.data = []
  for (let key in chartData.value[value]) {
    ethFlowChartOption.legend.data.push(key)
    let seriesData = {
      name: key,
      type: 'line',
      symbol: "none",
      data: [],
    }

    chartData.value[value][key].forEach(element => {
      seriesData.data.push([element.time, element.value])
    });
    ethFlowChartOption.series.push(seriesData)



  }
  console.log('ethFlowChartOption', ethFlowChartOption)
  ethFlowChart.value = markRaw(echarts.init(ethFlowChart.value));
  ethFlowChart.value.setOption(ethFlowChartOption, true)




};
const contentList = {
  tab1: 'content1',
  tab2: 'content2',
};

let chartData = ref()
const ethFlowHistory = () => {
  serviceAxios({
    url: interfaces.ethFlowHistory,
    method: 'post',
    data: {
      physical_interface_ids: storeAndGateway.value.ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      chartData.value = result.data.data
      for (let ethKey in result.data.data) {
        console.log("ethKey", ethKey)
        tabList.value.push({
          key: ethKey,
          tab: ethKey,
        })
        for (let ethKeyItem in result.data.data[ethKey]) {
          chartData.value[ethKey][ethKeyItem] = chartData.value[ethKey][ethKeyItem]
        }

      }
      console.log("data.value", chartData.value)


      key.value = tabList.value[0].key

      ethFlowChartOption.yAxis = {
        type: 'value',
        name: 'KB/s'
      }
      ethFlowChartOption.series = []
      ethFlowChartOption.legend.data = []
      for (let key in chartData.value[tabList.value[0].key]) {
        ethFlowChartOption.legend.data.push(key)
        let seriesData = {
          name: key,
          type: 'line',
          symbol: "none",
          data: [],
        }

        chartData.value[tabList.value[0].key][key].forEach(element => {
          seriesData.data.push([element.time, element.value])
        });
        ethFlowChartOption.series.push(seriesData)



      }
      console.log('ethFlowChartOption', ethFlowChartOption)
      // ethFlowChart.value = echarts.init(ethFlowChart.value)
      ethFlowChart.value.setOption(ethFlowChartOption, true)

    }
    else {
      message.error(result.data.message)
    }
  }).catch(
    error => {
      console.log(error)
    }
  )
}
// ethFlowHistory()
const chartStyle = ref({
  width: "100%",
  height: "100%"
})


// const chartScreen = () => {
//   const chartContainer = document.getElementById('portCard')
//   console.log('chartContainer', chartContainer)
// }
// chartScreen()
</script>
<style lang="less">
.show-box {
  background-color: aqua;
  height: 100%;
}

.chart-container {
  width: 100%;
  // justify-content: center; /* 水平居中 */
  // align-items: center; /* 垂直居中 */
  height: 800px; /* 设置容器高度 */
}

.box-title {
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.messageShow-box {
  line-height: 1.5em;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.cardChartContainer {
  width: 80%;
  height: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
  text-align: left;
}
</style>