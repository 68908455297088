<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <!-- 高级搜索 -->
  <!-- <a-modal :maskClosable="false" width="400px" v-model:open="modalShow.searchModal" title="高级搜索" ok-text="确认"
    cancel-text="取消" @ok="searchHandleOk">
    <a-form :model="searchRef" ref="formRef" :label-col="{ span: 7 }" :wrapper-col="{ span: 13
   }" :rules="rules">
      <a-form-item v-for="item in advanceSearchFileds" :key="item.id" :label="item.title">
        <a-select v-if="!['regions','keyword'].includes(item.key)" v-model:value="searchRef[item.dataIndex]"
          show-search :placeholder="`请选择${item.title}`" style="width: 200px" :options="options[item.key]"
          :filter-option="filterOption" @change="handleChangeSelect(item.dataIndex,$event)"></a-select>
        <a-input v-else-if="['keyword'].includes(item.key)" v-model:value="searchRef[item.dataIndex]"
          :placeholder="`请输入${item.title}`" />

        <a-tree-select v-else v-model:value="searchRef[item.dataIndex]" show-search style="width: 100%"
          :dropdown-style="{ maxHeight: 'auto', overflow: 'auto' }" placeholder="选择地区" allow-clear
          tree-default-expand-all :tree-data="options.regions" tree-node-filter-prop="label">

        </a-tree-select>
      </a-form-item>
    </a-form>
  </a-modal> -->

  <!-- 添加或者删除数据弹窗 -->
  <a-modal :maskClosable="false"
           width="1300px"
           v-model:open="modalShow.addOrEdit"
           title="商机信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 13
   }"
            :rules="rules">

      <table class="reportTable">
        <tr>
          <td colspan="9"
              style="font-size:20px;text-align: center;">{{baseData.brand_name}}品牌调研详情</td>
        </tr>
        <tr>
          <td class='column'></td>
          <td class='column'>品牌评级</td>
          <td>

            <a-select v-model:value="baseData.brand_rating"
                      show-search
                      placeholder="请选择评级"
                      style="width: 100px"
                      :options="options.brand_rating"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class='column'>优先级</td>
          <td>

            <a-select v-model:value="baseData.survey_priority"
                      show-search
                      placeholder="请选择优先级"
                      style="width: 100px"
                      :options="options.survey_priority"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class='column'>是否完成市调</td>

          <td>
            <a-select v-model:value="baseData.survey_completed"
                      show-search
                      placeholder="请选择"
                      style="width: 100px"
                      :options="options.survey_completed"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>

          <td class='column'>母品牌</td>

          <td>

            <a-select v-model:value="baseData.parent_brand_id"
                      show-search
                      placeholder="请选择"
                      style="width: 100px"
                      :options="options.brandSelector"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>

          </td>

        </tr>
        <tr>
          <td rowspan="9"
              class="column">基础运营信息</td>

          <td class="column">品牌名称<span style="color: red;margin-left: 0px;">*</span></td>
          <td colspan="3">

            <a-input class="input-baseTable"
                     :bordered="false"
                     placeholder="品牌名称"
                     v-model:value="baseData.brand_name">
            </a-input>

          </td>
          <td class="column">品牌LOGO</td>
          <td colspan="3">
            <a-upload v-model:file-list="fileList"
                      :data="{file_type:'data_attachment'}"
                      :action="uploadUrl"
                      list-type="picture"
                      :headers="headers"
                      @change="fileHandleChange">
              <a-button>
                <upload-outlined></upload-outlined>
                上传
              </a-button>
            </a-upload>
          </td>

        </tr>
        <tr>
          <td class="column">品牌背景及简介</td>
          <td colspan="7">
            <a-textarea :rows="2"
                        class="input-surveyTable"
                        :bordered="false"
                        placeholder="请输入品牌背景及简介"
                        v-model:value="baseData.brand_introduction"></a-textarea>
          </td>

        </tr>
        <tr>
          <td class="column">公司名称</td>
          <td colspan="3">
            <a-input class="input-baseTable"
                     :bordered="false"
                     placeholder="公司名称"
                     v-model:value="baseData.company_name">
            </a-input>
          </td>
          <td class="column">总部地址</td>
          <td colspan="3">
            <a-input class="input-baseTable"
                     :bordered="false"
                     placeholder="总部地址"
                     v-model:value="baseData.headquarter_address"></a-input>
          </td>

        </tr>

        <tr>

          <td class="column">创立时间</td>
          <td>
            <!-- baseData.found_time -->
            <a-date-picker v-model:value="tableSource.found_time"
                           picker="year" />
          </td>
          <td class="column">创始人</td>
          <td>
            <a-input class="input-baseTable"
                     :bordered="false"
                     placeholder="请输入创始人"
                     v-model:value="baseData.founder">
            </a-input>
          </td>
          <td colspan="4"></td>
        </tr>

        <tr>
          <td class="column">行业类目<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            <a-select v-model:value="baseData.industry_id"
                      show-search
                      placeholder="请选择"
                      style="width: 150px"
                      :options="options.tradeSelector"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @change="(value)=>tradesChange(value)"
                      @blur="handleBlur"></a-select>
          </td>

          <td class="column">业态<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            <a-select v-model:value="baseData.sub_industry_id"
                      show-search
                      placeholder="请选择"
                      style="width: 150px"
                      :options="options.subTradeSelector"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class="column">加盟/直营</td>
          <td>
            <a-select v-model:value="baseData.operate_model"
                      show-search
                      placeholder="请选择"
                      style="width: 150px"
                      :options="options.operate_model"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class="column">客单价（元）</td>
          <td>
            <a-input-number class="input-number"
                            :controls="false"
                            :bordered="false"
                            placeholder="请输入客单价"
                            v-model:value="baseData.customer_unit_price"></a-input-number>
          </td>
        </tr>
        <tr>
          <td class="column">需求及技术痛点说明</td>
          <td colspan="7">
            <div style="display:flex">
              <a-checkbox v-for="item in tableSource.needList"
                          :key='item.id'
                          v-model:checked="item.value">
                {{ item.name }}</a-checkbox>
              <!-- <a-checkbox v-model:checked="ttt1">宽带接入</a-checkbox>
              <a-checkbox v-model:checked="ttt1">网关设备安装</a-checkbox> -->
              <!-- <a-checkbox v-model:checked="ttt1">it维保</a-checkbox> -->
            </div>
            <a-textarea :rows="2"
                        class="input-surveyTable"
                        :bordered="false"
                        placeholder="请输入需求及技术痛点说明"
                        v-model:value="baseData.requirement_desc"></a-textarea>
          </td>

        </tr>
        <tr>
          <td class="column">品牌总门店量</td>
          <td>
            <a-input-number class="input-number"
                            :controls="false"
                            :bordered="false"
                            placeholder="请输入品牌总门店量"
                            v-model:value="baseData.store_total"></a-input-number>
          </td>
          <!-- <td class="column">覆盖省份（量）</td>
          <td> <a-input-number class='input-number' :controls="false" :bordered="false" placeholder="请输入创始人"
              v-model:value="log1"></a-input-number></td> -->
          <td class="column">覆盖省份（量）</td>
          <td> {{baseData.province_total}}</td>
          <td class="column">上海覆盖门店量</td>
          <td>
            <a-input-number class="input-number"
                            :controls="false"
                            :bordered="false"
                            placeholder="请输入上海覆盖门店量"
                            v-model:value="baseData.sh_store_total"></a-input-number>
          </td>
          <td class="column">年度计划门店总数</td>
          <td>
            <a-input-number class="input-number"
                            :controls="false"
                            :bordered="false"
                            placeholder="请输入年度计划门店总数"
                            v-model:value="baseData.plan_store_total"></a-input-number>
          </td>

        </tr>
        <tr>
          <td class="column">旗下关联品牌</td>
          <td colspan="7">{{baseData.sub_brands_str}} </td>
        </tr>
        <tr>
          <td class="column">覆盖区域</td>
          <td colspan="8">
            <a-tree-select v-model:value="baseData.region_ids"
                           show-search
                           style="width: 100%"
                           :dropdown-style="{ maxHeight: '1000px', overflow: 'auto' }"
                           placeholder="选择区域"
                           allow-clear
                           multiple
                           tree-default-expand-all
                           :tree-data="options.regions"
                           tree-node-filter-prop="label">

            </a-tree-select>
          </td>
        </tr>
        <tr>
          <td class="column">品牌来源</td>
          <td class="column">品牌提供来源</td>
          <td>
            <a-select v-model:value="baseData.source"
                      show-search
                      placeholder="请选择品牌提供来源"
                      style="width: 100px"
                      :options="options.pc_source"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class="column">姓名</td>
          <td>
            <a-input class="input-baseTable"
                     :controls="false"
                     :bordered="false"
                     placeholder="请输入姓名"
                     v-model:value="baseData.source_person"></a-input>
          </td>
          <td colspan="4"> </td>

        </tr>
        <tr>
          <td class="column">KP品牌方</td>
          <td colspan="8">
            <table>
              <tr>

                <td style="width:200px">角色<span style="color: red;margin-left: 0px;">*</span></td>
                <td style="width:200px">名称</td>
                <td style="width:200px">职位</td>
                <td style="width:200px">电话</td>

                <td style="width:150px"> <a class='editable-row-a'
                     @click="addKp">增加KP品牌方</a></td>
              </tr>

            </table>

            <table v-for="(item) in key_persons.kpData"
                   :key=item.id>
              <tr>

                <td style="width:200px">

                  <a-select v-model:value="item.role"
                            show-search
                            placeholder="请选择评级"
                            style="width: 150px;margin-left: -10px"
                            :options="options.pc_key_person_role"
                            :filter-option="filterOption"
                            @focus="handleFocus"
                            @blur="handleBlur"></a-select>

                </td>
                <td style="width:200px">
                  <a-input class="input-baseTable"
                           :bordered="false"
                           placeholder="名称"
                           v-model:value="item.name">
                  </a-input>
                </td>
                <td style="width:200px">
                  <a-input class="input-baseTable"
                           :bordered="false"
                           placeholder="职位"
                           v-model:value="item.position">
                  </a-input>
                </td>
                <td style="width:200px">

                  <a-input class="input-baseTable"
                           :bordered="false"
                           placeholder="电话"
                           v-model:value="item.phone">
                  </a-input>
                </td>
                <td style="width:150px"> <a class='editable-row-a'
                     @click=" removeKp(item)">删除KP品牌方</a></td>
              </tr>

            </table>
          </td>

        </tr>
        <tr>
          <td rowspan="2"
              class="column">品牌目前网络信息</td>

          <td class="column">平均单店面积(㎡)</td>
          <td>
            <a-input-number class="input-number"
                            :bordered="false"
                            placeholder="平均单店面积"
                            v-model:value="baseData.store_area_avg">
            </a-input-number>

          </td>

          <td class="column">预估上下行带宽</td>
          <td>
            <a-input class="input-surveyTable"
                     :bordered="false"
                     placeholder="预估上下行带宽"
                     v-model:value="baseData.estimate_link_speed"></a-input>
          </td>
          <td colspan="4">
            <div>
              <a-checkbox v-model:checked="tableSource.has_customer_wifi">是否提供客用WIFI</a-checkbox>
              <a-checkbox v-model:checked="tableSource.has_it_maintenance">是否有it维保</a-checkbox>
              <a-checkbox v-model:checked="tableSource.estimate_network_delay">是否对网络延迟有要求</a-checkbox>
            </div>
          </td>
        </tr>
        <tr>
          <td class="column">网络产品信息</td>
          <td colspan="7">
            <table>
              <thead>
                <tr>

                  <td style="width:200px">网络资费</td>
                  <td style="width:200px">网络速率</td>
                  <td style="width:150px">网络运营商</td>

                  <td style="width:200px">第三方信息</td>
                  <td style="width:150px">地区</td>
                  <td style="width:150px"> <a class='editable-row-a'
                       @click="addNetworkProvider">增加网络供方信息</a></td>
                </tr>
              </thead>

              <tbody v-for="item in networkProviderForm.networkData"
                     :key="item.id">
                <tr>

                  <td style="width:150px">
                    <a-input class="input-surveyTable"
                             :bordered="false"
                             placeholder="网络资费"
                             v-model:value="item.price">
                    </a-input>
                  </td>
                  <td style="width:150px">
                    <a-input class="input-surveyTable"
                             :bordered="false"
                             placeholder="网络速率"
                             v-model:value="item.speed">
                    </a-input>
                  </td>
                  <td style="width:150px">
                    <a-input class="input-surveyTable"
                             :bordered="false"
                             placeholder="网络运营商"
                             v-model:value="item.network_provider"></a-input>
                  </td>

                  <td style="width:150px">
                    <a-input class="input-surveyTable"
                             :bordered="false"
                             placeholder="第三方信息"
                             v-model:value="item.third_provider"></a-input>
                  </td>

                  <td>
                    <a-tree-select class='td-select'
                                   style="width:150px"
                                   v-model:value="item.region_id"
                                   show-search
                                   :dropdown-style="{ maxHeight: 'auto', overflow: 'auto' }"
                                   placeholder="选择地区"
                                   allow-clear
                                   :tree-data="options.regions"
                                   tree-node-filter-prop="label">
                    </a-tree-select>
                  </td>
                  <td style="width:150px"> <a class='editable-row-a'
                       @click="removeNetworkProvider(item)">删除网络供方信息</a>
                  </td>
                </tr>

              </tbody>
            </table>

          </td>

        </tr>
        <tr>
          <td class="column"> 跟进信息</td>

          <td class="column"> 跟进人</td>
          <td>

            <a-select v-model:value="baseData.project_manager_id"
                      show-search
                      placeholder="请选择"
                      style="width: 100px"
                      :options="options.messageSelector"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
          <td class="column"> 跟进阶段</td>
          <td>
            <a-select v-model:value="baseData.follow_stage"
                      show-search
                      placeholder="请选择"
                      style="width: 150px"
                      :options="options.pc_follow_stage"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
        </tr>

        <tr>
          <td class="column"> 客户成就</td>
          <td class="column"> 预估可合作门店量</td>
          <td>
            <a-input-number class="input-number"
                            :bordered="false"
                            placeholder="输入预估可合作门店量"
                            v-model:value="baseData.estimate_stores"></a-input-number>
          </td>
          <td class="column"> 预估合作金额</td>
          <td>
            <a-input-number class="input-number"
                            :bordered="false"
                            placeholder="输入预估合作金额"
                            v-model:value="baseData.estimate_amount"></a-input-number>
          </td>
          <td class="column"> 客户状态</td>
          <td>
            <a-select v-model:value="baseData.status"
                      show-search
                      placeholder="请选择"
                      style="width: 100px"
                      :options="options.pc_status"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur">
            </a-select>
          </td>
        </tr>

      </table>
    </a-form>
  </a-modal>

  <!-- 报告 -->
  <a-modal :maskClosable="false"
           width="1300px"
           v-model:open="modalShow.viewReportDetail"
           title="报告"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditReport">
    <table class="reportTable">
      <tr>
        <td colspan="9"
            style="font-size:20px;text-align: center;">{{baseData.brand_name}}品牌调研表</td>
      </tr>
      <!-- <tr>
        <td class='column'></td>
        <td class='column'>品牌评级</td>
        <td>

          <a-select v-model:value="baseData.brand_rating" show-search placeholder="请选择评级" style="width: 100px"
            :options="options.brand_rating" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select>
        </td>
        <td class='column'>优先级</td>
        <td>

          <a-select v-model:value="baseData.survey_priority" show-search placeholder="请选择优先级" style="width: 100px"
            :options="options.survey_priority" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select>
        </td>
        <td class='column'>是否完成市调</td>

        <td>
          <a-select v-model:value="baseData.survey_completed" show-search placeholder="请选择" style="width: 100px"
            :options="options.survey_completed" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select>
        </td>

        <td class='column'>母品牌</td>

        <td>

          <a-select v-model:value="baseData.parent_brand_id" show-search placeholder="请选择" style="width: 100px"
            :options="options.brandSelector" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select>

        </td>

      </tr> -->
      <tr>
        <td rowspan="9"
            class="column">基础运营信息</td>

        <td class="column">品牌名称<span style="color: red;margin-left: 0px;">*</span></td>
        <td colspan="3">

          <a-input class="input-baseTable"
                   :disabled="true"
                   :bordered="false"
                   placeholder="品牌名称"
                   v-model:value="baseData.brand_name"></a-input>

        </td>
        <td class="column">品牌LOGO</td>
        <td colspan="3">{{}}甜啦啦图片</td>

      </tr>
      <tr>
        <td class="column">品牌背景及简介</td>
        <td colspan="7">
          <a-textarea :disabled="true"
                      :rows="2"
                      class="input-surveyTable"
                      :bordered="false"
                      placeholder="请输入品牌背景及简介"
                      v-model:value="baseData.brand_introduction"></a-textarea>
        </td>

      </tr>
      <tr>
        <td class="column">公司名称</td>
        <td colspan="3">
          <a-input :disabled="true"
                   class="input-baseTable"
                   :bordered="false"
                   placeholder="公司名称"
                   v-model:value="baseData.company_name"></a-input>
        </td>
        <td class="column">总部地址</td>
        <td colspan="3">
          <a-input :disabled="true"
                   class="input-baseTable"
                   :bordered="false"
                   placeholder="总部地址"
                   v-model:value="baseData.headquarter_address"></a-input>
        </td>

      </tr>

      <tr>

        <td class="column">创立时间</td>
        <td>
          <!-- baseData.found_time -->
          <a-date-picker :disabled="true"
                         v-model:value="tableSource.found_time"
                         picker="year" />
        </td>
        <td class="column">创始人</td>
        <td>
          <a-input :disabled="true"
                   class="input-baseTable"
                   :bordered="false"
                   placeholder="请输入创始人"
                   v-model:value="baseData.founder"></a-input>
        </td>
        <td colspan="4"></td>
      </tr>

      <tr>
        <td class="column">行业类目<span style="color: red;margin-left: 0px;">*</span></td>
        <td>
          <a-select :disabled="true"
                    v-model:value="baseData.industry_id"
                    show-search
                    placeholder="请选择"
                    style="width: 150px"
                    :options="options.tradeSelector"
                    :filter-option="filterOption"
                    @focus="handleFocus"
                    @change="(value)=>tradesChange(value)"
                    @blur="handleBlur"></a-select>
        </td>

        <td class="column">业态<span style="color: red;margin-left: 0px;">*</span></td>
        <td>
          <a-select :disabled="true"
                    v-model:value="baseData.sub_industry_id"
                    show-search
                    placeholder="请选择"
                    style="width: 150px"
                    :options="options.subTradeSelector"
                    :filter-option="filterOption"
                    @focus="handleFocus"
                    @blur="handleBlur"></a-select>
        </td>
        <td class="column">加盟/直营</td>
        <td>
          <a-select :disabled="true"
                    v-model:value="baseData.operate_model"
                    show-search
                    placeholder="请选择"
                    style="width: 150px"
                    :options="options.operate_model"
                    :filter-option="filterOption"
                    @focus="handleFocus"
                    @blur="handleBlur"></a-select>
        </td>
        <td class="column">客单价（元）</td>
        <td>
          <a-input-number :disabled="true"
                          class="input-number"
                          :controls="false"
                          :bordered="false"
                          placeholder="请输入客单价"
                          v-model:value="baseData.customer_unit_price"></a-input-number>
        </td>
        <span style="color: red;margin-left: 0px;">*</span>
      </tr>
      <tr>
        <td class="column">需求及技术痛点说明</td>

        <td colspan="7">
          <div style="display:flex">
            <a-checkbox :disabled="true"
                        v-for="item in tableSource.needList"
                        :key='item.id'
                        v-model:checked="item.value">{{ item.name }}</a-checkbox>
            <!-- <a-checkbox v-model:checked="ttt1">宽带接入</a-checkbox>
              <a-checkbox v-model:checked="ttt1">网关设备安装</a-checkbox> -->
            <!-- <a-checkbox v-model:checked="ttt1">it维保</a-checkbox> -->
          </div>

          <a-textarea :disabled="true"
                      :rows="2"
                      class="input-surveyTable"
                      :bordered="false"
                      placeholder="请输入需求及技术痛点说明"
                      v-model:value="baseData.requirement_desc"></a-textarea>
        </td>

      </tr>
      <tr>
        <td class="column">品牌总门店量</td>
        <td>
          <a-input-number :disabled="true"
                          class="input-number"
                          :controls="false"
                          :bordered="false"
                          placeholder="请输入品牌总门店量"
                          v-model:value="baseData.store_total"></a-input-number>
        </td>
        <td class="column">覆盖省份（量）</td>
        <td> {{}}</td>
        <!-- <td class="column">覆盖城市（量）</td>
        <td> <a-input-number class='input-number' :controls="false" :bordered="false" placeholder="请输入创始人"
              v-model:value="log1"></a-input-number></td> -->
        <td class="column">上海覆盖门店量</td>
        <td>
          <a-input-number :disabled="true"
                          class="input-number"
                          :controls="false"
                          :bordered="false"
                          placeholder="请输入上海覆盖门店量"
                          v-model:value="baseData.sh_store_total"></a-input-number>
        </td>
        <td class="column">年度计划门店总数</td>
        <td>
          <a-input-number :disabled="true"
                          class="input-number"
                          :controls="false"
                          :bordered="false"
                          placeholder="请输入年度计划门店总数"
                          v-model:value="baseData.plan_store_total"></a-input-number>
        </td>
      </tr>
      <tr>
        <td class="column">旗下关联品牌</td>
        <td colspan="3">{{baseData.sub_brands_str}} </td>
      </tr>
      <tr>
        <td class="column">覆盖区域</td>
        <td colspan="8">
          <a-tree-select :disabled="true"
                         v-model:value="baseData.region_ids"
                         show-search
                         style="width: 100%"
                         :dropdown-style="{ maxHeight: '1000px', overflow: 'auto' }"
                         placeholder="Please select"
                         allow-clear
                         multiple
                         tree-default-expand-all
                         :tree-data="options.regions"
                         tree-node-filter-prop="label">

          </a-tree-select>
        </td>
      </tr>
      <!-- <tr>
        <td class="column">品牌来源</td>
        <td class="column">品牌提供来源</td>
        <td> <a-select :disabled="true" v-model:value="baseData.source" show-search placeholder="请选择品牌提供来源"
            style="width: 100px" :options="options.pc_source" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select> </td>
        <td class="column">姓名</td>
        <td><a-input :disabled="true" class="input-baseTable" :controls="false" :bordered="false" placeholder="请输入姓名"
            v-model:value="baseData.source_person"></a-input> </td>
        <td colspan="4"> </td>

      </tr> -->
      <tr>
        <td class="column">KP品牌方</td>
        <td colspan="8">
          <table>
            <tr>

              <td style="width:200px">角色<span style="color: red;margin-left: 0px;">*</span></td>
              <td style="width:200px">名称</td>
              <td style="width:200px">职位</td>
              <td style="width:200px">电话</td>

            </tr>

          </table>

          <table v-for="(item) in key_persons.kpData"
                 :key=item.id>
            <tr>

              <td style="width:200px">

                <a-select :disabled="true"
                          v-model:value="item.role"
                          show-search
                          placeholder="请选择评级"
                          style="width: 150px;margin-left: -10px"
                          :options="options.pc_key_person_role"
                          :filter-option="filterOption"
                          @focus="handleFocus"
                          @blur="handleBlur"></a-select>

              </td>
              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-baseTable"
                         :bordered="false"
                         placeholder="名称"
                         v-model:value="item.name"></a-input>
              </td>
              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-baseTable"
                         :bordered="false"
                         placeholder="职位"
                         v-model:value="item.position"></a-input>
              </td>
              <td style="width:200px">

                <a-input :disabled="true"
                         class="input-baseTable"
                         :bordered="false"
                         placeholder="电话"
                         v-model:value="item.phone">
                </a-input>
              </td>

            </tr>

          </table>
        </td>

      </tr>
      <tr>
        <td rowspan="2"
            class="column">品牌目前网络信息</td>

        <td class="column">平均单店面积(㎡)</td>
        <td>
          {{baseData.store_area_avg}}

        </td>

        <td class="column">预估上下行带宽</td>
        <td>{{baseData.estimate_link_speed}}
        </td>
        <td colspan="4">
          <div>
            <a-checkbox :disabled="true"
                        v-model:checked="tableSource.has_customer_wifi">是否提供客用WIFI</a-checkbox>
            <a-checkbox :disabled="true"
                        v-model:checked="tableSource.has_it_maintenance">是否有it维保</a-checkbox>
            <a-checkbox :disabled="true"
                        v-model:checked="tableSource.estimate_network_delay">是否对网络延迟有要求</a-checkbox>
          </div>
        </td>
      </tr>
      <tr>
        <td class="column">网络产品信息</td>
        <td colspan="7">
          <table>

            <tr>

              <td style="width:200px">网络资费</td>
              <td style="width:200px">网络速率</td>
              <td style="width:200px">网络运营商</td>

              <td style="width:200px">第三方信息</td>
            </tr>

          </table>
          <table v-for="item in networkProviderForm.networkData"
                 :key="item.id">
            <tr>

              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-surveyTable"
                         :bordered="false"
                         placeholder="网络资费"
                         v-model:value="item.price"></a-input>
              </td>
              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-surveyTable"
                         :bordered="false"
                         placeholder="网络速率"
                         v-model:value="item.speed"></a-input>
              </td>
              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-surveyTable"
                         :bordered="false"
                         placeholder="网络运营商"
                         v-model:value="item.network_provider"></a-input>
              </td>

              <td style="width:200px">
                <a-input :disabled="true"
                         class="input-surveyTable"
                         :bordered="false"
                         placeholder="第三方信息"
                         v-model:value="item.third_provider"></a-input>
              </td>

            </tr>

          </table>
        </td>

      </tr>
      <!-- <tr>
        <td class="column"> 跟进信息</td>

        <td class="column"> 项目经理</td>
        <td>

          <a-select :disabled="true" :bordered="false" v-model:value="baseData.project_manager_id" show-search
            placeholder="请选择" style="width: 100px" :options="options.messageSelector" :filter-option="filterOption"
            @focus="handleFocus" @blur="handleBlur"></a-select>
        </td>
        <td class="column"> 跟进阶段</td>
        <td>
          <a-select :disabled="true" :bordered="false" v-model:value="baseData.follow_stage" show-search
            placeholder="请选择" style="width: 100px" :options="options.pc_follow_stage" :filter-option="filterOption"
            @focus="handleFocus" @blur="handleBlur"></a-select>
        </td>
      </tr> -->

      <!-- <tr>
        <td class="column"> 客户成就</td>
        <td class="column"> 预估可合作门店量</td>
        <td>
          <a-input-number :disabled="true" :bordered="false" class="input-number" placeholder="输入预估可合作门店量"
            v-model:value="baseData.estimate_stores"></a-input-number>
        </td>
        <td class="column"> 预估合作金额</td>
        <td>
          <a-input-number :disabled="true" :bordered="false" class="input-number" placeholder="输入预估合作金额"
            v-model:value="baseData.estimate_amount"></a-input-number>
        </td>
        <td class="column"> 客户状态</td>
        <td>
          <a-select :disabled="true" :bordered="false" v-model:value="baseData.status" show-search placeholder="请选择"
            style="width: 100px" :options="options.pc_status" :filter-option="filterOption" @focus="handleFocus"
            @blur="handleBlur"></a-select>
        </td>
      </tr> -->
      <tr>
        <td rowspan="2"
            class="column">需求沟通</td>
        <!-- <td class="column">是否有扩店计划</td> -->
        <td colspan="2">
          <a-checkbox v-model:checked="tableSource.has_expand_plan">是否有扩店计划</a-checkbox>

        </td>
        <td class="column">
          调研状态
        </td>
        <td colspan="2">
          <a-select style="width:200px"
                    :bordered="false"
                    v-model:value="surveyData.status"
                    :options="options.survey_report_status"
                    show-search
                    placeholder="请选择">

          </a-select>
        </td>

        <td class="column">
          报告名称
        </td>
        <td colspan="2">
          <a-input style="width:200px"
                   :bordered="false"
                   v-model:value="surveyData.name"
                   placeholder="输入报告名称">

          </a-input>
        </td>

      </tr>
      <tr>

        <td class="column">计划合作方案</td>
        <td colspan="3">
          <a-textarea :rows="4"
                      class="input-surveyTable"
                      :bordered="false"
                      placeholder="请输计划合作方案"
                      v-model:value="surveyData.estimate_programme"></a-textarea>
        </td>
        <td class="column">当前合作方案</td>
        <td colspan="3">
          <a-textarea :rows="4"
                      class="input-surveyTable"
                      :bordered="false"
                      placeholder="请输当前合作方案"
                      v-model:value="surveyData.current_programme"></a-textarea>
        </td>
      </tr>
      <tr>

        <td rowspan="3"
            class="column">跟踪日志 </td>
        <td colspan="8">
          <table>
            <tr>

              <td style="width:150px">日期</td>
              <!-- <td style="width:200px">参与人</td> -->
              <td style="width:200px">跟进情况</td>
              <td style="width:200px">备注</td>
              <td style="width:150px">跟进人</td>
              <td style="width:150px"> <a class='editable-row-a'
                   @click="addLog">增加日志</a></td>
            </tr>

          </table>

          <table v-for="(item) in logForm.logsData"
                 :key="item.key">
            <tr>
              <td style="width:150px">
                {{item.create_time}}
              </td>

              <!-- <td style="width:200px"><a-textarea :rows="2" class="input-surveyTable" :bordered="false"
                  placeholder="请输入参与人" v-model:value="item.log1"></a-textarea></td> -->
              <td style="width:200px">
                <a-textarea :rows="2"
                            class="input-surveyTable"
                            :bordered="false"
                            placeholder="请输入跟进情况"
                            v-model:value="item.trace_situation"></a-textarea>
              </td>
              <!-- <td style="width:200px"><a-textarea :rows="2" class="input-surveyTable" :bordered="false"
                  placeholder="请输入本次跟进完成情况" v-model:value="item.log1"></a-textarea></td> -->
              <td style="width:200px">
                <a-textarea :rows="2"
                            class="input-surveyTable"
                            :bordered="false"
                            placeholder="请输入记录人/备注"
                            v-model:value="item.desc"></a-textarea>
              </td>

              <td style="width:150px">
                {{item.tracer.name}}
              </td>

              <td style="width:150px"> <a class='editable-row-a'
                   @click=" removeLog(item)">删除日志</a></td>
            </tr>
          </table>

        </td>

      </tr>
    </table>

  </a-modal>

  <!-- 报告索引信息 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="modalShow.viewSurveyReport"
           title="报告列表"
           ok-text="确认"
           cancel-text="取消">
    <table class="reportTable">
      <tr>
        <td style="width:200px">id</td>
        <td style="width:200px">报告名称</td>
        <td style="width:200px"><a class='editable-row-a'
             @click="addReport">增加报告</a></td>
      </tr>

    </table>

    <table class="reportTable"
           v-for="(item) in reportForm.reportsData"
           :key=item.id>
      <tr>

        <td style="width:200px">

          {{item.id}}

        </td>
        <td style="width:200px">
          {{item.name}}
        </td>
        <td style="width:200px">
          <a class='editable-row-a'
             @click="editReport(item.id)">查看报告</a>
          <a class='editable-row-a'
             @click="deleteReport(item.id)">删除报告</a>
        </td>

      </tr>

    </table>
    <template #footer>
    </template>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="fieldAll.columns"
           :data-source="tableSource.data"
           :row-selection="rowSelection"
           :scroll="{ x: 2000 }"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="reportList(record)">报告列表</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div class='search-box'>
          <div class='search-input-box'>
            <a-input class='input-item'
                     addonBefore='关键字'
                     v-model:value="searchRef.keyword"
                     placeholder="请输入关键字"
                     style="width:180px"></a-input>
            <regionTreeCascader class='input-item'
                                :value="searchRef.region"
                                @update:value='handleValueChanged' />
            <a-input class='input-item'
                     addonBefore='跟进阶段'
                     placeholder="请输入跟进阶段"
                     v-model:value="searchRef.follow_stage"
                     style="width:200px"></a-input>

            <a-input class='input-item'
                     addonBefore='准客户状态'
                     placeholder="请输入准客户状态"
                     v-model:value="searchRef.status"
                     style="width:200px"></a-input>
            <a-input class='input-item'
                     addonBefore='品牌评级'
                     placeholder="请输入品牌评级"
                     v-model:value="searchRef.brand_rating"
                     style="width:200px"></a-input>

            <a-button class='button-item'
                      type="primary"
                      @click="sift">搜索</a-button>
            <a-button class='button-item'
                      @click="resetSift">重置</a-button>

          </div>
          <div>

          </div>

        </div>
        <div class='right-buttons'>

          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <!-- <a-button class="addDataButton" v-if="buttonShow.edit" type="primary" @click="editData">编辑数据</a-button> -->
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import regionTreeCascader from '@/components/regionTreeCascader'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import { checkTree } from "@/utils/regionTree";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
dayjs.locale('zh-cn');

const url = {
  add: interfaces.prospective_customer_add,
  delete: interfaces.prospective_customer_delete,
  edit: interfaces.prospective_customer_edit,
  page: interfaces.prospective_customer_page,

  // getById: interfaces.deviceGetById,
}

const uploadUrl = process.env.VUE_APP_URL + interfaces.commonUpload
const lookPic = process.env.VUE_APP_URL + interfaces.fileGet
const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
  // "Content-Type": "multipart/form-data"
})
//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}




const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      fixed: "left"
    },
    {
      title: "评级",
      dataIndex: "brand_rating",
      key: "brand_rating"
    },
    {
      title: "优先级",
      dataIndex: "survey_priority",
      key: "survey_priority"
    },
    {
      title: "品牌名称",
      dataIndex: "brand_name",
      key: "brand_name"
    },
    {
      title: "行业类目",
      dataIndex: "industry_name",
      key: "industry_name"
    },
    {
      title: "业态",
      dataIndex: "sub_industry_name",
      key: "sub_industry_name"
    },


    {
      title: "运营模式",
      dataIndex: "operate_model",
      key: "operate_model"
    },

    {
      title: "途径分类",
      dataIndex: "source",
      key: "source"
    },
    {
      title: "跟进人",
      dataIndex: "project_manager_name",
      key: "project_manager_name"
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status"
    },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      fixed: "right"
    }
  ],

  //弹框字段数据
  modalData: [{
    title: "品牌",
    dataIndex: "brand",
    key: "brand"
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "设备型号",
    dataIndex: "model",
    key: "model"
  },
  {
    title: "设备数量",
    dataIndex: "quantity",
    key: "quantity"
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc"
  }]

})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: [],
  found_time: "", //创立时间

  has_customer_wifi: "",//客用wifi
  has_it_maintenance: "", //IT维护
  estimate_network_delay: "", //网络延迟


  has_expand_plan: false,//扩店计划

  needList: "" //需求项
})

//增加或者编辑的模态框规则
const rules = {
  brand: [
    {
      required: true,
      message: "请输入品牌",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],
  model: [
    {
      required: true,
      message: "请输入设备类型",
    },
  ],
  quantity: [
    {
      required: true,
      message: "请输入设备数量",
    },
  ]
}

//选择框数据
const options = reactive({
  brand: [],
  search: [],

  //调研状态
  survey_report_status: [{ label: "调研中", value: "调研中" }, { label: "调研完成", value: "调研完成" }],
  brand_rating: [],//品牌评级
  survey_priority: [], //优先级
  operate_model: [], //经营模式
  pc_source: [], //准客户来源
  pc_key_person_role: [], //准客户关键人物角色
  pc_follow_stage: [], //准客户跟进阶段
  pc_status: [], //准客户状态
  tradeSelector: [], //行业
  subTradeSelector: [], //子行业
  messageSelector: [],//项目经理
  brandSelector: [],//母品牌
  regions: [],//区域
  needList: [], //需求项
  survey_completed: [{
    label: "是",
    value: 1
  },
  {
    label: "否",
    value: 0
  }], //调研完成情况
});



//选择器
const selector = () => {
  let selectorFields = {

    brand_rating: interfaces.brand_rating, //品牌评级
    survey_priority: interfaces.survey_priority, //优先级
    operate_model: interfaces.operate_model, //经营模式
    pc_source: interfaces.pc_source, //准客户来源
    pc_key_person_role: interfaces.pc_key_person_role, //准客户关键人物角色
    pc_follow_stage: interfaces.pc_follow_stage, //准客户跟进阶段
    pc_status: interfaces.pc_status, //准客户状态
  }
  // console.log(selectorFields)
  for (let key in selectorFields) {

    serviceAxios({
      url: selectorFields[key],
      method: "get"
    }).then(result => { options[key] = result.data.data }).catch((error) => { message.error(error), console.log(error) })
  }
  console.log(options)
}
selector()

//行业选择器
const tradeSelector = () => {
  serviceAxios({
    url: interfaces.tradeAll,
    method: "get"
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      data.push({
        label: item.name,
        value: item.id
      })
    })
    options.tradeSelector = data

  }).catch((error) => { message.error(error), console.log(error) })
}

//子行业选择器
const subTradeSelector = (id) => {
  serviceAxios({
    url: interfaces.subTradeSelector,
    method: "get",
    params: {
      parent_id: id
    }
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      data.push({
        label: item.name,
        value: item.id
      })
    })
    options.subTradeSelector = data
  }).catch((error) => { message.error(error), console.log(error) })
}

tradeSelector()
// subTradeSelector()
//项目经理选择器，从系统用户接口中选择
const messageSelector = () => {
  serviceAxios({
    url: interfaces.systemUserPage,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.real_name,
        value: item.id
      })
    })
    options.messageSelector = data
  }).catch((error) => {
    message.error("error"),
      console.log(error)
  })
}
messageSelector()
// 基础数据模型
const baseData = reactive({
  id: "",
  survey_completed: "",
  industry_id: "",
  sub_industry_id: "",
  parent_brand_id: "",
  project_manager_id: "",
  brand_name: "",
  brand_introduction: "",
  brand_logo_id: "",
  company_name: "",
  headquarter_address: "",
  brand_rating: "",
  survey_priority: "",
  operate_model: "",
  customer_unit_price: "",
  plan_store_total: "",
  requirement_desc: "",
  cover_provinces: "",
  province_total: "",
  cover_cities: "",
  store_total: "",
  sh_store_total: "",
  founder: "",
  found_time: "",
  store_area_avg: "",
  has_customer_wifi: "",
  has_it_maintenance: "",
  estimate_link_speed: "",
  estimate_network_delay: "",
  desc: "",
  follow_stage: "",
  estimate_stores: "",
  estimate_amount: "",
  source: "",
  source_person: "",
  status: "",
  create_time: "",
  last_modified: "",
  requirement_ids: "",
  region_ids: "",
  current_products: "",
  key_persons: "",
  sub_brands_str: "", //旗下关联品牌
})

//商情调查数据模型
const surveyData = reactive({
  id: "",
  prospective_customer_id: "",
  has_expand_plan: false,
  estimate_programme: "",
  current_programme: "",
  status: "",
  name: "",
  trace_logs: []
})



//弹框显示
const modalShow = reactive({
  addOrEdit: false, //基础数据弹窗
  viewReportDetail: false,// 报告详情信息
  viewSurveyReport: false,//报告弹窗
  isDeleteModalVisible: false, //删除确认弹窗

  searchModal: false  //高级搜索模态框
})

let urlFlag = "" //判断 商机是增加数据还是编辑数据

let urlReport = "" //判断 报告是增加数据还是编辑数据

const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  size: "small",
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    let brandSelector = [] //母品牌
    result.data.data.list.forEach(item => {
      console.log("item", item);

      data.push({
        id: item.id,
        key: String(item.id),
        survey_completed: item.survey_completed, //商情调查是否完成  integer 
        industry_id: item.industry.id, //行业ID integer 
        sub_industry_id: item.sub_industry.id, //子行业ID integer 
        industry: item.industry,
        sub_industry: item.sub_industry,
        industry_name: item.industry.name,
        sub_industry_name: item.sub_industry.name,
        parent_brand_id: item.parent_brand_id, //母品牌ID integer 
        parent_brand: item.parent_brand,
        project_manager_id: item.project_manager == undefined ? null : item.project_manager.id, //项目负责人id integer 
        project_manager_name: item.project_manager == undefined ? null : item.project_manager.real_name, //项目负责人姓名 string
        brand_name: item.brand_name, //品牌名
        brand_introduction: item.brand_introduction, //品牌介绍
        brand_logo_id: item.brand_logo_id, //品牌logo
        company_name: item.company_name, //公司名称
        headquarter_address: item.headquarter_address, //公司总部
        brand_rating: item.brand_rating, //品牌评级
        survey_priority: item.survey_priority, //商情调查优先级
        operate_model: item.operate_model, //运营模式
        plan_store_total: item.plan_store_total,//年度计划开店数
        customer_unit_price: item.customer_unit_price, //客户单价

        requirement_desc: item.requirement_desc, //需求及技术痛点说明
        requirements: item.requirements,//需求信息
        cover_provinces: item.cover_provinces, //覆盖省份
        cover_cities: item.cover_cities, //覆盖城市
        province_total: item.province_total, //商情中省的数量
        store_total: item.store_total, //门店总数
        sh_store_total: item.sh_store_total, //上海门店总数
        founder: item.founder, //创始人
        found_time: item.found_time, // 成立时间 精确到年
        store_area_avg: item.store_area_avg, //门店面积平均值
        has_customer_wifi: item.has_customer_wifi, //是否有客户wifi
        has_it_maintenance: item.has_it_maintenance, //是否有IT维护
        estimate_link_speed: item.estimate_link_speed, //预计上下行速度
        estimate_network_delay: item.estimate_network_delay, //是否对网络延迟有要求 
        desc: item.desc, //备注/运行及技术痛点
        follow_stage: item.follow_stage, //跟进阶段 PCFollowStage
        estimate_stores: item.estimate_stores, //预计合作门店数
        estimate_amount: item.estimate_amount, //预计合作金额
        source: item.source,
        source_person: item.source_person,
        status: item.status, //状态 PCStatus
        create_time: item.create_time, //创建时间
        last_modified: item.last_modified, //最后修改时间
        requirement_ids: item.requirement_ids, //需求id array
        region_ids: item.region_ids,  //区域id 仅限省和直辖市 array
        regions: item.regions, //区域名称 仅限省和直辖市 array
        current_products: item.current_products, //当前产品 array
        key_persons: item.key_persons, //关键人 array

        survey_reports: item.survey_reports, //调研报告
        sub_brands_str: item.sub_brands //子品牌信息
      })

      brandSelector.push({
        label: item.brand_name,
        value: item.id
      })

    });

    options.brandSelector = brandSelector
    tableSource.data = data;
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  tableSource.found_time = null;
  key_persons.kpData = []
  networkProviderForm.networkData = []
  baseData.region_ids = []
  tableSource.needList.forEach(item => {
    item.value = false
  })
  tableSource.estimate_network_delay = false
  tableSource.has_it_maintenance = false
  tableSource.has_customer_wifi = false
};

const resetSurveyData = () => {
  for (let key in surveyData) {
    // console.log(key);
    if (key != "prospective_customer_id" && key != "report_id") {
      surveyData[key] = null;
    }
  }
  tableSource.has_expand_plan = null;
  logForm.logsData = []

};

const formRef = ref()

const edit = (record) => {
  for (let key in baseData) {
    baseData[key] = record[key]
  }
  baseData.parent_brand_id = record.parent_brand == undefined ? "" : record.parent_brand["id"]

  let sub_brands = []
  if (record.sub_brands_str.length > 0) {
    record.sub_brands_str.forEach(item => {
      sub_brands.push(item.brand_name)
    })
    sub_brands = sub_brands.join(" ")
    baseData.sub_brands_str = sub_brands
  }
  else {
    baseData.sub_brands_str = ""
  }


  // surveyData.id = record.id
  subTradeSelector(record.industry_id)
  tableSource.found_time = tableSource.found_time == "" || tableSource.found_time == null ? "" : dayjs(record.found_time, "YYYY");


  //需求项信息
  let requirements = []

  record.requirements.forEach(item => {
    requirements.push(item.id)
  })
  if (requirements.length > 0) {
    tableSource.needList.forEach(item => {
      item.value = false
      if (requirements.includes(item.id)) {
        item.value = true
      }
    })
  }
  else {
    tableSource.needList.forEach(item => {
      item.value = false
    })
  }

  let region_ids = []
  record.regions.forEach(item => {
    region_ids.push(item.id)
  })
  baseData.region_ids = region_ids
  tableSource.estimate_network_delay = record.estimate_network_delay == 0 ? false : true
  tableSource.has_it_maintenance = record.has_it_maintenance == 0 ? false : true
  tableSource.has_customer_wifi = record.has_customer_wifi == 0 ? false : true

  key_persons.kpData = record.key_persons
  networkProviderForm.networkData = record.current_products
}

//编辑数据
const editData = (record) => {
  console.log("record", record);
  urlFlag = url.edit
  edit(record)
  modalShow.addOrEdit = true;

};






//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};

//删除数据
const deleteData = () => {

  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    modalShow.isDeleteModalVisible = true;
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == "success") {
          message.success("删除成功");
          selectedRowKey.value = [];
          queryPage()

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};

//去除字典中的值为null的键
const removeNullValues = (obj) => {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] != null && obj[key] !== "") {
      result[key] = obj[key];
    }
    return result;
  }, {});
}



//提交商机信息
const addOrEditHandleOk = async () => {

  baseData.current_products = networkProviderForm.networkData
  baseData.key_persons = key_persons.kpData
  baseData.found_time = tableSource.found_time == "" || tableSource.found_time == null ? "" : tableSource.found_time.format("YYYY")
  baseData.estimate_network_delay = tableSource.estimate_network_delay == false ? 0 : 1
  baseData.has_it_maintenance = tableSource.has_it_maintenance == false ? 0 : 1
  baseData.has_customer_wifi = tableSource.has_customer_wifi == false ? 0 : 1

  let requirement_ids = [] //需求id
  tableSource.needList.forEach(item => {
    if (item.value == true) {
      requirement_ids.push(item.id)
    }
  })
  baseData.requirement_ids = requirement_ids

  let data = removeNullValues(baseData)
  // data['sub_brands'] = null
  if (data.current_products != undefined && data.current_products.length > 0) {
    data.current_products.forEach(item => {
      console.log("item", item)
      item.region_id = item.region_id == null || item.region_id.length == 0 ? null : item.region_id
    })
  }





  if (checkRequired() == true) {
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.error(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }






};

//查看报告列表
const reportList = (record) => {
  edit(record)
  modalShow.viewSurveyReport = true;
  surveyData.prospective_customer_id = record.id

  if (record.survey_reports.length == 0) {
    addReport()
    modalShow.viewSurveyReport = false;

  }
  reportForm.reportsData = record.survey_reports
}

//查看报告详情
const viewReportDetail = (reportId) => {
  report_get_by_id(reportId)
  modalShow.viewReportDetail = true;
  urlReport = interfaces.survey_report_edit

}

//查看报告
const editReport = (reportID) => {
  report_get_by_id(reportID)
  modalShow.viewReportDetail = true;
  modalShow.viewSurveyReport = false;
  urlReport = interfaces.survey_report_edit

}


//提交报告信息
const addOrEditReport = () => {
  surveyData.trace_logs = logForm.logsData

  surveyData.has_expand_plan = tableSource.has_expand_plan == false ? 0 : 1
  console.log(surveyData)
  let data = removeNullValues(surveyData)
  serviceAxios({
    url: urlReport,
    method: "post",
    data: data
  }).then(result => {
    if (result.data.message == 'success') {
      message.success("成功")
      modalShow.viewReportDetail = false;
      modalShow.viewSurveyReport = false;
      queryPage()
      // modalShow.viewSurveyReport = true;
    }
    else {
      message.error(result.data.message)
    }
  }).catch(err => {
    console.log(err)
    message.error("处理数据发生异常，请联系管理员")
  })
}
//增加报告信息
const addReport = () => {
  resetSurveyData()
  urlReport = interfaces.survey_report_add
  modalShow.viewReportDetail = true;
};
//删除报告信息
const deleteReport = (reportId) => {
  serviceAxios({
    url: interfaces.survey_report_delete,
    method: "post",
    data: {
      ids: [reportId]
    }
  }).then(result => {
    if (result.data.message == 'success') {
      message.success("成功")
      modalShow.viewSurveyReport = false;
      queryPage()
    }

  }).catch(err => {
    console.log(err)
    message.error("处理数据发生异常，请联系管理员")
  })
};



const getTicketsSelector = () => {
  serviceAxios(
    {
      url: "",
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      return result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}







const handleChangeSelect = (dataIndex, value) => {

};



//日志
const logForm = reactive({
  logsData: []
})



const removeLog = item => {
  const index = logForm.logsData.indexOf(item);
  if (index !== -1) {
    logForm.logsData.splice(index, 1);
  }
  if (item.id != null) {
    serviceAxios({
      url: interfaces.trace_log_delete,
      method: "post",
      data: {
        ids: [item.id]
      }
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("删除成功")
      }

    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })

  }


};

const addLog = () => {
  logForm.logsData.push({
    trace_situation: "",
    desc: "",
    report_id: surveyData.id,
    prospective_customer_id: surveyData.prospective_customer_id,
    create_time: "",
    tracer: { name: "" }
  });
};

// Kp品牌方信息
const key_persons = reactive({
  kpData: []
})

const removeKp = item => {
  const index = key_persons.kpData.indexOf(item);
  if (index !== -1) {
    key_persons.kpData.splice(index, 1);
  }


};

const addKp = () => {
  key_persons.kpData.push({
    role: null,
    name: "",
    position: "",
    phone: "",
  });
};


// 网络供方信息
const networkProviderForm = reactive({
  networkData: []
})

const removeNetworkProvider = item => {
  const index = networkProviderForm.networkData.indexOf(item);
  if (index !== -1) {
    networkProviderForm.networkData.splice(index, 1);
  }
  if (item.id != null) {
    serviceAxios({
      url: interfaces.pc_current_product_delete,
      method: "post",
      data: {
        ids: [item.id]
      }
    }
    ).then(result => {
      if (result.data.message == 'success') {
        message.success("删除成功")
        queryPage()
      }
    }).catch(error => {
      message.error("删除失败 请联系管理员")

    }
    )
  }

};

const addNetworkProvider = () => {
  networkProviderForm.networkData.push({
    price: "",
    speed: "",
    third_provider: "",
    network_provider: "",
    region_id: []
    //地区
  });
};



//报告
const reportForm = reactive({
  reportsData: []
})




queryPage();


//行业类目变化后获取业态信息
const tradesChange = (value) => {
  // console.log(value)
  baseData.sub_industry_id = null
  subTradeSelector(value)
}

//选择框的搜索功能
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

//获取区域树
const getRegionTree = async () => {

  const tree = await checkTree()
  // tree.forEach(item => {
  //   item.children.forEach(item1 => {
  //     item1.children.forEach(item2 => {
  //       item2.children = []
  //     })
  //   })
  // })
  options.regions = tree[0]["children"]
}
getRegionTree()


//获取商情调查报告 
const getReport = (prospective_customer_id) => {
  serviceAxios({
    url: interfaces.survey_report_page,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999,
      prospective_customer_id: prospective_customer_id
    }
  }).then(result => {


  }).catch()
}

const report_get_by_id = (report_id) => {
  serviceAxios({
    url: interfaces.survey_report_get_by_id,
    method: 'get',
    params: {
      id: report_id,
    }
  }).then(result => {
    let data = result.data.data
    for (let key in surveyData) {
      surveyData[key] = data[key]
    }
    tableSource.has_expand_plan = surveyData.has_expand_plan
    logForm.logsData = data.trace_logs
  }).catch()
}


//获取需求项
const needList = () => {
  serviceAxios({
    url: interfaces.pc_requirement_item_page,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999,
    }
  }).then(result => {

    // 更新表格数据
    let data = []
    let needList_selsector = []
    result.data.data.list.forEach(item => {
      data.push({
        id: item.id,
        name: item.name,
        value: false
      })
      needList_selsector.push({
        label: item.name,
        value: item.id
      })
    });
    tableSource.needList = data;
    options.needList = needList_selsector
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
needList()

//检测必填字段是否有数值
const checkRequired = () => {
  let flag = true
  //必填字段 行业类目 业态 品牌名称 区域
  if (baseData.industry_id == null || baseData.industry_id == '' || baseData.industry_id == undefined) {
    flag = false
    message.error("行业类目不能为空")
  }
  if (baseData.sub_industry_id == null || baseData.sub_industry_id == '' || baseData.sub_industry_id == undefined) {
    flag = false
    message.error("业态不能为空")
  }
  if (baseData.brand_name == null || baseData.brand_name == '' || baseData.brand_name == undefined) {
    flag = false
    message.error("品牌名称不能为空")
  }
  key_persons.kpData.forEach(item => {

    if (item.role == null || item.role == '' || item.role == undefined) {
      flag = false
      message.error("角色名称不能为空")
    }
  })
  return flag
}



const advanceSearchFileds = [

  {
    title: "商情是否调查",
    dataIndex: "survey_completed",
    key: "survey_completed",
  },
  {
    title: "品牌评级",
    dataIndex: "brand_rating",
    key: "brand_rating",
  },
  {
    title: "准客户状态",
    dataIndex: "status",
    key: "pc_status",
  },
  { title: "区域ID", dataIndex: "region_id", key: "regions" },
  { title: "跟进阶段", dataIndex: "follow_stage", key: "pc_follow_stage" },

  { title: "行业", dataIndex: "industry_id", key: "tradeSelector" },
  { title: "母品牌", dataIndex: "parent_brand_id", key: "brandSelector" },
  { title: "需求", dataIndex: "requirement_id", key: "needList" },
  {
    title: "跟进人",
    dataIndex: "project_manager_id",
    key: "messageSelector",
  },
  {
    title: "其他",
    dataIndex: "keyword",
    key: "keyword",
  },
];


const searchRef = ref({

  keyword: null,//模糊搜索
  survey_completed: null, //商情调查是否
  project_manager_id: null, //项目经理ID
  requirement_id: null, //需求ID
  parent_brand_id: null,//母品牌ID
  industry_id: null,//行业ID
  follow_stage: null, //跟进阶段 PCFollowStage
  region_id: null, //区域ID
  region: [],//全部区域
  status: null, //准客户状态 PCStatus
  brand_rating: null, //品牌评级 BrandRating
})



const sift = () => {
  let data = removeNullValues(searchRef.value)
  for (let key in data) {
    if (key != 'region') {
      queryField[key] = data[key]
    }
  }
  queryPage()
  console.log(searchRef.value)
}

const resetSift = () => {
  for (let key in searchRef.value) {
    searchRef.value[key] = null
  }
  searchRef.value.region = []
  pagination.current = 1
  queryField = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  }

  queryPage()
}


//附件信息
const fileList = reactive([])
const fileHandleChange = (info) => {
  console.log(info)
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }

}

//regionTreeCascader组件value变化事件
const handleValueChanged = (newValues) => {
  searchRef.value.region_id = newValues[newValues.length - 1]
  searchRef.value.region = newValues
  console.log(newValues)
}
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  margin-bottom: 0px;
}
.editable-row-a {
  margin: 2px;
  color: #1884ea;
}

.reportTable {
  // width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.reportTable tr td {
  width: auto;
  min-width: 100px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.reportTable tr td.column {
  background-color: #eff3f6;
  color: #040000;
  width: 10%;
}

.reportTable tr td.logsColumn {
  color: #393c3e;
  width: 12%;
}

.input-baseTable {
  width: 100%;
  padding-left: 0px;
}

.input-surveyTable {
  width: 100%;
  padding-left: 0px;
}
.input-number {
  padding-left: 0px;
  margin-left: -10px;
  margin-bottom: 5px;
  width: 100%;
}

.search-box {
  display: flex;
  padding: 10px 0px 10px 0px;
  .search-input-box {
    margin-right: auto;
    display: flex;
  }
  .input-item {
    margin-left: 5px;
  }
  .button-item {
    margin: 0px 0px 0px 5px;
  }
}
</style>