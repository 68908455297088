<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class='container'>
    <div class='left-container'>
      <div class='left-up'>
        <div class='header'>网关设备
        </div>
        <div style='display:flex'>
          <div id='gatewayChart'
               style='width:100%'></div>
          <!-- <div style='width:40%'>
            <div class="status-text">
              <span class="percentage">{{onlineRate*100}}%</span>
              <span class="label">在线率</span>
            </div>

          </div> -->
        </div>
      </div>
      <div class='left-middle'>
        <div class='left-middle-box'>
          <div class='header'>告警统计
          </div>
          <div>
            <div id='alertPieChart'
                 style='width:100%'></div>
            <div style='display: flex; justify-content: center; width: 100%;'>
              <div style='text-align: center;'>
                <div class="status-text">
                  <span class="label">设备离线</span>
                  <span class="percentage">{{alertRef.deviceOffline}}条</span>
                </div>
                <div class="status-text">
                  <span class="label">接口断开</span>
                  <span class="percentage">{{alertRef.interfaceOffline}}条</span>
                </div>
                <div class="status-text">
                  <span class="label">信号弱</span>
                  <span class="percentage">{{alertRef.weakSignal}}条</span>
                </div>
                <div class="status-text">
                  <span class="label">流量异常</span>
                  <span class="percentage">{{alertRef.flowAnomaly}}条</span>
                </div>
              </div>
            </div>
          </div>

          <div id='alertZhuChart'></div>
        </div>
      </div>
      <!-- <div class='left-down'>
        <div style="width: 100%; height:100%; overflow: hidden;">
          <div class='header'>IT资产
          </div>
          <a-row :gutter="24">
            <a-col :span='8'>
              <span class='span-it'>设备类型</span>
            </a-col>
            <a-col :span='8'>
              <span class='span-it'>总计</span>
            </a-col>
            <a-col :span='8'>
              <span class='span-it'>离线</span>
            </a-col>
          </a-row>
          <div style="width: 100%; height:80%; overflow: hidden;">
            <Vue3SeamlessScroll ref="assetsScroll"
                                :list="assetsList"
                                :limit-scroll-num="1"
                                :hover="true"
                                :step="step">

              <a-row :gutter="24"
                     v-for="(item, index) in assetsList"
                     :key="index"
                     :class="{ 'even-row': index % 2 === 0 }">
                <a-col :span='8'
                       class="column-title">
                  {{ item.title }}
                </a-col>
                <a-col :span='8'
                       class="column-count">
                  {{ item.count }}
                </a-col>
                <a-col :span='8'
                       class="column-offline">
                  {{ item.offline }}
                </a-col>
              </a-row>

              <a-row :gutter="24"
                     v-for="(item, index) in assetsList"
                     :key="index"
                     :class="{ 'even-row': index % 2 === 0 }">
                <a-col :span='8'
                       class="column-title">
                  {{ item.title }}
                </a-col>
                <a-col :span='8'
                       class="column-count">
                  {{ item.count }}
                </a-col>
                <a-col :span='8'
                       class="column-offline">
                  {{ item.offline }}
                </a-col>
              </a-row>

            </Vue3SeamlessScroll>
          </div>

        </div>

      </div> -->
    </div>
    <div class='middle-container'>
      <!-- <div class='middle-up'>
        <div style="width: 100%; height:80%; overflow: hidden;">

        </div>
      </div> -->
      <div class='middle-middle'
           style=' position: relative;'>
        <div class='map-click'><a @click='generalViewClick'>总览</a></div>
        <div style="position: absolute;
    z-index: 1000;
    left: 50%;
    top: 20px; 
    transform: translateX(-50%);">
          <div class="status-text">
            <span class="label">地区</span>
            <span class="percentage">{{inMapDataRef.region}}</span>
          </div>

          <div class="status-text">
            <span class="label">门店数量</span>
            <span class="percentage">{{inMapDataRef.storeCount}}</span>
          </div>

          <div class="status-text">
            <span class="label">预警数量</span>
            <span class="percentage">{{inMapDataRef.alertCount}}</span>
          </div>

        </div>
        <div class='middle-middle-box'
             id='mapChart'></div>
      </div>

      <div class='middle-down'>
        <div class='middle-down-left'
             style="width: 50%; height:100%; overflow: hidden;">
          <!-- <div class='header'>告警
          </div> -->
          <a-row :gutter="0">
            <a-col :span="8">
              <div class="art-text">
                店名
              </div>
            </a-col>
            <a-col :span="8">
              <div class="art-text">
                告警类型
              </div>
            </a-col>
            <a-col :span="8">
              <div class="art-text">
                发生时间
              </div>
            </a-col>

          </a-row>
          <div style="width: 100%; height:100%; overflow: hidden;">

            <Vue3SeamlessScroll ref="cScroll"
                                :list="alertList"
                                :limit-scroll-num="4"
                                :hover="true"
                                :step="step">
              <div v-for="item in alertList"
                   :key="item">
                <div>
                  <a-row :gutter="5"
                         class="alert-row">
                    <a-col :span="8">
                      <div class="content-text store-name">
                        {{ item.storeName }}
                      </div>
                    </a-col>
                    <a-col :span="8">
                      <div :class="item.alertType=='发生异常'? 'content-text alert-type':'content-text duration'">
                        {{ item.alertType }}
                      </div>
                    </a-col>
                    <a-col :span="8">
                      <div class="content-text alert-time">
                        {{ item.alertTime }}
                      </div>
                    </a-col>
                  </a-row>

                </div>

              </div>

              <div v-for="item in alertList"
                   :key="item">
                <div>
                  <a-row :gutter="5"
                         class="alert-row">
                    <a-col :span="8">
                      <div class="content-text store-name">
                        {{ item.storeName }}
                      </div>
                    </a-col>
                    <a-col :span="8">
                      <div :class="item.alertType=='发生异常'? 'content-text alert-type':'content-text duration'">
                        {{ item.alertType }}
                      </div>
                    </a-col>
                    <a-col :span="8">
                      <div class="content-text alert-time">
                        {{ item.alertTime }}
                      </div>
                    </a-col>
                  </a-row>

                </div>

              </div>

            </Vue3SeamlessScroll>
          </div>
        </div>

        <div class='middle-down-right'
             style="width: 50%; height:100%; overflow: hidden;padding-left:5px">
          <div class="middle-down-right-box"
               style="width: 100%; height:100%; overflow: hidden;">
            <!-- <div class='header'>IT资产
            </div> -->
            <a-row :gutter="24">
              <a-col :span='8'>
                <span class='span-it'>设备类型</span>
              </a-col>
              <a-col :span='8'>
                <span class='span-it'>总计</span>
              </a-col>
              <a-col :span='8'>
                <span class='span-it'>离线</span>
              </a-col>
            </a-row>
            <div style="width: 100%; height:100%; overflow: hidden;">
              <Vue3SeamlessScroll ref="assetsScroll"
                                  :list="assetsList"
                                  :limit-scroll-num="1"
                                  :hover="true"
                                  :step="step">

                <a-row :gutter="24"
                       v-for="(item, index) in assetsList"
                       :key="index">
                  <a-col :span='8'
                         class="column-title">
                    {{ item.title }}
                  </a-col>
                  <a-col :span='8'
                         class="column-count">
                    {{ item.count }}
                  </a-col>
                  <a-col :span='8'
                         class="column-offline">
                    {{ item.offline }}
                  </a-col>
                </a-row>

                <a-row :gutter="24"
                       v-for="(item, index) in assetsList"
                       :key="index">
                  <a-col :span='8'
                         class="column-title">
                    {{ item.title }}
                  </a-col>
                  <a-col :span='8'
                         class="column-count">
                    {{ item.count }}
                  </a-col>
                  <a-col :span='8'
                         class="column-offline">
                    {{ item.offline }}
                  </a-col>
                </a-row>

              </Vue3SeamlessScroll>
            </div>

          </div>

        </div>

      </div>
    </div>
    <div class='right-container'>
      <div class='right-up'>
        <div class='header'>实时能耗
        </div>
        <div style='display:flex'>
          <div id='energyLeftChart'
               style='width:50%'></div>
          <div id='energyRightChart'
               style='width:50%'></div>
        </div>
        <div>
          <div id='energyLineChart'></div>
        </div>
      </div>
      <div class='right-middle'>
        <div class='right-middle-box'>
          <div class='header'>客流分析
          </div>
          <div>
            <!-- <div>实时客流{{188}}</div> -->

            <div id='passengerFlowLineChart'
                 style='width:100%'></div>

          </div>
        </div>
      </div>
      <!-- <div class='right-middle2'>
        <div class='right-middle2-box'>

        </div>
      </div> -->
      <div class='right-down'>
        <div class='header'>工单统计
        </div>
        <div style='display:flex'>
          <div id='orderChart'>

          </div>
          <div class='order-statistics'>
            <div v-for="(item, index) in orderRef"
                 :key='index'>

              {{ item.status }}:{{ item.count }}
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script setup>

import { ref, onMounted, nextTick, onUnmounted } from "vue";
import * as echarts from "echarts";
import china from "@/static/china.json";
import serviceAxios from "@/utils/serviceAxios";
import proxyRequest from "@/utils/proxyRequest";
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll';
import interfaces from "@/config";
import { message } from "ant-design-vue";

import { getCurrentInstance } from "vue";
//定义警告数据
const alertRef = ref({
  deviceOffline: "", //设备离线
  interfaceOffline: "",//接口断开
  weakSignal: "",//信号弱
  flowAnomaly: "",//流量异常
})

//定义地图中的显示数据
const inMapDataRef = ref({
  region: '全国',
  storeCount: "",
  alertCount: ""
})


//定义滚动图步长
const step = 0.3

//定义省市
let province = ''
let city = ''
//定义饼图数据
let gatewayChart = ""
let alertPieChart = ""
let alertZhuChart = ""
let energyLeftChart = ""
let energyRightChart = ""
let energyLineChart = ""
let passengerFlowLineChart = ""
let mapChart = ""
let mpdata = ""
let orderChart = ""
//网关
const gatewayOption = {
  // backgroundColor: '#f5f5f5', // 设置背景颜色为浅灰色
  title: {
    // text: "网关",
    left: "center",
    textStyle: {
      fontSize: 17,
      fontWeight: "bold",
      color: "#333",
      shadowBlur: 10, // 添加阴影模糊
      shadowColor: 'rgba(0, 0, 0, 0.2)', // 设置阴影颜色
    },
  },
  tooltip: {
    trigger: "item",
    formatter: " {b} : {c}<br/> ({d}%)", // {a}
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: "50%",
      center: ['50%', '50%'], // 确保饼图居中显示
      data: [
        { value: 1048, name: "在线" },
        { value: 735, name: "离线" },
        { value: 580, name: "未知" },
      ],
      label: {
        show: true,
        position: "outside", // 显示在饼图外部
        formatter: "{b|{b}}{c|: {c}}\n{d|({d}%)}", // 自定义 label 内容
        rich: {
          b: {
            color: "#ccc",
            fontSize: 14,
            lineHeight: 22,
          },
          c: {
            color: "#ccc",
            fontSize: 14,
            lineHeight: 22,
          },
          d: {
            color: "#ccc",
            fontSize: 14,
            lineHeight: 22,
          },
        },
      },
      labelLine: {
        show: true, // 显示 labelLine
        length: 10, // labelLine 长度
        length2: 10, // labelLine 扩展长度
        smooth: 0.2, // 曲线平滑度
        lineStyle: {
          color: "#aaa", // labelLine 颜色
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      itemStyle: {
        borderRadius: 5, // 饼图块的圆角半径
        borderColor: "#fff", // 饼图块的边框颜色
        borderWidth: 2, // 饼图块的边框宽度
      },
      animationDuration: 1000, // 动画持续时间
      animationEasing: 'cubicOut', // 动画缓动函数
    },
  ],
  // emphasis: {
  //   itemStyle: {
  //     shadowBlur: 10,
  //     shadowOffsetX: 0,
  //     shadowColor: "rgba(0, 0, 0, 0.5)",
  //   },
  // },

};

//预警
const alertPieOption = {
  // title: {
  //   text: '告警',
  //   left: 'left',
  //   textStyle: {
  //     fontSize: 17,
  //     fontWeight: 'bold',
  //     color: '#333'
  //   }
  // },
  tooltip: {
    trigger: 'item'

  },
  legend: {
    top: '5%',
    orient: 'horizontal',
    textStyle: {
      fontSize: 14,
      color: '#fff'
    },
    icon: 'circle',
  },
  series: [
    {
      // name: 'Access From',
      type: 'pie',
      radius: ['45%', '55%'],
      center: ['50%', '55%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ]
    }
  ]
};
const alertZhuOption = {
  title: {
    text: '近7天警告统计',
    left: 'center',
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  grid: {
    top: '15%',
    bottom: '0%',
    left: '3%',
    right: '4%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        alignWithLabel: true
      },
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      },
      splitLine: {
        show: false
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#ccc'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#6c757d',
          width: 1,
          type: 'solid'
        }
      },

    }
  ],
  series: [
    {
      name: '告警数量',
      type: 'bar',
      barWidth: '30%',
      data: [10, 52, 200, 334, 390, 330, 220],
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#3f51b5' },
              { offset: 1, color: '#673ab7' }
            ],
            false
          ),
          barBorderRadius: 5
        }
      }
    }
  ]
};

//能耗
const energyLeftOption = {
  title: {
    text: '智能设备',
    left: 'left',
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   top: '5%',
  //   left: 'center'
  // },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['60%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ]
    }
  ]
};
const energyRightOption = {
  title: {
    text: '能耗分类',
    left: 'left',
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  tooltip: {
    trigger: 'item'
  },
  // legend: {
  //   top: '5%',
  //   left: 'center'
  // },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['60%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: 52, name: '空调' },
        { value: 38, name: 'Direct' },
        { value: 25, name: 'Email' },
        { value: 20, name: 'Union Ads' },
        { value: 15, name: 'Video Ads' }
      ]
    }
  ]
};
const energyLineOption = {
  title: {
    text: '电量统计',
    left: 'left',
    textStyle: {
      fontSize: 14,
      color: '#ccc'
    }
  },
  grid: {
    top: '20%', // 顶部边距
    bottom: '0%', // 底部边距
    left: '0%', // 左侧边距
    right: '0%', // 右侧边距
    containLabel: true // 是否包含坐标轴的标签
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
    splitLine: {
      lineStyle: {
        color: '#6c757d',
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      name: 'Energy Consumption',
      data: [85, 91, 88, 80, 100, 125, 125],
      type: 'line',
      smooth: true,
      lineStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: '#3f51b5' }, // 蓝紫色渐变开始颜色
              { offset: 1, color: '#673ab7' }  // 蓝紫色渐变结束颜色
            ],
            false
          )
        }
      },
      areaStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
            [
              { offset: 0, color: 'rgba(63, 81, 181, 0.2)' },
              { offset: 1, color: 'rgba(103, 58, 183, 0.2)' }
            ],
            false
          )
        }
      }
    }
  ]
};

//客流
const passengerFlowLineOption = {
  grid: {
    top: '5%', // 顶部边距
    bottom: '0%', // 底部边距
    left: '0%', // 左侧边距
    right: '0%', // 右侧边距
    containLabel: true // 是否包含坐标轴的标签
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#666', // 添加x轴线颜色
      },

    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      color: '#ccc',
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: '#ccc', // 添加y轴线颜色
      },
    },
    splitLine: {
      lineStyle: {
        color: '#6c757d',
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      smooth: true,
      lineStyle: {
        color: '#4caf50', // 添加线条颜色
        width: 3, // 添加线条宽度
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: 'rgba(76, 175, 80, 0.8)' },
          { offset: 1, color: 'rgba(76, 175, 80, 0.2)' },
        ]), // 添加区域填充样式
      },
    },
  ],
};

//地图
const points = ref([
  // 散点图数据
  {
    name: "上海",
    value: [121.399244, 31.325152],
    itemStyle: { color: "#00EEFF" },
  }, // 广东
]);
const linesData = ref([
  {
    coords: [
      [121.399244, 31.325152]
    ]
  }, // 北京->广东
]);
const planePath = // 飞机svg
  "path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z";

const mapOption = {
  backgroundColor: "transparent", // 背景颜色
  geo: {
    // 地图配置
    zoom: 1,
    roam: true, // 允许缩放和平移
    map: "china",
    label: {
      // 图形上的文本标签
      normal: {
        // 通常状态下的样式
        show: false,
        textStyle: {
          color: "#fff",
        },
      },
      emphasis: {
        // 鼠标放上去高亮的样式
        textStyle: {
          color: "#fff",
        },
      },
    },
    itemStyle: {
      // 地图区域的样式设置
      normal: {
        // 通常状态下的样式
        borderColor: "#898686",
        borderWidth: 1,
        areaColor: '#084187'
        // areaColor: {
        //   //地图区域的颜色
        //   type: "radial", // 径向渐变
        //   x: 0.5, // 圆心
        //   y: 0.5, // 圆心
        //   r: 0.8, // 半径
        //   colorStops: [
        //     {
        //       // 0% 处的颜色
        //       offset: 0,
        //       color: "rgba(0, 102, 154, 0)",
        //     },
        //     {
        //       // 100% 处的颜色
        //       offset: 1,
        //       color: "rgba(0, 102, 154, .4)",
        //     },
        //   ],
        // },
      },
      // 鼠标放上去高亮的样式
      emphasis: {
        areaColor: "#2386AD",
        borderWidth: 1,
      },
    },
  },
  // visualMap: {
  //   min: 0, // 最小值
  //   max: 20, // 最大值
  //   calculable: true, // 是否启用拖拽重计算
  //   piecewise: true, // 使用分段视觉映射
  //   pieces: [
  //     { gte: 20, color: '#ff0000' }, // 红色
  //     { gte: 10, lte: 20, color: '#ff9900' }, // 橙色
  //     { gte: 5, lte: 10, color: '#ffcc00' }, // 黄橙色
  //     { gte: 1, lte: 5, color: '#00ff00' }, // 绿色
  //     { lte: 1, color: '#99ff99' } // 浅绿色
  //   ],

  //   textStyle: {
  //     color: '#fff'
  //   },
  //   show: true,
  //   orient: 'vertical', // 方向：水平或垂直
  //   left: 'left', // 位置：左边
  //   top: 'middle' // 位置：中间
  // },
  series: [
    // {
    //   // 散点系列数据
    //   type: "effectScatter", // 带有涟漪特效动画的散点（气泡）图
    //   coordinateSystem: "geo", //该系列使用的坐标系:地理坐标系
    //   // 特效类型,目前只支持涟漪特效'ripple'，意为“涟漪”
    //   effectType: "ripple",
    //   // 配置何时显示特效。可选'render'和'emphasis' 。
    //   showEffectOn: "render",
    //   rippleEffect: {
    //     // 涟漪特效相关配置。
    //     period: 10, // 动画的周期，秒数。
    //     scale: 4, // 动画中波纹的最大缩放比例。
    //     // 波纹的绘制方式，可选 'stroke' 和 'fill'。
    //     brushType: "fill",
    //   },
    //   zlevel: 1, // 所有图形的 zlevel 值。
    //   // data: points.value,
    // },
    // {
    //   // 线条系列数据
    //   type: "lines",
    //   zlevel: 2,
    //   symbol: ["none", "arrow"], // 标记的图形: 箭头
    //   symbolSize: 10, // 标记的大小
    //   effect: {
    //     // 线条特效的配置
    //     show: true,
    //     period: 6, // 特效动画的时间，单位s
    //     trailLength: 0, // 特效尾迹的长度。取值[0,1]值越大，尾迹越重
    //     symbol: planePath, // 特效图形的标记 可选'circle'等
    //     symbolSize: 15, // 特效标记的大小
    //   },
    //   lineStyle: {
    //     // 线条样式
    //     normal: {
    //       color: "#93EBF8",
    //       width: 2.5, // 线条宽度
    //       opacity: 0.6, // 尾迹线条透明度
    //       curveness: 0.2, // 尾迹线条曲直度
    //     },
    //   },
    //   data: linesData.value,
    // },
  ],
};

//工单
const orderRef = ref([])
const orderOption = {
  grid: {
    top: '5%',
    bottom: '0%',
    left: '0%',
    right: '5%',
    containLabel: true // 保持标签包含
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    formatter: function (params) {
      return params.value + '1单'; // 自定义提示信息
    }
  },
  xAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        color: "#ccc",
      },
      splitLine: {
        lineStyle: {
          color: '#6c757d',
          width: 1,
          type: 'solid'
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'category', // x轴变为分类轴
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        color: "#ccc",
      },
    }
  ],
  series: [
    {
      name: 'Direct',
      type: 'bar',
      barWidth: '30%', // 减少柱状图宽度
      data: [10, 52, 200, 334, 390, 330, 220],
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0, 0, 1, 0,
            [
              { offset: 0, color: '#08d9d6' }, // 蓝绿渐变开始颜色
              { offset: 1, color: '#ff2e63' }  // 蓝绿渐变结束颜色
            ],
            false
          ),
          barBorderRadius: 5 // 可选地添加圆角效果
        }
      },
      label: {
        normal: {
          show: true,
          position: 'right', // 显示在柱状图的右侧
          formatter: '{c}', // 自定义标签文本
          color: '#333',
          fontSize: 14
        }
      }
    }
  ]
};
const updateWindowSize = () => {
  nextTick(() => {
    const gatewayHeight = document.getElementsByClassName('left-up')[0].scrollHeight - document.getElementsByClassName('header')[0].scrollHeight
    gatewayChart.resize({ height: gatewayHeight });
    console.log("gatewayHeight", gatewayHeight)
  })
}




// 定义设备列表数据
const list = ref([]);
let onlineRate = 0;
const assetsList = ref([])
const alertList = ref([])
// 定义滚动组件 就是我们上边在dom上定义的ref
const cScroll = ref();
const assetsScroll = ref()
//获取网关统计信息
const gatewayData = async () => {
  const response = await serviceAxios({
    url: interfaces.get_statistical_data,
    method: 'post',
  })
  let gatewayStatictics = response.data.data
  gatewayOption.series[0].data = [
    { value: gatewayStatictics.online, name: "在线", itemStyle: { color: '#1661ab' } }
    , { value: gatewayStatictics.offline, name: "离线", itemStyle: { color: '#d11a2d' } },
    { value: gatewayStatictics.unknown, name: "未知" }
  ]
  onlineRate = gatewayStatictics.online / gatewayStatictics.total
  console.log("gatewayOption", gatewayOption)
}

//获取预警信息
const getAlertData = async () => {
  let response = await serviceAxios({
    url: interfaces.zabbixAlert,
    method: 'get'
  })
  let data = response.data.data.list;
  alertRef.value.deviceOffline = data.filter(item => item.type == '设备离线')[0].count;
  alertRef.value.interfaceOffline = data.filter(item => item.type == '接口断开')[0].count;
  alertRef.value.weakSignal = data.filter(item => item.type == 'RSRP信号弱')[0].count;
  alertRef.value.flowAnomaly = data.filter(item => item.type == '流量异常')[0].count;

  const alertTypeData = Object.entries(response.data.data.dict).map(([key, value]) => ({
    name: key,
    value: value
  }));
  alertPieOption.series[0].data = alertTypeData

  response = await serviceAxios({
    url: interfaces.offlineAlertGroupByTime,
    method: 'get',
    params: {
      step: 2,
      num: 7
    }
  })
  data = response.data.data;
  alertZhuOption.xAxis[0].data = data.map(item => item.day + '号').slice().reverse()
  alertZhuOption.series[0].data = data.map(item => item.count).slice().reverse()


}

//资产统计 
const assetsStatictics = async () => {
  const response = await serviceAxios({
    url: interfaces.assetsStatictics,
    method: 'get'
  })

  assetsList.value = response.data.data.detail.map(item => ({
    title: item.name,
    count: item.count,
    offline: item.offline
  }))
  console.log('ssetsList.value', assetsList.value)
  // assetsScroll.value.Reset();
}

//工单统计 
const orderStatictics = async () => {
  let response = await serviceAxios({
    url: interfaces.workOrderStatictics,
    method: 'get'
  })

  orderRef.value = response.data.data.detail

  response = await serviceAxios({
    url: interfaces.workOrderGroupByTime,
    method: 'get',
    params: {
      step: 1,
      num: 5
    }
  })

  let week = response.data.data.map(item => item.week + '周')
  let count = response.data.data.map(item => item.count)
  week[0] = '本周'
  week = week.slice().reverse();
  count = count.slice().reverse();
  orderOption.yAxis[0].data = week
  orderOption.series[0].data = count

  // assetsScroll.value.Reset();
}

// alertGroupByRegion: "/v1/ops/count/store/zabbix/alert/group_by_region",
//获取区域警报
const alertGroupByRegion = async (province, city) => {
  //获取地区预警数据，将含有警报的地区变红
  console.log(province, city)
  province = province == '' ? null : province
  city = city == '' ? null : city
  const response = await serviceAxios({
    url: interfaces.alertGroupByRegion,
    method: 'get',
    params: {
      province: province,
      city: city,
      step: 1,
      only_offline: 0
    }
  })
  if (response.data.message == 'success') {
    const data = response.data.data.detail

    //将含有预警的门店区域颜色变红
    const validData = data.filter(item => item.alert_count != 0 && item.store_count != 0).map(item => ({
      name: item.name,
      severity: Math.floor((item.alert_count * 100) / item.store_count)
    }))
    const colors = {
      red: '#FF0000',
      orange: '#FFA500',
      yellowOrange: '#FFCC00',
      green: '#00FF00',
      lightGreen: '#99FF99'
    };
    mapOption.geo.regions = []
    validData.forEach(item => {
      mapOption.geo.regions.push({
        name: item.name, itemStyle: { normal: { areaColor: '#FF0000' } } //红色
      })
    })


    //统计门店数量和预警数量
    inMapDataRef.value.storeCount = 0;
    inMapDataRef.value.alertCount = 0;
    inMapDataRef.value.storeCount = data.reduce((accumulator, item) => {
      if (item.store_count !== 0) {
        return accumulator + item.store_count;
      }
      return accumulator;
    }, inMapDataRef.value.storeCount || 0);
    inMapDataRef.value.alertCount = data.reduce((accumulator, item) => {
      if (item.alert_count !== 0) {
        return accumulator + item.alert_count;
      }
      return accumulator;
    }, inMapDataRef.value.alertCount || 0);

  }
  else {
    // message.error(response.data.message)
    message.error('该区域不可进入')
  }




}
// alertGroupByRegion()
//总览点击事件
const generalViewClick = async () => {
  mpdata = china
  // echarts.registerMap("china", /**/ china);
  // mapOption.series[0].map = "china"
  province = ""
  city = ""
  await alertGroupByRegion(province, city)
  mapOption.geo.map = 'china'
  mapChart.setOption(mapOption);


  inMapDataRef.value.region = '全国'

}

//获取阿里地区数据
const getRegionData = async (adcode) => {
  // const regionResponse = await proxyRequest({
  //   url: '/areas_v3/bound/' + adcode + '_full.json',
  //   method: 'get'
  // })

  // return { data: regionResponse.data }
  // const filePath = `region_data/${adcode}.json`;
  // const regionResponse = await proxyRequest({
  //   url: filePath,//同文件夹下的json文件路径
  //   method: "get",
  //   // dataType: "json",
  // })

  const regionResponse = await serviceAxios({
    url: interfaces.aliMap + '/' + String(adcode) + '.json',
    method: 'get',

  })

  return { data: regionResponse.data.data }

  // return { data: regionResponse.data }




}
const getAlertHistory = () => {
  serviceAxios({
    url: interfaces.alertHistories,
    method: 'get',
    params: {
      limit: 40,
    }
  }).then(result => {
    if (result.data.message = 'success') {
      alertList.value = result.data.data.map(item => ({
        storeName: item.host_name,
        alertType: item.message.alert_type,
        alertTime: item.alert_time,
        duration: item.message['Problem duration'],
        problem: item.message['Problem name'],
      }))
    }
    else {
      message.error(result.data.message)
    }
  })
}

//自执行函数
(async () => {
  for (let i = 0; i < 10; i++) {
    list.value.push({ title: '类型' + i, online: '在线' + i, offline: '离线' + i },)
  }
  getAlertHistory()
  nextTick(() => {

    // 注意这个重置方法是大写的R -> Reset,而不是小写的reset();
    // 很多教程写错了，这里就会报错
    // cScroll.value.Reset();
  })
  // getAlertData()alertHistories

})()

onMounted(() => {
  mpdata = china
  nextTick(async () => {

    await getAlertData()
    await gatewayData()
    await assetsStatictics()
    await orderStatictics()


    //网关设备

    let maxDataIndex = 0;
    let maxValue = 0;
    gatewayOption.series[0].data.forEach((item, index) => {
      if (item.value > maxValue) {
        maxValue = item.value;
        maxDataIndex = index;
      }
    });

    var chartDom = document.getElementById('gatewayChart');
    gatewayChart = echarts.init(chartDom);
    const gatewayHeight = document.getElementsByClassName('left-up')[0].scrollHeight - document.getElementsByClassName('header')[0].scrollHeight
    gatewayChart.resize({ height: gatewayHeight });
    gatewayChart.setOption(gatewayOption);
    // 在图表初始化之后立即触发 emphasis 效果
    gatewayChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0, // 0 表示第一个 series
      dataIndex: maxDataIndex // 设置最大值数据项为强调状态
    });



    //告警

    maxDataIndex = 0;
    maxValue = 0;
    alertPieOption.series[0].data.forEach((item, index) => {
      if (item.value > maxValue) {
        maxValue = item.value;
        maxDataIndex = index;
      }
    });
    chartDom = document.getElementById('alertPieChart');
    alertPieChart = echarts.init(chartDom);
    const alertHeight = document.getElementsByClassName('left-middle')[0].scrollHeight - document.getElementsByClassName('header')[0].scrollHeight
    alertPieChart.resize({ height: alertHeight * 0.4 });
    alertPieChart.setOption(alertPieOption)
    alertPieChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0, // 0 表示第一个 series
      dataIndex: maxDataIndex // 设置最大值数据项为强调状态
    });

    chartDom = document.getElementById('alertZhuChart');
    alertZhuChart = echarts.init(chartDom);
    alertZhuChart.resize({ height: alertHeight * 0.5 });
    alertZhuChart.setOption(alertZhuOption)

    //实时能耗

    chartDom = document.getElementById('energyLeftChart');
    energyLeftChart = echarts.init(chartDom);
    let chartHeight = document.getElementsByClassName('right-up')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    energyLeftChart.resize({ height: chartHeight * 0.5 });
    energyLeftChart.setOption(energyLeftOption)

    chartDom = document.getElementById('energyRightChart');
    energyRightChart = echarts.init(chartDom);
    energyRightChart.resize({ height: chartHeight * 0.5 });
    energyRightChart.setOption(energyRightOption)

    chartDom = document.getElementById('energyLineChart');
    energyLineChart = echarts.init(chartDom);
    energyLineChart.resize({ height: chartHeight * 0.5 });
    energyLineChart.setOption(energyLineOption)

    //客流
    chartDom = document.getElementById('passengerFlowLineChart');
    passengerFlowLineChart = echarts.init(chartDom);
    chartHeight = document.getElementsByClassName('right-middle-box')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    passengerFlowLineChart.resize({ height: chartHeight });
    passengerFlowLineChart.setOption(passengerFlowLineOption)


    //工单
    // orderChart

    chartDom = document.getElementById('orderChart');
    orderChart = echarts.init(chartDom);
    chartHeight = document.getElementsByClassName('right-down')[0].scrollHeight - 10 - document.getElementsByClassName('header')[0].scrollHeight
    let chartWeight = document.getElementsByClassName('right-down')[0].scrollWidth - 10 - document.getElementsByClassName('order-statistics')[0].scrollWidth
    console.log('right-down', document.getElementsByClassName('right-down')[0].scrollHeight, document.getElementsByClassName('header')[0].scrollHeight)

    orderChart.resize({ height: chartHeight, width: chartWeight });
    orderChart.setOption(orderOption)

    //地图
    mapChart = echarts.init(document.getElementById('mapChart'));
    const mapchartWidth = document.getElementsByClassName('middle-middle')[0].scrollWidth - 10
    const mapchartHeight = document.getElementsByClassName('middle-middle')[0].scrollHeight - 10
    echarts.registerMap("china", /**/ china);




    // const data = [
    //   { name: "西藏自治区", value: 28 },
    //   { name: "青海省", value: 10 },
    //   { name: "广东省", value: 10 },
    //   { name: "新疆维吾尔自治区", value: 10 },
    //   { name: "内蒙古自治区", value: 10 },
    //   { name: "河南省", value: 0 },
    // ];
    // mapOption.geo.regions = [
    //   { name: '上海市', itemStyle: { normal: { areaColor: '#FD9A9A' } } }
    // ]
    await alertGroupByRegion()
    // 添加数据到地图配置中
    // mapOption.series.push({
    //   name: 'China Map',
    //   type: 'map',
    //   map: 'china',
    //   roam: false,
    //   label: {
    //     show: false // 默认不显示标签
    //   },
    //   tooltip: {
    //     trigger: 'item', // 触发方式为项
    //     formatter: '{b}' // 显示项的名称
    //   },
    //   emphasis: {
    //     label: {
    //       show: true, // 鼠标悬停时显示标签
    //       fontSize: 14 // 标签字体大小
    //     },
    //     itemStyle: {
    //       areaColor: null, // 鼠标悬停时保留原有颜色
    //       borderColor: '#5089EC', // 边框颜色
    //       borderWidth: 1 // 边框宽度
    //     }
    //   },
    //   data: data
    // });
    mapChart.resize({ height: mapchartHeight, width: mapchartWidth });
    mapChart.setOption(mapOption)
    //点击进入地区事件
    mapChart.on("click", async function (params) {
      if (province == "") {
        province = params.name

        inMapDataRef.value.region = province  //修改地图地区字段的显示
      } else {
        city = city == "" ? params.name : city
        inMapDataRef.value.region = city  //修改地图地区字段的显示
      }
      await alertGroupByRegion(province, city) //获取地区预警数据，将含有警报的地区变红

      console.log(params.name, mpdata)
      let filterRegion = mpdata.features.filter(item =>
        item.properties.name == params.name
      );
      mpdata = await getRegionData(filterRegion[0].properties.adcode)  //获取ali地图数据
      mpdata = mpdata.data
      console.log(filterRegion[0].properties.adcode, params.name, mpdata)

      mapOption.geo.map = params.name
      echarts.registerMap(params.name, /**/ mpdata);
      // mapOption.series[0].map = params.name
      if (params.name === '海南省') {
        console.log('access current')
        mapOption.geo.center = [109.844902, 19.0392];//中心位置
        mapOption.geo.layoutCenter = ['50%', '40%'];//地图相对容器偏移
        mapOption.geo.layoutSize = "380%";//地图放大比例
      } else { //非显示海南时，将设置的参数恢复默认值
        mapOption.geo.center = undefined;
        mapOption.geo.layoutCenter = undefined;
        mapOption.geo.layoutSize = undefined;
      }

      mapChart.setOption(mapOption, true);

      mapOption.geo.center = undefined;
      mapOption.geo.layoutCenter = undefined;
      mapOption.geo.layoutSize = undefined;

    });

  })
  window.addEventListener('resize', updateWindowSize)
})
onUnmounted(() => {
  if (gatewayChart) gatewayChart.dispose();
  if (alertPieChart) alertPieChart.dispose();
  if (alertZhuChart) alertZhuChart.dispose();
  if (energyLeftChart) energyLeftChart.dispose();
  if (energyRightChart) energyRightChart.dispose();
  if (energyLineChart) energyLineChart.dispose();
  if (passengerFlowLineChart) passengerFlowLineChart.dispose();
  if (mapChart) mapChart.dispose();
  window.removeEventListener('resize', updateWindowSize)
})


</script>

<style lang='less' scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: "微软雅黑";
  // background: linear-gradient(120deg, #e0f7fa, #f1f8e9, #fff8e1, #e8f5e9);
  background: -webkit-radial-gradient(
    50% 35%,
    farthest-corner,
    #1965ab,
    #044c8e,
    #03407a,
    #013064
  );
  // #034f8e,
  // #034987,
  // #02366d,
  // #002353
  // background: url(../static/homeImage/pageBg.png) center no-repeat;
  // background-size: 200% 200%; /* 扩展背景尺寸以实现流动效果 */
  // animation: backgroundFlow 10s ease infinite;
}
// @keyframes backgroundFlow {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }
.left-container {
  width: 22%;
  height: 100%;
  padding: 5px;
  .left-up {
    height: 30%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    // padding: 10px;
    border-radius: 8px;

    border: 1px solid rgba(7, 118, 181, 0.5);
    box-shadow: inset 0 0 0.5rem #1a7cc8;
  }
  .left-middle {
    height: 70%;
    padding: 5px 0px 0px 0px;

    .left-middle-box {
      height: 100%;
      border: 1px solid rgba(7, 118, 181, 0.5);
      box-shadow: inset 0 0 0.5rem #1a7cc8;
      border-radius: 5px;
      background-color: transparent;
    }
  }
  .left-down {
    height: 20%;
    width: 100%;
    border: 1px solid #dcd8d8;
    border-radius: 5px;
    background-color: transparent;
  }
}

.middle-container {
  padding: 0px 0px 5px 0px;
  width: 56%;
  height: 100%;

  .span-it {
    font-family: "Arial Unicode MS", sans-serif; /* 使用艺术字体 */
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(to right, #00bfff, #007bff); /* 渐变背景颜色 */
    -webkit-background-clip: text; /* 使背景仅应用于文本 */
    -webkit-text-fill-color: transparent; /* 使文本颜色透明，只显示背景颜色 */
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
  }
  // border: 1px solid black;
  .middle-up {
    height: 20%;
    border: 1px solid #dcd8d8;
    border-radius: 5px;
    background-color: transparent;
  }
  .middle-middle {
    height: 70%;
    width: 100%;
    padding: 5px 0px 5px 0px;
    .middle-middle-box {
      width: 100%;
      border: 1px solid rgba(7, 118, 181, 0.5);
      box-shadow: inset 0 0 0.5rem #1a7cc8;
      border-radius: 5px;
      background-color: transparent;
    }
    .map-click {
      position: absolute;
      z-index: 1000;
      margin: 24px;

      a {
        color: #ccc;
        font-size: 15px;
      }
    }
  }
  .middle-down {
    display: flex;
    height: 30%;

    .middle-down-left {
      width: 60%;
      height: 100%;
      overflow: hidden;
      border: 1px solid rgba(7, 118, 181, 0.5);
      box-shadow: inset 0 0 0.5rem #1a7cc8;
      border-radius: 5px;
      background-color: transparent;

      .art-text {
        font-size: 16px; /* 设置字体大小 */
        text-align: center; /* 居中文本 */

        color: #ccc; /* 透明颜色 */
      }

      .alert-row {
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 0;
      }

      .store-name {
        font-size: 14px;
        color: #e4dddd;
      }

      .alert-type {
        font-size: 14px;
        color: #ff4d4f;
        font-weight: 500;
      }

      .alert-time {
        font-size: 14px;
        color: #1890ff;
      }

      .duration {
        font-size: 14px;
        color: #52c41a;
      }

      .problem {
        font-size: 14px;
        color: #595959;
        font-style: italic;
      }

      .content-text {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .middle-down-right {
      width: 40%;
      height: 100%;

      border-radius: 5px;
      background-color: transparent;
      .middle-down-right-box {
        overflow: hidden;
        border: 1px solid rgba(7, 118, 181, 0.5);
        box-shadow: inset 0 0 0.5rem #1a7cc8;
      }
    }
  }
}

.right-container {
  width: 22%;
  height: 100%;
  padding: 5px;
  // border: 1px solid black;
  .right-up {
    height: 40%;
    border: 1px solid rgba(7, 118, 181, 0.5);
    box-shadow: inset 0 0 0.5rem #1a7cc8;
    border-radius: 5px;
    background-color: transparent;
  }
  .right-middle {
    height: 30%;
    padding: 5px 0px 5px 0px;
    .right-middle-box {
      height: 100%;
      border: 1px solid rgba(7, 118, 181, 0.5);
      box-shadow: inset 0 0 0.5rem #1a7cc8;
      border-radius: 5px;
      background-color: transparent;
    }
  }
  .right-middle2 {
    height: 20%;
    padding: 5px 0px 5px 0px;
    .right-middle2-box {
      height: 100%;
      border: 1px solid rgba(7, 118, 181, 0.5);
      box-shadow: inset 0 0 0.5rem #1a7cc8;
      border-radius: 5px;
      background-color: transparent;
    }
  }
  .right-down {
    height: 30%;
    border: 1px solid rgba(7, 118, 181, 0.5);
    box-shadow: inset 0 0 0.5rem #1a7cc8;
    border-radius: 5px;
    background-color: transparent;

    .order-statistics {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90px;

      font-size: 14px;
      text-align: center;

      font-weight: bold;
      background: -webkit-linear-gradient(#00bfff, #007bff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .order-statistics div {
      padding: 10px 0;
    }
  }
}
.header {
  // font-weight: bold;
  font-size: 15px;
  position: relative;
  padding-bottom: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; /* 使用现代简约的字体 */
  color: #f1eaea; /* 深灰色文字，提升高级感 */
  letter-spacing: 2px; /* 字符间距 */

  border-bottom: 1px solid rgba(7, 118, 181, 0.7);
}

//块内小标题样式 例如在线率 设备离线等
.status-text {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background-color: rgba(255, 255, 255, 0.8); /* 半透明背景 */
  // padding-right: 8px 16px;
  padding-right: 16px;
  border-radius: 4px;
  border: none;
  // box-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
  //   0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.2); /* 光晕渐变 */
}
.percentage {
  font-size: 15px;
  font-weight: bold;
  position: relative;
  background: -webkit-linear-gradient(#00bfff, #007bff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* 如果需要兼容旧版浏览器，可以添加一个回退颜色 */
.percentage::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  color: #007bff; /* 回退颜色 */
  z-index: -1;
  opacity: 0.2; /* 可以根据需要调整透明度 */
}

.label {
  font-size: 14px; /* 减小标签字体大小 */
  color: #6f89a0; /* 更改标签字体颜色 */
}

//滑动框样式
.column-title,
.column-count,
.column-offline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 16px;
  font-size: 16px;
  color: #333;
}

.column-title {
  color: #a4a0a0;
  font-size: 14px;
  // font-weight: bold;
}

.column-count {
  color: #007bff;
}

.column-offline {
  color: #dc3545;
}

.even-row .column-title,
.even-row .column-count,
.even-row .column-offline {
  background-color: #f9f9f9;
}

/* 可选：为行添加下边框 */
a-row {
  border-bottom: 1px solid #e9ecef;
}
</style>