<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="modalShow.addOrEdit"
           title="发票信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="submit"
           :maskClosable="false">
    <table class='mailTable'>
      <template v-for="equips in groupFields"
                :key="equips.key">
        <tr>
          <template v-for="item in equips"
                    :key="item.key">
            <td class='column'
                v-if="!['desc'].includes(item.dataIndex)">
              {{item.title}}<span style="color: red;margin-left: 0px;">*</span>
            </td>
            <td v-else
                class='column'>
              {{item.title}}
            </td>

            <td v-if="!['desc'].includes(item.dataIndex)">
              <a-select v-if="['account_id','pay_status','invoice_type_id'].includes(item.dataIndex)"
                        :bordered="false"
                        v-model:value="baseData[item.dataIndex]"
                        style="width:200px"
                        :showArrow="false"
                        :placeholder="`请选择${item.title}`"
                        :options="options[item.dataIndex]"
                        @change="handleChangeCustomer"
                        show-search
                        :filter-option="filterOption"></a-select>
              <a-cascader v-else-if="['contract_id'].includes(item.dataIndex)"
                          :bordered="false"
                          style="width:auto"
                          v-model:value="baseData.contract_message"
                          :options="options[item.dataIndex]"
                          :placeholder="`请选择${item.title}`"
                          @change="contractChange" />

              <a-input-number v-else-if="['pay_amount'].includes(item.dataIndex)"
                              style="width:100%"
                              :bordered="false"
                              v-model:value="baseData[item.dataIndex]"
                              :controls="false"
                              :step="0.01"
                              :placeholder="`请输入${item.title}`"></a-input-number>

              <a-config-provider :locale="locale"
                                 v-else-if="['pay_time'].includes(item.dataIndex)">
                <a-date-picker show-time
                               :bordered=false
                               v-model:value="baseData['pay_time_time']"
                               format="YYYY-MM-DD HH:mm:ss"
                               :placeholder="['请选择日期', '请选择时间']"
                               @change="onPayTimeChange">
                </a-date-picker>
              </a-config-provider>
              <!-- <div style='display:flex' v-else-if="['pay_duration'].includes(item.dataIndex)">
                <a-input-number style="width:100%" :bordered="false" v-model:value="baseData[item.dataIndex]"
                  :controls="false" :placeholder="`请输入${item.title}`"></a-input-number>

                <a-select v-model:value="baseData.duration_unit" style="width:80px" :showArrow="false"
                  :placeholder="`请选择周期`" :options="options.duration_unit" @change="handleChangeCustomer" show-search
                  :filter-option="filterOption"></a-select>
              </div> -->

              <a-input v-else
                       :bordered="false"
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`"></a-input>
            </td>

            <td v-else
                colspan="3">
              <a-input :bordered="false"
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`"></a-input>
            </td>

          </template>
        </tr>

      </template>

    </table>

    <div style="margin-top:3px"
         v-if="modalShow.contractMessage">

      <table class="mailTable"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td colspan="4"><strong>合同信息</strong></td>
        </tr>

        <tr>
          <td class="column">ID</td>
          <td>{{contractInfoData.id}}</td>
          <td class="column">品牌</td>
          <td>{{contractInfoData.brand}}</td>

        </tr>

        <tr>
          <td class="column">区域</td>
          <td>{{contractInfoData.region}}</td>
          <td class="column">门店名称</td>
          <td>{{contractInfoData.storeName}}</td>

        </tr>
        <tr>
          <td class="column">门店地址</td>
          <td colspan="3">{{contractInfoData.region}} {{contractInfoData.address}}</td>

        </tr>

        <tr>
          <td class="column">品牌公司</td>
          <td>{{contractInfoData.officialName}}</td>
          <td class="column">门店固话</td>
          <td>{{contractInfoData.telephone}}</td>

        </tr>

        <tr>
          <td class="column">联系人</td>
          <td>{{contractInfoData.contactName}}</td>
          <td class="column">联系方式</td>
          <td>{{contractInfoData.phone}}</td>
        </tr>
        <tr>
          <td class="column">计划开通时间</td>
          <td>{{contractInfoData.planBeginDate}}</td>
          <td class="column">运行状态</td>
          <td>运行中 <div class="green-light"></div>
          </td>
        </tr>
        <tr>

          <td class="column">实际开通时间</td>
          <td>{{contractInfoData.actualDate}}</td>
          <td class="column">实际结束时间</td>
          <td>{{contractInfoData.endDate}}</td>
        </tr>

        <tr>
          <td class="column">付费方式</td>
          <td>{{contractInfoData.paymentType}}</td>
          <td class="column">合同签约周期</td>
          <td>{{contractInfoData.duration}}{{contractInfoData.paymentType}}</td>
        </tr>
        <tr>
          <td class="column">
            签约公司
          </td>
          <td>{{contractInfoData.signed_company}}</td>
          <td class="column">
            签约人
          </td>
          <td>{{contractInfoData.signed_person}}</td>
        </tr>
        <tr>

          <td class="column">付费金额</td>
          <td>{{contractInfoData.paymentAmount}}元</td>
        </tr>

        <tr>

          <td class="column">产品服务</td>
          <td colspan="3"
              v-if="contractInfoData.productService.length>0">
            <thead>
              <tr>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">产品名称</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">计量单位</td>
                <td style="width:200px;background-color: rgb(188, 188, 217);  "
                    scope="col">单价(元)</td>
              </tr>
            </thead>
            <tbody v-for="production in contractInfoData.productService"
                   :key=production.id>
              <tr>
                <td scope="row">{{production.name}}</td>
                <td>{{production.quantity}}{{production.billing_mode}}</td>
                <td>{{production.unit_price}}</td>
              </tr>

            </tbody>
            <div style="display: flex; justify-content: flex-end;  margin-right:45px">
              <div>总价：<strong>{{contractInfoData.total_price}}</strong> 元</div>
            </div>

          </td>
          <td colspan="3"
              v-else></td>
        </tr>
        <tr>
          <td class="column">付款信息</td>
          <td colspan="3">

            <thead>
              <tr v-if="contractInfoData.revenue_records.length!=0">

                <td v-if="contractInfoData.revenue_records.length >0"
                    class='th-product'>类型</td>
                <td v-if="contractInfoData.revenue_records.length >0"
                    class='th-product'>价格（元）</td>
                <td v-if="contractInfoData.revenue_records.length >0"
                    class='th-product'>付款时间</td>
                <td v-if="contractInfoData.revenue_records.length >1"
                    style="width:100px;"><a style="color:#1884ea"
                     @click="toggleExpand">{{ isExpanded ? '展开' : '收起' }}</a>
                </td>
                <td v-if="contractInfoData.revenue_records.length==0">无付款信息</td>
              </tr>
            </thead>
            <template v-if="isExpanded &&contractInfoData.revenue_records.length!=0 ">
              <tbody>
                <tr>
                  <td> {{contractInfoData.revenue_records[0].invoice_account_name}}</td>
                  <td> {{ decimalUtils.ensureAtLeastTwoDecimals(contractInfoData.revenue_records[0].pay_amount) }}</td>
                  <td> {{contractInfoData.revenue_records[0].pay_time}}</td>
                </tr>

              </tbody>
            </template>

            <template v-if="!isExpanded">
              <tbody v-for="(item) in revenue_records.data"
                     :key="item.id">
                <tr>
                  <td> {{item.invoice_account_name}}</td>
                  <td> {{ decimalUtils.ensureAtLeastTwoDecimals(item.pay_amount) }}</td>
                  <td> {{item.pay_time}}</td>
                </tr>

              </tbody>
            </template>

          </td>
        </tr>
        <tr>
          <td class="column">描述</td>
          <td colspan="3">{{contractInfoData.desc}}</td>
        </tr>

      </table>
    </div>

  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='searchDivContainer'>
    <div class='right-buttons'>
      <a-button class="addDataButton"
                v-if="buttonShow.add"
                type="primary"
                @click="openSearchView">{{searchData.buttonValue}}</a-button>
      <a-button class="addDataButton"
                v-if="buttonShow.add"
                type="primary"
                @click="addData">添加</a-button>
      <a-button class="addDataButton"
                v-if="buttonShow.delete"
                @click="deleteData">删除数据</a-button>
    </div>
  </div>

  <a-table :columns="fieldAll.columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>

    <template #title>

      <div class='searchDivContainer'>
        <div class='searchDivContainer'
             v-if="searchData.searchViewFlag">
          <table class="searchTable">
            <template v-for="searchField in searchFields"
                      :key="searchField">
              <tr>
                <template v-for="item in searchField"
                          :key="item.key">
                  <td>
                    {{item.title}}
                  </td>
                  <td>
                    <!-- <a-select v-if="['type'].includes(item.dataIndex)" :bordered="false" :showArrow="false" v-model:value="searchData[item.dataIndex]" show-search
                    :placeholder="`请选择${item.title}`" style="width: 100px" :options="options['type']"
                    :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur"
                    @change="handleChange"></a-select>
                  <a-input  v-else :bordered="false" v-model:value="searchData[item.dataIndex]" :placeholder="`输入${item.title}`"></a-input> -->

                    <a-select v-if="['account_id',,'invoice_type_id'].includes(item.dataIndex)"
                              :bordered="false"
                              v-model:value="searchData[item.dataIndex]"
                              style="width:200px"
                              :showArrow="false"
                              :placeholder="`请选择${item.title}`"
                              :options="options[item.dataIndex]"
                              @change="handleChangeCustomer"
                              show-search
                              :filter-option="filterOption"></a-select>

                    <a-input-number v-else-if="['min_amount','max_amount'].includes(item.dataIndex)"
                                    style="width:100%"
                                    :bordered="false"
                                    v-model:value="searchData[item.dataIndex]"
                                    :controls="false"
                                    :step="0.01"
                                    :placeholder="`请输入${item.title}`"></a-input-number>

                    <a-config-provider :locale="locale"
                                       v-else-if="['start_time_time','end_time_time'].includes(item.dataIndex)">
                      <a-date-picker show-time
                                     :bordered=false
                                     v-model:value="searchData[item.dataIndex]"
                                     format="YYYY-MM-DD HH:mm:ss"
                                     :placeholder="['请选择日期', '请选择时间']"
                                     @change="value =>onSearchTimeChange(value,item.dataIndex)">
                      </a-date-picker>
                    </a-config-provider>

                    <a-input v-else
                             :bordered="false"
                             v-model:value="searchData[item.dataIndex]"
                             :placeholder="`请输入${item.title}`"></a-input>
                  </td>
                </template>
              </tr>
            </template>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
        </div>

      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
  <script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { decimalUtils } from "@/utils/decimalUtils";

import { checkTree } from "@/utils/regionTree";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import dayjs from "dayjs";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import itMaintenance from "../synthesis/product/itMaintenance.vue";
const locale = zhCN;

const url = {
  add: interfaces.contract_revenue_record_add,
  delete: interfaces.contract_revenue_record_delete,
  edit: interfaces.contract_revenue_record_edit,
  page: interfaces.contract_revenue_record_page,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "收款科目", dataIndex: "account_name", key: "account_name", width: "100px", ellipsis: true },
    { title: "付款单位", dataIndex: "pay_company", key: "pay_company", width: "100px", ellipsis: true },
    { title: "收款单位", dataIndex: "receive_company", key: "receive_company", width: "100px", ellipsis: true },
    { title: "收款人", dataIndex: "payee", key: "payee", width: "100px", ellipsis: true },
    { title: "金额(元)", dataIndex: "pay_amount", key: "pay_amount", width: "100px", ellipsis: true },
    { title: "发票号码", dataIndex: "invoice_number", key: "invoice_number", width: "100px", ellipsis: true },
    { title: "付款周期", dataIndex: "pay_duration", key: "pay_duration", width: "100px", ellipsis: true },
    { title: "付款状态", dataIndex: "pay_status", key: "pay_status", width: "100px", ellipsis: true },
    { title: "发票类型", dataIndex: "invoice_type_name", key: "invoice_type_name", width: "100px", ellipsis: true },
    { title: "日期", dataIndex: "pay_time", key: "pay_time", width: "100px", ellipsis: true },
    { title: "备注", dataIndex: "desc", key: "desc", width: "100px", ellipsis: true },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", width: "120px", ellipsis: true },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
    // { title: "品牌", dataIndex: "customer_names", key: "customer_names", width: "150px", ellipsis: true },
    // { title: "地区", dataIndex: "regions_str", key: "regions_str", width: "100px", width: "150px", ellipsis: true },
    // { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode", width: "100px", ellipsis: true },
    // { title: "收费金额", dataIndex: "unit_price", key: "unit_price", width: "100px", ellipsis: true },
    // { title: "产品包", dataIndex: "is_package_name", key: "is_package_name", width: "100px", ellipsis: true },
    // { title: "产品包服务", dataIndex: "children_name", key: "children_name", width: "150px", ellipsis: true },
    // { title: "描述", dataIndex: "desc", key: "desc" },
    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

  ],

  //弹框字段数据
  modalData: [
    { title: "合同", dataIndex: "contract_id", key: "contract_id" },
    { title: "付款单位", dataIndex: "pay_company", key: "pay_company" },
    { title: "收款单位", dataIndex: "receive_company", key: "receive_company" },
    { title: "收款人", dataIndex: "payee", key: "payee" },
    { title: "金额(元)", dataIndex: "pay_amount", key: "pay_amount" },
    { title: "收款科目", dataIndex: "account_id", key: "account_id" },

    { title: "付款周期", dataIndex: "pay_duration", key: "pay_duration" },
    { title: "付款状态", dataIndex: "pay_status", key: "pay_status" },
    { title: "发票类型", dataIndex: "invoice_type_id", key: "invoice_type_id" },
    { title: "发票号码", dataIndex: "invoice_number", key: "invoice_number" },
    { title: "日期", dataIndex: "pay_time", key: "pay_time" },
    { title: "备注", dataIndex: "desc", key: "desc" },
  ],

  //搜索框字段
  searchFields: [
    { title: "关键字", dataIndex: "keyword", key: "keyword" },
    { title: "收款人", dataIndex: "payee", key: "payee" },
    { title: "发票类型", dataIndex: "invoice_type_id", key: "invoice_type_id" },
    { title: "收款科目", dataIndex: "account_id", key: "account_id" },
    { title: "最早收款时间", dataIndex: "start_time_time", key: "start_time_time" },
    { title: "最晚收款时间", dataIndex: "end_time_time", key: "end_time_time" },
    { title: "最小金额", dataIndex: "min_amount", key: "min_amount" },
    { title: "最大金额", dataIndex: "max_amount", key: "max_amount" },
  ],

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)

//搜索框字段二位数组
const searchFields = commonFun.groupedItems(fieldAll.searchFields, 3)

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",


})



//选择框数据
const options = reactive({
  type: [],
  account_id: [], // 收款科目
  invoice_type_id: [], //发票类型
  contract_id: [], //合同
  pay_status: [{ label: "已支付", value: "已支付" }, { label: "未支付", value: "未支付" }], // 付款状态
});

// 数据模型
const baseData = reactive({
  id: "",
  pay_duration: "",
  // duration_unit: "年",
  contract_message: "",
  contract_id: "",
  pay_company: '',
  receive_company: '',
  payee: '',
  pay_amount: '',
  invoice_number: '',
  pay_status: '',
  invoice_type_id: '',
  account_id: '',
  pay_time: '',
  pay_time_time: '',
  desc: '',
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  contractMessage: false, // 添加里面的合同信息
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,

})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach((item) => {
      data.push({
        id: item.id,
        key: item.id,
        pay_duration: item.pay_duration,
        contract: item.contract,
        class_type: item.class_type,
        pay_company: item.pay_company,
        receive_company: item.receive_company,
        payee: item.payee,
        pay_amount: decimalUtils.ensureAtLeastTwoDecimals(item.pay_amount),
        principal: item.principal,
        tax: item.tax,
        invoice_number: item.invoice_number,
        pay_time: item.pay_time,
        pay_status: item.pay_status,
        desc: item.desc,
        create_time: item.create_time,
        last_modified: item.last_modified,
        invoice_type_id: item.invoice_type_id,
        account_id: item.account_id,
        account_name: item.account_name,
        invoice_type_name: `${item.invoice_type_name}(${item.tax}%)`,
        account_name: item.account_name,
      })
    });

    dataSource.data = data;
    // console.log(dataSource.data);
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;

    // if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
    //   baseData[key] = [];
    // }
  }
  baseData.duration_unit = "年",
    checkedKeys.value = [];
  modalShow.contractMessage = false

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData.pay_time_time = dayjs(record.pay_time, "YYYY-MM-DD HH:mm:ss")
  baseData.contract_id = record.contract.id
  getContractInfo(record.contract.id)
};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ['contract_id', 'pay_duration', 'pay_company', 'receive_company', 'payee', 'pay_amount', 'invoice_number', 'pay_status', 'invoice_type_id', 'account_id', 'pay_time']
  let flag = commonFun.checkFields(baseData, mustInputFields)

  // await Promise.all([formRef.value.validate()])
  if (flag == true) {
    let data = {
      id: baseData.id,
      pay_duration: baseData.pay_duration,
      duration_unit: baseData.duration_unit,
      contract_id: baseData.contract_id,
      pay_company: baseData.pay_company,
      receive_company: baseData.receive_company,
      payee: baseData.payee,
      pay_amount: baseData.pay_amount,
      invoice_number: baseData.invoice_number,
      pay_status: baseData.pay_status,
      invoice_type_id: baseData.invoice_type_id,
      account_id: baseData.account_id,
      pay_time: baseData.pay_time,
      desc: baseData.desc,
    }
    data = commonFun.removeNullValues(data)
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }
  else {
    message.error("请填写完整信息");
  }
};




const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  keyword: null, //关键字
  payee: null, //收款人
  invoice_type_id: null, //发票类型
  account_id: null, //收款科目

  start_time: null, //最早收款时间
  end_time: null, //最晚收款时间
  start_time_time: null, //最早收款时间
  end_time_time: null, //最晚收款时间

  min_amount: null, //最小金额
  max_amount: null, //最大金额


  buttonValue: "打开搜索",
  searchViewFlag: false  //判断是否打开搜索框
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag', 'start_time_time', 'end_time_time'].includes(key)) {
      queryField[key] = searchData[key]

    }
  }
  queryField = commonFun.removeNullStringValue(queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,

  }
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null
    }
  }
  console.log(searchData)
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};
//清洗区域树数据
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
      });
    }
  });
  return response;
};
//获取区域树
const getRegionTree = async () => {
  const tree = await checkTree()
  options.region = clearRegionalTree(tree)
};


getRegionTree()











const type_selector = () => {
  // finance_account_type_selector
  serviceAxios({
    url: interfaces.finance_account_type_selector,
    method: 'get',

  }).then(
    result => {

      options.type = result.data.data.map(item => ({
        label: item.name,
        value: item.value
      }))


    }
  ).catch(error => { console.log(error) })
}

const account_name_selector = () => {
  serviceAxios({
    url: interfaces.finance_account_page,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999
    }

  }).then(
    result => {

      options.account_id = result.data.data.list.map(item => ({
        label: `${item.name} (${item.code})`,
        value: item.id
      }))

    }
  ).catch(error => { console.log(error) })
}


const invoice_type_selector = () => {
  serviceAxios({
    url: interfaces.invoice_type_page,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(
    result => {
      options.invoice_type_id = result.data.data.list.map(item => ({
        label: `${item.name} (${item.tax_rate}%)`,
        value: item.id
      }))

    }
  ).catch(error => { console.log(error) })
}


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const onPayTimeChange = (value) => {
  if (value) {
    baseData.pay_time = value.format("YYYY-MM-DD HH:mm:ss")
  }
  else {
    baseData.pay_time_time = "";
    baseData.pay_time = "";
  }
}

const onSearchTimeChange = (value, field) => {
  let newField = field.slice(0, -4);
  if (value) {
    searchData[newField] = value.format("YYYY-MM-DD HH:mm:ss")
  }
  else {
    searchData[newField] = "";
    searchData[field] = "";
  }
}




const contractData = {}
let contractInfoData = reactive({})
const isExpanded = ref(true)
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}

//获取合同信息
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    result.data.data.list.forEach(item => {
      if (contractData[item.customer.id] != undefined) {

        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          //   customer_id: item.customer.id,
          //   brand: item.customer.name,
          //   storeName: item.contract_user.name,
          //   address: item.contract_user.address,
          //   contactName: item.contract_user.contact_name,
          //   contactPhone: item.contract_user.phone_number,
          //   productServices: item.product_services,
          //   region: item.region,
        })
      }
      else {

        contractData[item.customer.id] = {}
        contractData[item.customer.id].children = []
        contractData[item.customer.id].brand = item.customer.name
        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          //   customer_id: item.customer.id,
          //   brand: item.customer.name,
          //   storeName: item.contract_user.name,
          //   address: item.contract_user.address,
          //   contactName: item.contract_user.contact_name,
          //   contactPhone: item.contract_user.phone_number,
          //   productServices: item.product_services,
          //   region: item.region,
        })
      }
    })

    let data = []
    for (let key in contractData) {
      data.push({
        label: contractData[key].brand,
        value: key,
        children: contractData[key].children
      }
      )
    }

    options.contract_id = data
  }).catch((err) => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  })
}

let revenue_records = reactive({
  data: []
})

const contractChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  let contract_id = selectedOptions[1].contract_id
  baseData.contract_id = contract_id
  getContractInfo(contract_id)

}




const getContractInfo = (id) => {
  serviceAxios({
    url: interfaces.contractGetID,
    method: 'get',
    params: {
      id: id
    }
  }).then(res => {
    console.log(res)
    let data = res.data.data
    contractInfoData.id = data.id
    contractInfoData.customer_id = data.customer.id,
      contractInfoData.contract_user_id = data.contract_user_id,
      contractInfoData.brand = data.customer.name
    contractInfoData.region = data.region.friendly_name
    contractInfoData.storeName = data.contract_user.name
    contractInfoData.officialName = data.contract_user.official_name
    contractInfoData.address = data.contract_user.address
    contractInfoData.telephone = data.contract_user.telephone
    contractInfoData.contract_user_id = data.contract_user.id
    contractInfoData.contactName = data.contract_user.contact_name
    contractInfoData.phone = data.contract_user.phone_number
    contractInfoData.planBeginDate = data.planed_begin_date
    contractInfoData.actualDate = data.begin_date
    contractInfoData.endDate = data.end_date
    contractInfoData.paymentType = data.payment_type
    contractInfoData.duration = data.duration
    contractInfoData.payment_status = data.payment_status
    contractInfoData.paymentAmount = decimalUtils.ensureAtLeastTwoDecimals(data.payment_amount)
    contractInfoData.invoice_type = data.invoice_type
    contractInfoData.invoice_number = data.invoice_number
    contractInfoData.desc = data.desc
    contractInfoData.signed_company = data.signed_company
    contractInfoData.signed_person = data.signed_person
    contractInfoData.revenue_records = data.revenue_records

    let productName = []
    contractInfoData.total_price = 0
    if (data.product_services.length != 0) {
      data.product_services.forEach(item => {
        // productName.push(item.product.name)
        productName.push({
          id: item.id,
          name: item.product.name,
          quantity: item.quantity,
          unit_price: decimalUtils.ensureAtLeastTwoDecimals(item.unit_price),
          billing_mode: item.product.billing_mode
        })
        contractInfoData.total_price = decimalUtils.addWithPrecision(contractInfoData.total_price, item.unit_price)
      })
    }
    contractInfoData.total_price = decimalUtils.ensureAtLeastTwoDecimals(contractInfoData.total_price),


      baseData.contract_message = [String(contractInfoData.customer_id), Number(contractInfoData.contract_user_id)]

    contractInfoData.productService = productName

    modalShow.contractMessage = true



  }).catch(err => {
    console.log(err)
  })
}







contractPage()
account_name_selector()
type_selector()
invoice_type_selector()
  </script>
  <style lang="less">
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 11%;
}
</style>