<!-- eslint-disable vue/no-v-model-argument -->


<template>

  <a-layout>
    <a-layout-sider class="sider"
                    v-model:collapsed="state.collapsed"
                    :trigger="null"
                    collapsible>
      <img @click="toHome"
           v-if='!state.collapsed'
           style="width: 200px; height: 64px;cursor:pointer;"
           src="@/assets/2018.png"
           alt="Logo" />

      <div v-if='state.collapsed'
           class='logo'>AIOps</div>
      <a-menu id="dddddd"
              v-model:openKeys="openKeys"
              v-model:selectedKeys="selectedKeysSide"
              mode="inline"
              theme="dark"
              :items="items.navigation_sides"
              @click="handleClickSide"
              @openChange="handleOpenChange"></a-menu>

    </a-layout-sider>
    <a-layout>

      <a-layout-header class="layout-header">
        <a-button type="link"
                  class='collapsed-button'
                  @click="toggleCollapsed">
          <MenuUnfoldOutlined v-if="state.collapsed" />
          <MenuFoldOutlined v-else />
        </a-button>
        <a-menu v-model:selectedKeys="selectedKeysHeader"
                theme="dark"
                mode="horizontal"
                :style="{ lineHeight: '64px' }"
                :items="items.navigation_headers"
                @click="handleClickHeader">

        </a-menu>
        <div class="header-right">
          <a-dropdown>
            <div class='message-icon-box'
                 @click='toMessagePage'>
              <a-badge count="5">
                <img class='message-image'
                     src="@/assets/消息提醒.png">
              </a-badge>
              <span class="message-info">消息提醒</span>
            </div>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;"
                     @click="toMessagePage">消息1</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;"
                     @click="toMessagePage">消息1</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;"
                     @click="toMessagePage">消息1</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-dropdown>
            <div style="display:flex;cursor:pointer;align-items:center;">
              <img class='rounded-image'
                   src="https://images.gitee.com/uploads/images/2021/1101/141155_f3dfce1d_7382127.jpeg">
              <span class="user-info">{{ userName }}</span>
            </div>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;"
                     @click="handleLogout">退出系统</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <!-- <span class="user-info"></span> -->
          <!-- <span class="user-info" style='cursor:pointer' @click="handleLogout">{{ "退出" }}</span> -->
        </div>
      </a-layout-header>
      <breadcrumb-component style="margin: 2px;margin-left:20px" />
      <a-layout-content style="margin: 0 16px"
                        class="content">
        <!-- padding: '24px', -->
        <div :style="{ background: '#fff',height:(windowHeight-140.5)+'px' }">
          <router-view></router-view>
        </div>
      </a-layout-content>
      <a-layout-footer class="footer">
        <div class='footer-label'>AIOPS ©2024 Created by Yuhe Information Technology</div>
      </a-layout-footer>
    </a-layout>
  </a-layout>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'

import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';
import { ref, watch, reactive, h } from "vue";
// import router from "@/router";
import serviceAxios from "../utils/serviceAxios";
import interfaces from "@/config";
import { message } from "ant-design-vue";
import { useRouter, useRoute } from 'vue-router';

import { useScreenSize } from '@/utils/useScreenSize';
import useItems from 'ant-design-vue/es/menu/src/hooks/useItems';
import {
  MailOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
const { windowWidth, windowHeight } = useScreenSize();

const state = reactive({
  collapsed: false,
  selectedKeys: ['1'],
  openKeys: ['sub1'],
  preOpenKeys: ['sub1'],
});

let router = useRouter()
const userName = ref("")
let breadcrumb_info = ref("");
const selectedKeysSide = ref();
const selectedKeysHeader = ref()
watch(selectedKeysSide, (selectedKeysSide) => {
  console.log("selectedKeysSide", selectedKeysSide)
  localStorage.setItem("selectedKeysSide", selectedKeysSide)
})
watch(selectedKeysHeader, (selectedKeysHeader) => {
  console.log("selectedKeysHeader", selectedKeysHeader)
  localStorage.setItem("selectedKeysHeader", selectedKeysHeader)
  // localStorage.removeItem("selectedKeysSide")
})
const openKeys = ref([]);
const items = reactive({
  navigation_headers: [],
  navigation_sidesAll: {},
  navigation_sides: []

});

const handleClickSide = (menuInfo) => {
  if (menuInfo.key != undefined)
    console.log('click ', menuInfo);
  // breadcrumb_info.value = menuInfo.label;
  router.push(menuInfo.item.keyPath);
};

let selectSide = ''

//获取导航下的第一个可用路由
const getChildren = (sideNavigation) => {
  console.log("开始检测sideNavigation", sideNavigation)
  if (sideNavigation[0] != undefined) {
    if (sideNavigation[0].children != undefined) {
      openKeys.value = [sideNavigation[0].key]
      getChildren(sideNavigation[0].children)
    }
    else {
      console.log("keyPath", sideNavigation[0].keyPath)
      selectedKeysSide.value = [sideNavigation[0].key]
      selectSide = sideNavigation[0].keyPath
    }
  }
  else {
    selectSide = undefined
  }
}

//头部菜单点击事件
const handleClickHeader = (key) => {
  console.log("key", key)
  //key.item.originItemValue.title //头部菜单标题
  let menuKey = key.key
  if (menuKey == undefined) {
    menuKey = key
  }
  items.navigation_sides = items.navigation_sidesAll[menuKey]
  console.log('items.navigation_sides', items.navigation_sides)

  if (items.navigation_sides == undefined) {
    router.push('/login')
  }
  else {
    items.navigation_sides.forEach(
      item => {
        item['icon'] = h(AppstoreOutlined)
      }
    )
    getChildren(items.navigation_sides)
  }


  if (selectSide != '' && selectSide != undefined) {
    router.push(selectSide)
  } else {
    try {
      if (key.item.title == '首页') {
        router.push('/homeData')
      }
      else if (key.item.title == '信发系统') {
        pointShow()
      }
      else {
        router.push('/result')

      }
    }
    catch {

    }

  }
  console.log("selectSide", selectSide)

};
const handleLogout = () => {

  // localStorage.removeItem("token");
  // localStorage.removeItem("name");
  serviceAxios({
    url: interfaces.logout,
    method: 'get'
  }).then(
    result => {
      console.log(result.data)
      let role = localStorage.getItem("role")
      localStorage.clear()
      console.log("角色", role)

      if (role != "外勤人员") {
        router.push("/login")
      }
      else {
        router.push("/loginTalent")
      }
    }
  ).catch()

};

let rules = {}
const clearMenu = (data) => {
  let childrenData = []
  if (data != undefined) {

    data.forEach((item) => {
      let scopData = {
        id: String(item.id),
        key: item.id,
        is_bottom: item.is_bottom,
        title: item.title,
        label: item.label,
        keyPath: item.key
      }
      if (item.children.length != 0) {
        scopData.children = clearMenu(item.children)
      }
      childrenData.push(scopData)


      if (item.is_bottom == 1 && item.rules != undefined) {
        rules[item.key] = []
        item.rules.forEach((rule) => {
          rules[item.key].push(rule.rule_path)
        })
      }
    }
    )
    return childrenData

  }
  else {
    return []
  }
}

//查询用户权限菜单
const queryUserMenu = () => {
  serviceAxios({
    url: interfaces.systemMe,
    method: "get",
    // data:''
  })
    .then((result) => {

      let headersData = [];
      let sidesdate = {};
      result.data.rules.forEach((item) => {
        // console.log(item)
        let scopData = {
          id: String(item.id),
          key: item.id,
          is_bottom: item.is_bottom,
          title: item.title,
          label: item.label,
          keyPath: item.key,
        }
        headersData.push(scopData)
        sidesdate[String(item.id)] = clearMenu(item.children)

      })

      localStorage.setItem("rules", JSON.stringify(rules))
      localStorage.setItem("id", result.data.id)
      localStorage.setItem("role", result.data.role.name)
      localStorage.setItem("headers", JSON.stringify(headersData))
      localStorage.setItem("sides", JSON.stringify(sidesdate))
      localStorage.setItem("userName", result.data.username)
      userName.value = result.data.username




      //初始化菜单
      items.navigation_headers = headersData
      items.navigation_sidesAll = sidesdate

      // 默认选择的菜单
      selectedKeysHeader.value = [headersData[0]["id"]]
      handleClickHeader(items.navigation_headers[0])



    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err)
    });
};


//检查权限
const checkPermissios = () => {
  if (localStorage.getItem("id") == null) {
    queryUserMenu()
  }
  else {
    items.navigation_headers = JSON.parse(localStorage.getItem("headers"))
    items.navigation_sidesAll = JSON.parse(localStorage.getItem("sides"))
    selectedKeysHeader.value = [Number(localStorage.getItem("selectedKeysHeader"))] == null ? [] : [Number(localStorage.getItem("selectedKeysHeader"))] //缓存头部
    let headerMeny = selectedKeysHeader.value == [] ? items.navigation_headers[0] : selectedKeysHeader.value[0]
    // console.log("headerMeny", headerMeny)
    let headerkey = localStorage.getItem('selectedKeysHeader')
    items.navigation_sides = items.navigation_sidesAll[headerkey]
    try {
      items.navigation_sides.forEach(
        item => {
          item['icon'] = h(AppstoreOutlined)
        }
      )
    }
    catch {
      error => {
        console.log(error)
      }
    }

    // handleClickHeader(selectedKeysHeader.value == [] ? items.navigation_headers[0] : selectedKeysHeader.value[0])
    selectedKeysSide.value = [Number(localStorage.getItem("selectedKeysSide"))] == null ? [] : [Number(localStorage.getItem("selectedKeysSide"))] //缓存侧边栏
    openKeys.value = localStorage.getItem("openKeys") == null ? [] : JSON.parse(localStorage.getItem("openKeys")) //缓存展开菜单
    userName.value = localStorage.getItem("userName")

  }
}
checkPermissios();

watch(openKeys, (val) => {
  localStorage.setItem("openKeys", JSON.stringify(val))
  console.log("openKeys", val);
});
const toHome = () => {
  router.push("/homeData");
}

const handleOpenChange = (keys) => {
  console.log("openkeys", keys)
  if (keys.length > 1) {
    openKeys.value = [keys[keys.length - 1]]; // 仅保留最后一个打开的key
  } else {
    openKeys.value = keys; // 否则直接更新 openKeys
  }
}


const toggleCollapsed = () => {
  state.collapsed = !state.collapsed;
  state.openKeys = state.collapsed ? [] : state.preOpenKeys;
};


const pointShow = () => {
  serviceAxios({
    url: '/v1/info_dis/point_show/get_token',
    method: 'post'
  }).then(result => {
    if (result.data.message = 'success') {
      let pointShowUrl = result.data.data
      window.open(pointShowUrl)

    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
  })
}

const toMessagePage = () => {
  router.push("/test");
}
</script>
<style lang="less" scope>
.layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #10092c !important;
}
.sider {
  background-color: #10092c !important;
}

.menu-item-right-align {
}
.footer {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center;
  height: 20px;
  .footer-label {
    margin-top: -9px;
  }
}
.ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  color: white;
  background-color: #10092c !important;
}

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* 使得用户名和退出按钮位于最右边 */
}

.user-info {
  margin: 5px; /* 根据需要调整用户名和退出按钮之间的间距 */
  font-size: 17px;
  color: rgb(134, 134, 136);
}

.ant-menu-inline {
  text-align: left;
  padding-left: 24px; /* 根据需要调整 */
}
.ant-menu-inline > .ant-menu-item {
  text-align: left;
  padding-left: 24px; /* 根据需要调整 */
}

.rounded-image {
  width: 30px; /* 您指定的宽度 */
  height: 30px; /* 您指定的高度 */
  border-radius: 50%; /* 将边角弧度设为50%以形成圆形 */
  object-fit: cover; /* 保证图片填充圆形同时不失比例 */
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.07); /* 您指定的阴影效果 */
}

.message-icon-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .message-image {
    width: 30px;
    height: 30px;
  }
  .message-info {
    margin: 5px;
    font-size: 16px;
    color: rgb(134, 134, 136);
  }
}

.collapsed-button {
  // background-color: #10092c;
  margin: 0px 10px 0px -50px;
  height: 64px;
  color: white;
}
.collapsed-button:hover {
  color: white;
}
.logo {
  width: 80px;
  color: white;
  height: 64px;
  cursor: pointer;
  font-size: 23px;
  text-align: center;
  padding-top: 15px;

  font-family: "Microsoft YaHei";
  background: -webkit-linear-gradient(left, #0f0, #00f) 0 0 no-repeat; /*设置线性渐变*/
  -webkit-background-size: 80px; /*设置背景大小*/
  -webkit-background-clip: text; /*背景被裁剪到文字*/
  -webkit-text-fill-color: rgba(255, 255, 255, 0.3); /*设置文字的填充颜色*/
  -webkit-animation: shine 0.5s infinite; /*设置动画*/
}
@-webkit-keyframes shine {
  /*创建动画*/
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

//调整head行属性
.ant-table-thead > tr > th {
  padding: 5px !important;
}
.ant-table-tbody > tr > td {
  padding: 5px !important;
}
.ant-table-title {
  padding: 2px !important;
}
// .ant-form-item {
//   margin-bottom: 0px;
// }
</style>