<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.examineWorkOrder"
           title="审核工单"
           ok-text="确认"
           cancel-text="取消"
           @ok="examineHandleOk">

    <table class='commonTable'>

      <tr>
        <td class='column'>
          审核状态<span style="color: red;margin-left: 0px;">*</span>
        </td>
        <td colspan="3">

          <a-radio-group v-model:value="examineWorkOrderRef.status">
            <a-radio value="true">通过</a-radio>
            <a-radio value="false">拒绝</a-radio>
          </a-radio-group>

        </td>
      </tr>
      <tr>
        <td class='column'>
          审核备注
        </td>
        <td colspan="3"> <a-input v-model:value="examineWorkOrderRef.desc"
                   :placeholder="`请输入审核备注`"></a-input></td>

      </tr>
      <tr>
        <!-- <td class='column'>
          完成时间
        </td>
        <td colspan="3">
          <a-date-picker v-model:value="examineWorkOrderRef.complete_time"
                         valueFormat="YYYY-MM-DD HH:mm:ss"
                         :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
        </td> -->
      </tr>
      <tr v-if='examineWorkOrderRef.status=="false"'>
        <td class='column'>
          驳回原因
        </td>
        <td colspan="3"> <a-input v-model:value="examineWorkOrderRef.reject_reason"
                   :placeholder="`请输入驳回原因`"></a-input></td>

      </tr>

      <tr>
        <td class='column'>
          工单号
        </td>
        <td>{{baseData.work_sn}}</td>

        <td class='column'>
          工单类型
        </td>
        <td>{{baseData.type}}</td>

      </tr>

      <tr>
        <td class='column'>
          品牌
        </td>
        <td>{{baseData.customer_name}}</td>
        <td class='column'>
          门店名称
        </td>
        <td>{{baseData.contract_user_name}}</td>

      </tr>

      <tr>
        <td class='column'>
          地区
        </td>
        <td>{{baseData.region}}</td>
        <td class='column'>
          门店地址
        </td>
        <td>{{baseData.complete_address}}</td>

      </tr>

      <tr>
        <td class='column'>
          联系人
        </td>
        <td>{{baseData.contract_user_contact_name}}</td>

        <td class='column'>
          联系电话
        </td>
        <td>{{baseData.contract_user_phone_number}}</td>

      </tr>

      <tr>
        <td class='column'>
          状态
        </td>
        <td>{{baseData.status}}</td>
        <td class='column'>
          备注
        </td>
        <td>{{baseData.desc}}</td>
      </tr>

    </table>
    <a-tabs v-model:activeKey="activeKeyRef">
      <a-tab-pane v-for='item in activeKeyList'
                  :key='item'
                  :tab="item"></a-tab-pane>

    </a-tabs>

    <!-- 资源勘查 -->
    <div v-if='activeKeyRef=="资源勘查"'>
      <div class='order-box'
           v-for="(item1, index) in workTableData.sourceExplorate"
           :key="item1.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">

          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item1.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('sourceExplorate',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item1.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item1.id,'sourceExplorate',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px">资源勘查
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item1.work_content_sn}}</td>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item1.status}}
            </td>
          </tr>

          <tr>
            <td class="column">资源信息</td>
            <td colspan="5">
              <div style="display: flex;">
                <a-checkbox v-model:checked="item1.power">是否有电源</a-checkbox>
                <a-checkbox v-model:checked="item1.space">是否有空间</a-checkbox>
                <a-checkbox v-model:checked="item1.need_line">是否需要布线</a-checkbox>
                <a-checkbox v-model:checked="item1.weak_current_well">弱电井是否可用</a-checkbox>
                <a-checkbox v-model:checked="item1.is_monopolized">是否代理管控</a-checkbox>
              </div>
            </td>
          </tr>
          <tr v-if="item1.is_monopolized">

            <td class="column">代理信息</td>
            <td colspan="5">
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px; display: inline-block; text-align: right;">物业联系人</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.pmc_contact_name"
                           :placeholder="`请输入物业联系人`"></a-input>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px;">物业联系人电话</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.pmc_contact_phone"
                           :placeholder="`请输入物业联系人电话`"></a-input>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px; display: inline-block; text-align: right;">代理管控价格</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.monopolized_prices"
                           :placeholder="`请输入代理管控价格`"></a-input>
                </div>

              </div>
            </td>

          </tr>
          <tr>
            <td class="column">信号强度</td>
            <td colspan="5">
              <div style="display: flex;">
                <div style="display: flex; align-items: center;margin:4px">

                  <a-checkbox v-model:checked="item1.provider_resources_available_telegraphy">电信</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_telegraphy"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <a-checkbox v-model:checked="item1.provider_resources_available_link">联通</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_link"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <a-checkbox v-model:checked="item1.provider_resources_available_move">移动</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_move"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="column">附件</td>
            <td colspan="5"
                style="dispaly:flex;align-items: center;">

              <uploadComponent :fileIds='item1.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"sourceExplorate",index)'>

              </uploadComponent>
            </td>
          </tr>
          <tr>
            <td class="column">备注</td>
            <td colspan="5">
              <a-textarea :bordered="false"
                          v-model:value="item1.desc"
                          placeholder="请输入备注"
                          :rows="2" />
            </td>
          </tr>

        </table>

      </div>
    </div>
    <!-- 宽带接入 -->
    <div v-if='activeKeyRef=="宽带接入"'>
      <div class='order-box'
           v-for="(item, index) in workTableData.internet"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('internet',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'internet',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px"
                colspan="3">宽带接入</td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
            <td class="column"><span style="color: red;margin-left: 0px;">*</span>工单状态</td>
            <td>{{item.status}}</td>
          </tr>

          <template v-for="item1 in groupedItems(modalInterMust)"
                    :key="item1.key">
            <tr>
              <template v-for="(item2) in item1"
                        :key="item2.key">

                <td v-if="['begin_date','end_date'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}
                </td>
                <td v-if="['begin_date','end_date'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('internet',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)">
                  <a-select v-model:value="item[item2.dataIndex]"
                            show-search
                            :placeholder="`请选择1${item2.title}`"
                            style="width: 200px"
                            :options="options.service_provider"
                            :filter-option="filterOption"
                            @change="handleChangeSelect(item.dataIndex,$event)">
                  </a-select>
                </td>

                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}</td>
                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input-number :bordered="false"
                                  style="width:200px"
                                  v-model:value="item[item2.dataIndex]"
                                  :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['signing_company','service_product'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['signing_company','service_product'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}</td>
                <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td class='column'>附件</td>
            <td colspan="5">
              <div style="dispaly:flex;align-items: center;">

                <uploadComponent :fileIds='item.fileIds'
                                 @update:fileIds='(event)=>fileChange(event,"internet",index)'>

                </uploadComponent>
              </div>
            </td>
          </tr>

        </table>
      </div>
    </div>
    <!-- 网关设备安装 -->
    <div v-if='activeKeyRef=="网关设备安装"'>
      <div class='order-box'
           v-for="(item, index) in workTableData.installWork"
           :key="item.id">
        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('installWork',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'installWork',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px">网关设备安装
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>{{item.status}}

            </td>
          </tr>

          <template v-for="item1 in groupedItems(installWork)"
                    :key="item1.key">
            <tr>
              <template v-for="(item2) in item1"
                        :key="item2.key">

                <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}<span style="color: red;margin-left: 0px;">*</span>
                </td>
                <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['express_received_time'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}
                </td>
                <td v-if="['express_received_time'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['payment_type'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['payment_type'].includes(item2.dataIndex)">
                  <a-select v-model:value="item[item2.dataIndex]"
                            show-search
                            :placeholder="`请选择${item2.title}`"
                            style="width: 200px"
                            :options="options[item2.dataIndex]"
                            :filter-option="filterOption"
                            @change="handleChangeSelect(item.dataIndex,$event)">
                  </a-select>
                </td>

                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}</td>
                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input-number :bordered="false"
                                  style="width:200px"
                                  v-model:value="item[item2.dataIndex]"
                                  :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}</td>
                <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
              </template>
            </tr>
          </template>

          <tr>
            <td class='column'>设备</td>
            <td colspan="5">
              <thead>
                <tr>
                  <td v-if="item.devices.length != 0"
                      scope="col">设备名称<span style="color: red;margin-left: 0px;">*</span>
                  </td>
                  <td v-if="item.devices.length != 0"
                      scope="col">数量<span style="color: red;margin-left: 0px;">*</span>
                  </td>
                  <td scope="col"><a style="margin:2px;color: #1884ea;"
                       @click="addDevice(index)">添加设备</a></td>
                </tr>
              </thead>

              <tbody v-for="(device,index1) in item.devices"
                     :key="device.id">
                <tr>
                  <td scope="row">

                    <a-select style='margin-left:-10px;width:100%'
                              v-model:value="device.device_id"
                              show-search
                              placeholder="选择产品"
                              :options="options['device_selector']"
                              :filter-option="filterOption"></a-select>
                  </td>
                  <td>
                    <a-input-number style="width:100%;margin-left:-10px;"
                                    :bordered="false"
                                    v-model:value="device.quantity"
                                    :placeholder="`请输入数量`" />
                  </td>
                  <td style="margin:2px;color: #1884ea;"
                      @click="removeUser(index1,device)">删除设备</td>
                </tr>

              </tbody>
            </td>
          </tr>
          <tr>
            <td class='column'>附件</td>
            <td colspan="5">
              <div style="dispaly:flex;align-items: center;">

                <uploadComponent :fileIds='item.fileIds'
                                 @update:fileIds='(event)=>fileChange(event,"installWork",index)'>

                </uploadComponent>
              </div>
            </td>
          </tr>
          <tr>
            <td class='column'>备注</td>
            <td colspan="5">
              <a-input :bordered="false"
                       v-model:value="item['desc']"
                       :placeholder="`请输入备注`" />

            </td>
          </tr>

        </table>

      </div>
    </div>
    <!-- 故障维修 -->
    <div v-if='activeKeyRef=="故障维修"'>
      <div v-for="(item,index) in workTableData.faultOrder"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('faultOrder',index)">保存</a-button>
              <a-button v-if='["已到店"].includes(baseData.status) && ["工作中"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitfaultOrder(workContentID, 'faultOrder', index,baseData.id)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工作类型</td>
            <td style="font-size:16px">故障维修
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
          </tr>
          <tr>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item.status}}
            </td>

          </tr>
          <tr>
            <td class='column'> 现场结果<span style="color: red;margin-left: 0px;">*</span></td>
            <td>

              <a-input v-model:value="item.on_site_result"
                       :placeholder="`请输入现场结果`" />
            </td>
          </tr>

          <tr>
            <td class="column">完成时间</td>
            <td>
              <a-date-picker v-model:value="item.complete_time"
                             valueFormat="YYYY-MM-DD HH:mm:ss"
                             :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
            </td>
          </tr>

          <tr>
            <td class="column">附件</td>
            <td style="dispaly:flex;align-items: center;">
              <uploadComponent :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"faultOrder",index)'>

              </uploadComponent>
            </td>
          </tr>

        </table>

        <tr>
          <td colspan="5"
              style="height: 5px;"></td>
        </tr>
      </div>
    </div>
    <!-- 测试报告 -->
    <div v-if='activeKeyRef=="测试报告"'>
      <div v-for="(item,index) in workTableData.testReport"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('testReport',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'testReport',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工作类型</td>
            <td style="font-size:16px">测试报告
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
          </tr>
          <tr>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item.status}}
            </td>
            <td v-if='item.status == "待补充"'
                class='column'> 拒绝原因</td>
            <td v-if='item.status == "待补充"'>{{item.reject_reason}}</td>
          </tr>

          <tr>
            <td class="column">线路策略</td>
            <td>
              <a-input :bordered="false"
                       v-model:value="item.line_policy"
                       :placeholder="`请输入线路策略`" />
            </td>
          </tr>
          <tr>

            <td class="column">线路接入方式</td>
            <td>
              <a-input :bordered="false"
                       v-model:value="item.line_access_mode"
                       :placeholder="`请输入线路接入方式`" />
            </td>

          </tr>

          <tr>
            <td class="column">附件</td>
            <td style="dispaly:flex;align-items: center;">
              <uploadComponent :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"testReport",index)'>

              </uploadComponent>
            </td>
          </tr>
          <tr>
            <td class="column">工作内容描述</td>
            <td>
              <a-textarea :bordered="false"
                          v-model:value="item.desc"
                          placeholder="请输入工作内容描述"
                          :rows="2" />
            </td>
          </tr>
        </table>

      </div>
    </div>
  </a-modal>
  <!-- 处理工单 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.handleWorkOrder"
           title="工单"
           ok-text="确认"
           cancel-text="取消"
           :ok-button-props="{ style: { display: modalShow.buttonShow } }"
           :cancel-button-props="{ style: { display: modalShow.buttonShow } }"
           :afterClose='queryPage'
           @ok="handleOk">
    <h4>基础信息</h4>

    <table class='commonTable'>

      <tr>
        <td class='column'>
          工单号
        </td>
        <td>{{baseData.work_sn}}</td>

        <td class='column'>
          工单类型
        </td>
        <td>{{baseData.type}}</td>

      </tr>

      <tr>
        <td class='column'>
          品牌
        </td>
        <td>{{baseData.customer_name}}</td>
        <td class='column'>
          门店名称
        </td>
        <td>{{baseData.contract_user_name}}</td>

      </tr>

      <tr>
        <td class='column'>
          地区
        </td>
        <td>{{baseData.region}}</td>
        <td class='column'>
          门店地址
        </td>
        <td>{{baseData.complete_address}}</td>

      </tr>

      <tr>
        <td class='column'>
          联系人
        </td>
        <td>{{baseData.contract_user_contact_name}}</td>

        <td class='column'>
          联系电话
        </td>
        <td>{{baseData.contract_user_phone_number}}</td>

      </tr>

      <tr>
        <td class='column'>
          状态
        </td>
        <td>{{baseData.status}}</td>
        <td class='column'>
          备注
        </td>
        <td>{{baseData.desc}}</td>
      </tr>

    </table>
    <div v-if='!["已到店"].includes(baseData.status)'>
      <h4>操作</h4>
      <table class='commonTable'
             v-if='handleWorkOrderRef.show'>
        <tr>
          <td class='column'
              style='color:red'>是否成功<span style="color: red;margin-left: 0px;">*</span></td>
          <td colspan="3">
            <a-radio-group v-model:value="handleWorkOrderRef.status">
              <a-radio value="true">是</a-radio>
              <a-radio value="false">否</a-radio>
            </a-radio-group>
          </td>
        </tr>

        <tr>
          <td class='column'
              style='color:red'>处理结果<span style="color: red;margin-left: 0px;">*</span></td>
          <td colspan="3">
            <a-input style="width:500px;"
                     v-model:value="handleWorkOrderRef.remote_result"
                     :placeholder="`请输入处理结果`">
            </a-input>
          </td>
        </tr>
      </table>
    </div>
    <table class='commonTable'>
      <tr v-if='baseData.status =="已调度"'>
        <td class='column'
            style='color:red'>预约上门时间</td>
        <td colspan="3">
          <a-config-provider :locale="locale">
            <a-date-picker v-model:value="appointmentTimeRef.schedule_time"
                           format="YYYY-MM-DD HH:mm:ss"
                           :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
          </a-config-provider>
        </td>
      </tr>

      <tr v-if='baseData.status =="已预约"'>
        <!-- <td class='column'
            style='color:red'>到达现场时间</td>
        <td colspan="3">
          <a-config-provider :locale="locale">
            <a-date-picker v-model:value="arriveTimeRef.arrival_time"
                           format="YYYY-MM-DD HH:mm:ss"
                           :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
          </a-config-provider>
        </td> -->
      </tr>
      <tr v-if='lookOrderRef.show && !["已到店"].includes(baseData.status)'>

        <td class='column'
            style='color:red'>操作</td>
        <td colspan="3">
          <a-button v-if='baseData.status =="已调度"'
                    @click='appointmentTime(baseData.id)'
                    style='margin:5px 0px 0px 0px'>预约</a-button>

          <a-button v-if='baseData.status =="已预约"'
                    @click='arriveTime(baseData.id)'
                    style='margin:5px 0px 0px 0px'>到达现场</a-button>
        </td>

      </tr>
    </table>
    <a-tabs v-model:activeKey="activeKeyRef"
            v-if='!handleWorkOrderRef.show && baseData.status =="已到店"'>
      <a-tab-pane v-for='item in activeKeyList'
                  :key='item'
                  :tab="item"></a-tab-pane>

    </a-tabs>

    <!-- 资源勘查 -->
    <div v-if='activeKeyRef=="资源勘查"'>
      <div class='order-box'
           v-for="(item1, index) in workTableData.sourceExplorate"
           :key="item1.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">

          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item1.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('sourceExplorate',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item1.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item1.id,'sourceExplorate',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px">资源勘查
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item1.work_content_sn}}</td>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item1.status}}
            </td>
          </tr>

          <tr>
            <td class="column">资源信息</td>
            <td colspan="5">
              <div style="display: flex;">
                <a-checkbox v-model:checked="item1.power">是否有电源</a-checkbox>
                <a-checkbox v-model:checked="item1.space">是否有空间</a-checkbox>
                <a-checkbox v-model:checked="item1.need_line">是否需要布线</a-checkbox>
                <a-checkbox v-model:checked="item1.weak_current_well">弱电井是否可用</a-checkbox>
                <a-checkbox v-model:checked="item1.is_monopolized">是否代理管控</a-checkbox>
              </div>
            </td>
          </tr>
          <tr v-if="item1.is_monopolized">

            <td class="column">代理信息</td>
            <td colspan="5">
              <div style="display: flex; flex-direction: column;">
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px; display: inline-block; text-align: right;">物业联系人</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.pmc_contact_name"
                           :placeholder="`请输入物业联系人`"></a-input>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px;">物业联系人电话</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.pmc_contact_phone"
                           :placeholder="`请输入物业联系人电话`"></a-input>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <label style="width: 100px; display: inline-block; text-align: right;">代理管控价格</label>
                  <a-input style="width:180px;margin:4px"
                           v-model:value="item1.monopolized_prices"
                           :placeholder="`请输入代理管控价格`"></a-input>
                </div>

              </div>
            </td>

          </tr>
          <tr>
            <td class="column">信号强度</td>
            <td colspan="5">
              <div style="display: flex;">
                <div style="display: flex; align-items: center;margin:4px">

                  <a-checkbox v-model:checked="item1.provider_resources_available_telegraphy">电信</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_telegraphy"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <a-checkbox v-model:checked="item1.provider_resources_available_link">联通</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_link"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
                <div style="display: flex; align-items: center;margin:4px">
                  <a-checkbox v-model:checked="item1.provider_resources_available_move">移动</a-checkbox>
                  <a-select v-model:value="item1.provider_resources_rssi_move"
                            style="width: 100px;maigin-left:30px;margin:1px"
                            placeholder="请选择信号强度"
                            :options="options.provider_resources"></a-select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="column">附件</td>
            <td colspan="5"
                style="dispaly:flex;align-items: center;">

              <uploadComponent :fileIds='item1.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"sourceExplorate",index)'>

              </uploadComponent>
            </td>
          </tr>
          <tr>
            <td class="column">备注</td>
            <td colspan="5">
              <a-textarea :bordered="false"
                          v-model:value="item1.desc"
                          placeholder="请输入备注"
                          :rows="2" />
            </td>
          </tr>

        </table>

      </div>
    </div>
    <!-- 宽带接入 -->
    <div v-if='activeKeyRef=="宽带接入"'>
      <div class='order-box'
           v-for="(item, index) in workTableData.internet"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('internet',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'internet',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px"
                colspan="3">宽带接入</td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
            <td class="column"><span style="color: red;margin-left: 0px;">*</span>工单状态</td>
            <td>{{item.status}}</td>
          </tr>

          <template v-for="item1 in groupedItems(modalInterMust)"
                    :key="item1.key">
            <tr>
              <template v-for="(item2) in item1"
                        :key="item2.key">

                <td v-if="['begin_date','end_date'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}
                </td>
                <td v-if="['begin_date','end_date'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('internet',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)">
                  <a-select v-model:value="item[item2.dataIndex]"
                            show-search
                            :placeholder="`请选择1${item2.title}`"
                            style="width: 200px"
                            :options="options.service_provider"
                            :filter-option="filterOption"
                            @change="handleChangeSelect(item.dataIndex,$event)">
                  </a-select>
                </td>

                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}</td>
                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input-number :bordered="false"
                                  style="width:200px"
                                  v-model:value="item[item2.dataIndex]"
                                  :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['signing_company','service_product'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['signing_company','service_product'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}</td>
                <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <td class='column'>附件</td>
            <td colspan="5">
              <div style="dispaly:flex;align-items: center;">

                <uploadComponent :fileIds='item.fileIds'
                                 @update:fileIds='(event)=>fileChange(event,"internet",index)'>

                </uploadComponent>
              </div>
            </td>
          </tr>

        </table>
      </div>
    </div>
    <!-- 网关设备安装 -->
    <div v-if='activeKeyRef=="网关设备安装"'>
      <div class='order-box'
           v-for="(item, index) in workTableData.installWork"
           :key="item.id">
        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('installWork',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'installWork',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工单类型</td>
            <td style="font-size:16px">网关设备安装
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>{{item.status}}

            </td>
          </tr>

          <template v-for="item1 in groupedItems(installWork)"
                    :key="item1.key">
            <tr>
              <template v-for="(item2) in item1"
                        :key="item2.key">

                <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}<span style="color: red;margin-left: 0px;">*</span>
                </td>
                <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['express_received_time'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}
                </td>
                <td v-if="['express_received_time'].includes(item2.dataIndex)">
                  <a-space direction="vertical"
                           :size="12">
                    <a-config-provider :locale="locale">
                      <a-date-picker valueFormat='YYYY-MM-DD'
                                     @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                     v-model:value="item[item2.dataIndex]" />
                    </a-config-provider>
                  </a-space>
                </td>

                <td v-if="['payment_type'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['payment_type'].includes(item2.dataIndex)">
                  <a-select v-model:value="item[item2.dataIndex]"
                            show-search
                            :placeholder="`请选择${item2.title}`"
                            style="width: 200px"
                            :options="options[item2.dataIndex]"
                            :filter-option="filterOption"
                            @change="handleChangeSelect(item.dataIndex,$event)">
                  </a-select>
                </td>

                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                    class="column">
                  {{item2.title}}</td>
                <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                  <a-input-number :bordered="false"
                                  style="width:200px"
                                  v-model:value="item[item2.dataIndex]"
                                  :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
                <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)"
                    class="column">{{item2.title}}</td>
                <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)">
                  <a-input :bordered="false"
                           v-model:value="item[item2.dataIndex]"
                           :placeholder="`请输入${item2.title}`" />
                </td>
              </template>
            </tr>
          </template>

          <tr>
            <td class='column'>设备</td>
            <td colspan="5">
              <thead>
                <tr>
                  <td v-if="item.devices.length != 0"
                      scope="col">设备名称<span style="color: red;margin-left: 0px;">*</span>
                  </td>
                  <td v-if="item.devices.length != 0"
                      scope="col">数量<span style="color: red;margin-left: 0px;">*</span>
                  </td>
                  <td scope="col"><a style="margin:2px;color: #1884ea;"
                       @click="addDevice(index)">添加设备</a></td>
                </tr>
              </thead>

              <tbody v-for="(device,index1) in item.devices"
                     :key="device.id">
                <tr>
                  <td scope="row">

                    <a-select style='margin-left:-10px;width:100%'
                              v-model:value="device.device_id"
                              show-search
                              placeholder="选择产品"
                              :options="options['device_selector']"
                              :filter-option="filterOption"></a-select>
                  </td>
                  <td>
                    <a-input-number style="width:100%;margin-left:-10px;"
                                    :bordered="false"
                                    v-model:value="device.quantity"
                                    :placeholder="`请输入数量`" />
                  </td>
                  <td style="margin:2px;color: #1884ea;"
                      @click="removeUser(index1,device)">删除设备</td>
                </tr>

              </tbody>
            </td>
          </tr>
          <tr>
            <td class='column'>附件</td>
            <td colspan="5">
              <div style="dispaly:flex;align-items: center;">
                <!-- <a-select v-model:value="item.attachment"
                        show-search
                        placeholder="请选择附件类型"
                        style="width: 180px"
                        mode=""
                        :options="options['attachment']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('attachment',$event)">
              </a-select> -->
                <!-- <a-upload v-model:file-list="item.fileList"
                        :data="{file_type: item.attachment}"
                        :action="uploadUrl"
                        list-type="picture"
                        :headers="headers"
                        @change="fileHandleChange">
                <a-button :disabled="item.attachment == null ?true:false">
                  <upload-outlined></upload-outlined>
                  上传
                </a-button>
              </a-upload> -->
                <uploadComponent :fileIds='item.fileIds'
                                 @update:fileIds='(event)=>fileChange(event,"installWork",index)'>

                </uploadComponent>
              </div>
            </td>
          </tr>
          <tr>
            <td class='column'>备注</td>
            <td colspan="5">
              <a-input :bordered="false"
                       v-model:value="item['desc']"
                       :placeholder="`请输入备注`" />

            </td>
          </tr>

        </table>

      </div>
    </div>
    <!-- 故障维修 -->
    <div v-if='activeKeyRef=="故障维修" &&  !handleWorkOrderRef.show &&  baseData.status =="已到店"'>
      <div v-for="(item,index) in workTableData.faultOrder"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='
         lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('faultOrder',index)">保存</a-button>
              <a-button v-if='["已到店"].includes(baseData.status) && ["工作中"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitfaultOrder(workContentID, 'faultOrder', index,baseData.id)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工作类型</td>
            <td>故障维修
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
          </tr>
          <tr>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item.status}}
            </td>

          </tr>
          <tr>
            <td class='column'> 现场结果<span style="color: red;margin-left: 0px;">*</span></td>
            <td>

              <a-input v-model:value="item.on_site_result"
                       :placeholder="`请输入现场结果`" />
            </td>
          </tr>

          <!-- <tr>
            <td class="column">完成时间</td>
            <td>
              <a-date-picker v-model:value="item.complete_time"
                             valueFormat="YYYY-MM-DD HH:mm:ss"
                             :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
            </td>
          </tr> -->

          <tr>
            <td class="column">附件</td>
            <td style="dispaly:flex;align-items: center; ">
              <uploadComponent style='margin-left:-100px'
                               :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"faultOrder",index)'>

              </uploadComponent>
            </td>
          </tr>

        </table>

        <tr>
          <td colspan="5"
              style="height: 5px;"></td>
        </tr>
      </div>
    </div>
    <!-- 测试报告 -->
    <div v-if='activeKeyRef=="测试报告"'>
      <div v-for="(item,index) in workTableData.testReport"
           :key="item.id">

        <table class="commonTable"
               cellspacing="0"
               cellpadding="0">
          <tr v-if='lookOrderRef.show'>
            <td class='column'
                style='color:red'>操作</td>
            <td colspan="5">
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="saveOrder('testReport',index)">保存</a-button>
              <a-button v-if='["已分配","处理中"].includes(baseData.status) && !["审核中","已完成"].includes(item.status) '
                        size="small"
                        style="margin:5px"
                        @click="submitOrder(item.id,'testReport',index)">提交</a-button>
            </td>
          </tr>
          <tr>
            <td class="column">工作类型</td>
            <td>测试报告
            </td>

          </tr>
          <tr>
            <td class="column">工作号</td>
            <td>{{item.work_content_sn}}</td>
          </tr>
          <tr>
            <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
            <td>
              {{item.status}}
            </td>
            <td v-if='item.status == "待补充"'
                class='column'> 拒绝原因</td>
            <td v-if='item.status == "待补充"'>{{item.reject_reason}}</td>
          </tr>

          <tr>
            <td class="column">线路策略</td>
            <td>
              <a-input :bordered="false"
                       v-model:value="item.line_policy"
                       :placeholder="`请输入线路策略`" />
            </td>
          </tr>
          <tr>

            <td class="column">线路接入方式</td>
            <td>
              <a-input :bordered="false"
                       v-model:value="item.line_access_mode"
                       :placeholder="`请输入线路接入方式`" />
            </td>

          </tr>

          <tr>
            <td class="column">附件</td>
            <td style="dispaly:flex;align-items: center;">
              <uploadComponent :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"testReport",index)'>

              </uploadComponent>
            </td>
          </tr>
          <tr>
            <td class="column">工作内容描述</td>
            <td>
              <a-textarea :bordered="false"
                          v-model:value="item.desc"
                          placeholder="请输入工作内容描述"
                          :rows="2" />
            </td>
          </tr>
        </table>

      </div>
    </div>
  </a-modal>

  <!-- 查看工单 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.workOrderView"
           title="工单详情"
           ok-text="确认"
           cancel-text="取消"
           :ok-button-props="{ style: { display: 'none'} }"
           :cancel-button-props="{ style: { display: 'none'} }"
           :afterClose='queryPage'>
    <a-tabs v-model:activeKey="baseOrFlowMessageActiveKey"
            style='width:100%'>
      <a-tab-pane key="1">
        <template #tab>
          <span>
            <apple-outlined />
            工单信息
          </span>
        </template>
        <div>
          <h4>基础信息</h4>

          <table class='commonTable'>
            <tr>
              <td class='column'>
                工单号
              </td>
              <td>{{baseData.work_sn}}</td>

              <td class='column'>
                工单类型
              </td>
              <td>{{baseData.type}}</td>

            </tr>

            <tr>
              <td class='column'>
                品牌
              </td>
              <td>{{baseData.customer_name}}</td>
              <td class='column'>
                门店名称
              </td>
              <td>{{baseData.contract_user_name}}</td>

            </tr>

            <tr>
              <td class='column'>
                地区
              </td>
              <td>{{baseData.region}}</td>
              <td class='column'>
                门店地址
              </td>
              <td>{{baseData.complete_address}}</td>

            </tr>

            <tr>
              <td class='column'>
                联系人
              </td>
              <td>{{baseData.contract_user_contact_name}}</td>

              <td class='column'>
                联系电话
              </td>
              <td>{{baseData.contract_user_phone_number}}</td>

            </tr>

            <tr>
              <td class='column'>
                状态
              </td>
              <td>{{baseData.status}}</td>
              <td class='column'>
                备注
              </td>
              <td>{{baseData.desc}}</td>
            </tr>

          </table>
          <h4>工作内容信息</h4>
          <a-tabs v-model:activeKey="activeKeyRef">
            <a-tab-pane v-for='item in activeKeyList'
                        :key='item'
                        :tab="item"></a-tab-pane>

          </a-tabs>

          <!-- 资源勘查 -->
          <div v-if='activeKeyRef=="资源勘查"'>
            <div class='order-box'
                 v-for="(item1, index) in workTableData.sourceExplorate"
                 :key="item1.id">

              <table class="commonTable"
                     cellspacing="0"
                     cellpadding="0">

                <tr>
                  <td class="column">工单类型</td>
                  <td style="font-size:16px">资源勘查
                  </td>

                </tr>
                <tr>
                  <td class="column">工作号</td>
                  <td>{{item1.work_content_sn}}</td>
                  <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
                  <td>
                    {{item1.status}}
                  </td>
                </tr>

                <tr>
                  <td class="column">资源信息</td>
                  <td colspan="5">
                    <div style="display: flex;">
                      <a-checkbox v-model:checked="item1.power">是否有电源</a-checkbox>
                      <a-checkbox v-model:checked="item1.space">是否有空间</a-checkbox>
                      <a-checkbox v-model:checked="item1.need_line">是否需要布线</a-checkbox>
                      <a-checkbox v-model:checked="item1.weak_current_well">弱电井是否可用</a-checkbox>
                      <a-checkbox v-model:checked="item1.is_monopolized">是否代理管控</a-checkbox>
                    </div>
                  </td>
                </tr>
                <tr v-if="item1.is_monopolized">

                  <td class="column">代理信息</td>
                  <td colspan="5">
                    <div style="display: flex; flex-direction: column;">
                      <div style="display: flex; align-items: center;margin:4px">
                        <label style="width: 100px; display: inline-block; text-align: right;">物业联系人</label>
                        <a-input style="width:180px;margin:4px"
                                 v-model:value="item1.pmc_contact_name"
                                 :placeholder="`请输入物业联系人`"></a-input>
                      </div>
                      <div style="display: flex; align-items: center;margin:4px">
                        <label style="width: 100px;">物业联系人电话</label>
                        <a-input style="width:180px;margin:4px"
                                 v-model:value="item1.pmc_contact_phone"
                                 :placeholder="`请输入物业联系人电话`"></a-input>
                      </div>
                      <div style="display: flex; align-items: center;margin:4px">
                        <label style="width: 100px; display: inline-block; text-align: right;">代理管控价格</label>
                        <a-input style="width:180px;margin:4px"
                                 v-model:value="item1.monopolized_prices"
                                 :placeholder="`请输入代理管控价格`"></a-input>
                      </div>

                    </div>
                  </td>

                </tr>
                <tr>
                  <td class="column">信号强度</td>
                  <td colspan="5">
                    <div style="display: flex;">
                      <div style="display: flex; align-items: center;margin:4px">

                        <a-checkbox v-model:checked="item1.provider_resources_available_telegraphy">电信</a-checkbox>
                        <a-select v-model:value="item1.provider_resources_rssi_telegraphy"
                                  style="width: 100px;maigin-left:30px;margin:1px"
                                  placeholder="请选择信号强度"
                                  :options="options.provider_resources"></a-select>
                      </div>
                      <div style="display: flex; align-items: center;margin:4px">
                        <a-checkbox v-model:checked="item1.provider_resources_available_link">联通</a-checkbox>
                        <a-select v-model:value="item1.provider_resources_rssi_link"
                                  style="width: 100px;maigin-left:30px;margin:1px"
                                  placeholder="请选择信号强度"
                                  :options="options.provider_resources"></a-select>
                      </div>
                      <div style="display: flex; align-items: center;margin:4px">
                        <a-checkbox v-model:checked="item1.provider_resources_available_move">移动</a-checkbox>
                        <a-select v-model:value="item1.provider_resources_rssi_move"
                                  style="width: 100px;maigin-left:30px;margin:1px"
                                  placeholder="请选择信号强度"
                                  :options="options.provider_resources"></a-select>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="column">附件</td>
                  <td colspan="5"
                      style="dispaly:flex;align-items: center;">

                    <uploadComponent :fileIds='item1.fileIds'
                                     @update:fileIds='(event)=>fileChange(event,"sourceExplorate",index)'>

                    </uploadComponent>
                  </td>
                </tr>
                <tr>
                  <td class="column">备注</td>
                  <td colspan="5">
                    <a-textarea :bordered="false"
                                v-model:value="item1.desc"
                                placeholder="请输入备注"
                                :rows="2" />
                  </td>
                </tr>

              </table>

            </div>
          </div>
          <!-- 宽带接入 -->
          <div v-if='activeKeyRef=="宽带接入"'>
            <div class='order-box'
                 v-for="(item, index) in workTableData.internet"
                 :key="item.id">

              <table class="commonTable"
                     cellspacing="0"
                     cellpadding="0">

                <tr>
                  <td class="column">工单类型</td>
                  <td style="font-size:16px"
                      colspan="3">宽带接入</td>

                </tr>
                <tr>
                  <td class="column">工作号</td>
                  <td>{{item.work_content_sn}}</td>
                  <td class="column"><span style="color: red;margin-left: 0px;">*</span>工单状态</td>
                  <td>{{item.status}}</td>
                </tr>

                <template v-for="item1 in groupedItems(modalInterMust)"
                          :key="item1.key">
                  <tr>
                    <template v-for="(item2) in item1"
                              :key="item2.key">

                      <td v-if="['begin_date','end_date'].includes(item2.dataIndex)"
                          class="column">
                        {{item2.title}}
                      </td>
                      <td v-if="['begin_date','end_date'].includes(item2.dataIndex)">
                        <a-space direction="vertical"
                                 :size="12">
                          <a-config-provider :locale="locale">
                            <a-date-picker valueFormat='YYYY-MM-DD'
                                           @change="time=>onOpenChange('internet',time,item2.dataIndex,index)"
                                           v-model:value="item[item2.dataIndex]" />
                          </a-config-provider>
                        </a-space>
                      </td>

                      <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                      <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)">
                        <a-select v-model:value="item[item2.dataIndex]"
                                  show-search
                                  :placeholder="`请选择1${item2.title}`"
                                  style="width: 200px"
                                  :options="options.service_provider"
                                  :filter-option="filterOption"
                                  @change="handleChangeSelect(item.dataIndex,$event)">
                        </a-select>
                      </td>

                      <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                          class="column">
                        {{item2.title}}</td>
                      <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                        <a-input-number :bordered="false"
                                        style="width:200px"
                                        v-model:value="item[item2.dataIndex]"
                                        :placeholder="`请输入${item2.title}`" />
                      </td>
                      <td v-if="['signing_company','service_product'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                      <td v-if="['signing_company','service_product'].includes(item2.dataIndex)">
                        <a-input :bordered="false"
                                 v-model:value="item[item2.dataIndex]"
                                 :placeholder="`请输入${item2.title}`" />
                      </td>
                      <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}</td>
                      <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)">
                        <a-input :bordered="false"
                                 v-model:value="item[item2.dataIndex]"
                                 :placeholder="`请输入${item2.title}`" />
                      </td>
                    </template>
                  </tr>
                </template>
                <tr>
                  <td class='column'>附件</td>
                  <td colspan="5">
                    <div style="dispaly:flex;align-items: center;">

                      <uploadComponent :fileIds='item.fileIds'
                                       @update:fileIds='(event)=>fileChange(event,"internet",index)'>

                      </uploadComponent>
                    </div>
                  </td>
                </tr>

              </table>
            </div>
          </div>
          <!-- 网关设备安装 -->
          <div v-if='activeKeyRef=="网关设备安装"'>
            <div class='order-box'
                 v-for="(item, index) in workTableData.installWork"
                 :key="item.id">
              <table class="commonTable"
                     cellspacing="0"
                     cellpadding="0">

                <tr>
                  <td class="column">工单类型</td>
                  <td style="font-size:16px">网关设备安装
                  </td>

                </tr>
                <tr>
                  <td class="column">工作号</td>
                  <td>{{item.work_content_sn}}</td>
                  <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
                  <td>{{item.status}}

                  </td>
                </tr>

                <template v-for="item1 in groupedItems(installWork)"
                          :key="item1.key">
                  <tr>
                    <template v-for="(item2) in item1"
                              :key="item2.key">

                      <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)"
                          class="column">
                        {{item2.title}}<span style="color: red;margin-left: 0px;">*</span>
                      </td>
                      <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)">
                        <a-space direction="vertical"
                                 :size="12">
                          <a-config-provider :locale="locale">
                            <a-date-picker valueFormat='YYYY-MM-DD'
                                           @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                           v-model:value="item[item2.dataIndex]" />
                          </a-config-provider>
                        </a-space>
                      </td>

                      <td v-if="['express_received_time'].includes(item2.dataIndex)"
                          class="column">
                        {{item2.title}}
                      </td>
                      <td v-if="['express_received_time'].includes(item2.dataIndex)">
                        <a-space direction="vertical"
                                 :size="12">
                          <a-config-provider :locale="locale">
                            <a-date-picker valueFormat='YYYY-MM-DD'
                                           @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                           v-model:value="item[item2.dataIndex]" />
                          </a-config-provider>
                        </a-space>
                      </td>

                      <td v-if="['payment_type'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                      <td v-if="['payment_type'].includes(item2.dataIndex)">
                        <a-select v-model:value="item[item2.dataIndex]"
                                  show-search
                                  :placeholder="`请选择${item2.title}`"
                                  style="width: 200px"
                                  :options="options[item2.dataIndex]"
                                  :filter-option="filterOption"
                                  @change="handleChangeSelect(item.dataIndex,$event)">
                        </a-select>
                      </td>

                      <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                          class="column">
                        {{item2.title}}</td>
                      <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                        <a-input-number :bordered="false"
                                        style="width:200px"
                                        v-model:value="item[item2.dataIndex]"
                                        :placeholder="`请输入${item2.title}`" />
                      </td>
                      <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
                      <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)">
                        <a-input :bordered="false"
                                 v-model:value="item[item2.dataIndex]"
                                 :placeholder="`请输入${item2.title}`" />
                      </td>
                      <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)"
                          class="column">{{item2.title}}</td>
                      <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)">
                        <a-input :bordered="false"
                                 v-model:value="item[item2.dataIndex]"
                                 :placeholder="`请输入${item2.title}`" />
                      </td>
                    </template>
                  </tr>
                </template>

                <tr>
                  <td class='column'>设备</td>
                  <td colspan="5">
                    <thead>
                      <tr>
                        <td v-if="item.devices.length != 0"
                            scope="col">设备名称<span style="color: red;margin-left: 0px;">*</span>
                        </td>
                        <td v-if="item.devices.length != 0"
                            scope="col">数量<span style="color: red;margin-left: 0px;">*</span>
                        </td>
                        <td scope="col"><a style="margin:2px;color: #1884ea;"
                             @click="addDevice(index)">添加设备</a></td>
                      </tr>
                    </thead>

                    <tbody v-for="(device,index1) in item.devices"
                           :key="device.id">
                      <tr>
                        <td scope="row">

                          <a-select style='margin-left:-10px;width:100%'
                                    v-model:value="device.device_id"
                                    show-search
                                    placeholder="选择产品"
                                    :options="options['device_selector']"
                                    :filter-option="filterOption"></a-select>
                        </td>
                        <td>
                          <a-input-number style="width:100%;margin-left:-10px;"
                                          :bordered="false"
                                          v-model:value="device.quantity"
                                          :placeholder="`请输入数量`" />
                        </td>
                        <td style="margin:2px;color: #1884ea;"
                            @click="removeUser(index1,device)">删除设备</td>
                      </tr>

                    </tbody>
                  </td>
                </tr>
                <tr>
                  <td class='column'>附件</td>
                  <td colspan="5">
                    <div style="dispaly:flex;align-items: center;">
                      <!-- <a-select v-model:value="item.attachment"
                        show-search
                        placeholder="请选择附件类型"
                        style="width: 180px"
                        mode=""
                        :options="options['attachment']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect('attachment',$event)">
              </a-select> -->
                      <!-- <a-upload v-model:file-list="item.fileList"
                        :data="{file_type: item.attachment}"
                        :action="uploadUrl"
                        list-type="picture"
                        :headers="headers"
                        @change="fileHandleChange">
                <a-button :disabled="item.attachment == null ?true:false">
                  <upload-outlined></upload-outlined>
                  上传
                </a-button>
              </a-upload> -->
                      <uploadComponent :fileIds='item.fileIds'
                                       @update:fileIds='(event)=>fileChange(event,"installWork",index)'>

                      </uploadComponent>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class='column'>备注</td>
                  <td colspan="5">
                    <a-input :bordered="false"
                             v-model:value="item['desc']"
                             :placeholder="`请输入备注`" />

                  </td>
                </tr>

              </table>

            </div>
          </div>
          <!-- 故障维修 -->
          <div v-if='activeKeyRef=="故障维修"'>
            <div v-for="(item,index) in workTableData.faultOrder"
                 :key="item.id">

              <table class="commonTable"
                     cellspacing="0"
                     cellpadding="0">

                <tr>
                  <td class="column">工作类型</td>
                  <td style="font-size:16px">故障维修
                  </td>

                </tr>
                <tr>
                  <td class="column">工作号</td>
                  <td>{{item.work_content_sn}}</td>
                </tr>
                <tr>
                  <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
                  <td>
                    {{item.status}}
                  </td>

                  <td class='column'
                      v-if='item.status == "待补充"'> 拒绝原因</td>
                  <td v-if='item.status == "待补充"'>{{item.reject_reason}}</td>
                </tr>

                <!-- <tr>
                  <td class="column">诊断出的故障原因</td>
                  <td>
                    <a-input :bordered="false"
                             v-model:value="item.fault_desc"
                             :placeholder="`请输入故障原因`" />
                  </td>
                </tr> -->
                <tr>

                  <td class="column">处理结果</td>
                  <td>
                    <a-input :bordered="false"
                             v-model:value="item.on_site_result"
                             :placeholder="`请输入处理结果`" />
                  </td>

                </tr>

                <tr>
                  <td class="column">附件</td>
                  <td style="dispaly:flex;align-items: center;">
                    <uploadComponent :fileIds='item.fileIds'
                                     :isButtonShow='false'
                                     :inputShow='false'
                                     @update:fileIds='(event)=>fileChange(event,"faultOrder",index)'>

                    </uploadComponent>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="column">故障描述</td>
                  <td>
                    <a-textarea :bordered="false"
                                v-model:value="item.desc"
                                placeholder="请输入备注"
                                :rows="2" />
                  </td>
                </tr> -->
              </table>

              <tr>
                <td colspan="5"
                    style="height: 5px;"></td>
              </tr>
            </div>
          </div>
          <!-- 测试报告 -->
          <div v-if='activeKeyRef=="测试报告"'>
            <div v-for="(item,index) in workTableData.testReport"
                 :key="item.id">

              <table class="commonTable"
                     cellspacing="0"
                     cellpadding="0">

                <tr>
                  <td class="column">工作类型</td>
                  <td style="font-size:16px">测试报告
                  </td>

                </tr>
                <tr>
                  <td class="column">工作号</td>
                  <td>{{item.work_content_sn}}</td>
                </tr>
                <tr>
                  <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
                  <td>
                    {{item.status}}
                  </td>
                  <td v-if='item.status == "待补充"'
                      class='column'> 拒绝原因</td>
                  <td v-if='item.status == "待补充"'>{{item.reject_reason}}</td>
                </tr>

                <tr>
                  <td class="column">线路策略</td>
                  <td>
                    <a-input :bordered="false"
                             v-model:value="item.line_policy"
                             :placeholder="`请输入线路策略`" />
                  </td>
                </tr>
                <tr>

                  <td class="column">线路接入方式</td>
                  <td>
                    <a-input :bordered="false"
                             v-model:value="item.line_access_mode"
                             :placeholder="`请输入线路接入方式`" />
                  </td>

                </tr>

                <tr>
                  <td class="column">附件</td>
                  <td style="dispaly:flex;align-items: center;">
                    <uploadComponent :fileIds='item.fileIds'
                                     @update:fileIds='(event)=>fileChange(event,"testReport",index)'>

                    </uploadComponent>
                  </td>
                </tr>
                <tr>
                  <td class="column">工作内容描述</td>
                  <td>
                    <a-textarea :bordered="false"
                                v-model:value="item.desc"
                                placeholder="请输入工作内容描述"
                                :rows="2" />
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template #tab>
          <span>
            <android-outlined />
            流程信息
          </span>
        </template>

        <div>
          <a-timeline mode="alternate">
            <a-timeline-item v-for="item in baseData.flow_steps"
                             :key="item.id">
              <p>{{item.step}} {{item.create_time}}</p>

              <p>{{item.after_result}}</p>
            </a-timeline-item>
          </a-timeline>
        </div>
      </a-tab-pane>
    </a-tabs>

  </a-modal>
  <a-table :scroll="ne"
           :columns="columns"
           :data-source="tableSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">

      <template v-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="lookWorkView(record)">查看工单</a>
          <a class='editable-row-a'
             v-if='["已调度","已预约","已到店"].includes(record.status)  && buttonShow.appointmentTime && !["待审核","已完成"].includes(record.work_contents[0].status )'
             @click="handleWorkContent(record)">处理工作内容单</a>
          <a class='editable-row-a'
             v-if='buttonShow.handleWorkOrder && record.status=="远程处理"'
             @click="handleWorkOrder(record)">处理工单</a>

          <a class='editable-row-a'
             v-if='buttonShow.resultAudit && record.status=="已到店" && record.work_contents[0].status =="待审核"'
             @click="examineWorkOrder(record)">审核</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <label style="margin-right:5px">状态:</label>
        <!-- <span class="addon">状态</span> -->
        <a-select v-model:value="searchData.status"
                  show-search
                  placeholder="选择搜索项"
                  style="width: 100px"
                  :options="options.status">
        </a-select>
        <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSearch"
                  style="margin: 5px">重置</a-button>
        <a-button type="primary"
                  @click="refresh"
                  style="margin: 5px">刷新</a-button>
        <div class='right-buttons'>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUnmounted, onUpdated, watch } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router';
import qs from 'qs';
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { selector } from '@/utils/selector'
import serviceWebsocket from "@/utils/serviceWebsocket";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;

import { useScreenSize } from '@/utils/useScreenSize';
const { windowWidth, windowHeight } = useScreenSize();
const ne = reactive({
  x: "",
  y: ''
})
const tableHeight = () => {

  ne.y = windowHeight.value - 328

}

onMounted(() => {
  tableHeight()

})

//定义查看工单tab栏字段
const baseOrFlowMessageActiveKey = ref('1')
//定义tab栏数据
const activeKeyRef = ref()
let activeKeyList = []
dayjs.locale("zh-cn");
let route = useRoute()
//统一管理接口变量
const lookPic = process.env.VUE_APP_URL + interfaces.fileGet
const uploadUrl = process.env.VUE_APP_URL + interfaces.commonUpload
const url = {
  add: interfaces.ticketsAdd,
  delete: interfaces.ticketDelete,
  edit: interfaces.ticketEdit,
  page: '/v1/work_order/my',
  getById: interfaces.deviceGetById,
  ticketsType: interfaces.ticketsSelector,
  contractPage: interfaces.contractPage,
  workSelector: interfaces.workSelector,
  handleWorkOrder: interfaces.handleWorkOrder,
  appointmentTime: interfaces.appointmentTime,
  resultAudit: interfaces.resultAudit,
  handleWorkOrder: interfaces.handleWorkOrder,
}
const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
  // "Content-Type": "multipart/form-data"
})



//按钮的显示，对不同用户进行权限控制，普通用户一般不具有增删改查的权限，具体需要看角色配置
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
  handleWorkOrder: true,
  handleWorkContent: true,
  appointmentTime: true,
  resultAudit: true //审核

})

//从缓存中获取按钮权限
//所需数据：浏览器缓存rules字段的规则数据、url中的页面路由、统一管理接口的数据 
const getButtonRules = () => {

  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}



getButtonRules()

//搜索字段
let searchData = reactive({
  status: ""
});

// table列名数据
const columns = reactive([
  {
    title: "工单号",
    dataIndex: "work_sn",
    key: "work_sn",
    width: '200px',
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "工单类型",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "品牌",
    dataIndex: "customer_name",
    key: "customer_name"
  },
  {
    title: "门店名称",
    dataIndex: "contract_user_name",
    key: "contract_user_name",
    width: '150px',
    ellipsis: true
  },
  {
    title: "地址",
    dataIndex: "complete_address",
    key: "complete_address",
    width: '150px',
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contract_user_contact_name",
    key: "contract_user_contact_name"
  },
  {
    title: "联系人手机号",
    dataIndex: "contract_user_phone_number",
    key: "contract_user_phone_number"
  },


  {
    title: "操作",
    dataIndex: "operation",
    key: "operation"
  }
]);


//增加或者编辑的模态框规则
const rules = {
  sourceExplorate: {},
  base: {
    must: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    product: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    type: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
  },

  internet: {
    provider_customer_manager_name: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    provider_customer_manager_phone: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    service_provider: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    signing_company: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    service_product: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

    payment_type: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    duration: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    payment_amount: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    service_provider: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    begin_date_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    end_date_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    workType: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

  },

  installWork: {
    outbound_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    receipt_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    plan_install_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    arrival_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    complete_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

  }

}
//table数据源
const tableSource = reactive(
  {
    data: [],
    region: ""
  }
)


//选择框数据源
const options = reactive({

  attachment: [],//附件类型选择器数据
  order_status: [],//工作内容状态选择器数据
  status: [],//工单状态选择器数据
  device_selector: [
  ],
  provider_resources: [
    {

      label: "强",
      value: "强"

    },
    {

      label: "中",
      value: "中"

    },
    {

      label: "弱",
      value: "弱"

    },
  ],
  worker: [

  ],
  type: [{
    label: "网络接入",
    value: "网络接入"
  },
  ],
  workType: [{
    label: "宽带接入",
    value: "宽带接入"
  },
  ],//工作内容
  contract: "",
  product: "",
  payment_type: [{
    label: "包年",
    value: "包年"
  },
  {
    label: "包月",
    value: "包月"
  }],
  service_provider: [],
},);

// 数据模型
const baseData = reactive({


  must: "", //合同
  status: "",
  customer_id: "",
  contract_id: "",
  product: "",
  type: "",
  desc: "",
  customer_name: "",
  contract_user_name: "",
  contract_user_address: "",
  contract_user_contact_name: "",
  contract_user_phone_number: "",
  complete_address: "",


  ad_number: "",
  password: "",
  kd_number: "",
  sn: "",
  provider_customer_manager_name: "",
  provider_customer_manager_phone: "",
  installer_name: "",
  installer_phone: "",
  maintenance_name: "",
  maintenance_phone: "",

  workType: "",
  service_provider: "",
  signing_company: "",
  service_product: "",
  payment_type: "",
  duration: "",
  payment_amount: "",
  begin_date: "",
  end_date: "",

  // begin_date_str: "",
  // end_date_str: ""


})

//弹框字段
const modalData = reactive([
  {
    title: "工单号",
    dataIndex: "work_sn",
    key: "work_sn"
  },

  {
    title: "工单类型",
    dataIndex: "type",
    key: "type"
  },


  {
    title: "品牌",
    dataIndex: "customer_name",
    key: "customer_name"
  },

  {
    title: "门店名称",
    dataIndex: "contract_user_name",
    key: "contract_user_name",

  },
  {
    title: "地区",
    dataIndex: "region",
    key: "region"
  },
  {
    title: "门店地址",
    dataIndex: "contract_user_address",
    key: "contract_user_address",

  },
  {
    title: "联系人",
    dataIndex: "contract_user_contact_name",
    key: "contract_user_contact_name"
  },
  {
    title: "联系电话",
    dataIndex: "contract_user_phone_number",
    key: "contract_user_phone_number"
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status"
  },
])



//网关设备服务字段
const installWork = reactive([


  {
    title: '出库时间',
    dataIndex: 'outbound_time',
    key: 'outbound_time',
  },
  {
    title: '收货时间',
    dataIndex: 'receipt_time',
    key: 'receipt_time',
  },
  {
    title: '计划安装时间',
    dataIndex: 'plan_install_time',
    key: 'plan_install_time',
  },
  {
    title: '到达现场时间',
    dataIndex: 'arrival_time',
    key: 'arrival_time',
  },
  {
    title: '完成安装时间',
    dataIndex: 'complete_time',
    key: 'complete_time',
  },
  //   devices: [],
  // data_attachment_ids: [],
  // e_signature_ids: [],


  {
    title: '运输方式',
    dataIndex: 'transport_method',
    key: 'transport_method',
  },
  {
    title: '快递单号',
    dataIndex: 'express_number',
    key: 'express_number',
  },
  {
    title: '快递收件人',
    dataIndex: 'express_receiver',
    key: 'express_receiver',
  },
  {
    title: '快递签收时间',
    dataIndex: 'express_received_time',
    key: 'express_received_time',
  },

  // {
  //   title: '附件类型',
  //   dataIndex: 'attachment',
  //   key: 'attachment',
  // },
  {
    title: '设备信息',
    dataIndex: 'devices',
    key: 'devices',
  },
  {
    title: '附件',
    dataIndex: 'attachment_file',
    key: 'attachment_file',
  },
  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },

  // {
  //   title: '安装调试资料的id',
  //   dataIndex: 'data_attachment_ids',
  //   key: 'data_attachment_ids',
  // },
  // {
  //   title: '电子签名图片的id',
  //   dataIndex: 'e_signature_ids',
  //   key: 'e_signature_ids',
  // },

])

//宽带服务字段
const modalInterMust = reactive([

  {
    title: '运营商',
    dataIndex: 'service_provider',
    key: 'service_provider',
  },

  {
    title: '账号/专线编号',
    dataIndex: 'ad_number',
    key: 'ad_number',
  },
  {
    title: '账号/专线密码',
    dataIndex: 'password',
    key: 'password',
  },
  {
    title: '分帐序号/KD号',
    dataIndex: 'kd_number',
    key: 'kd_number',
  },
  {
    title: 'SN号',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: '客户经理名称',
    dataIndex: 'provider_customer_manager_name',
    key: 'provider_customer_manager_name',
  },
  {
    title: '客户经理手机号',
    dataIndex: 'provider_customer_manager_phone',
    key: 'provider_customer_manager_phone',
  },
  {
    title: '安装人名字',
    dataIndex: 'installer_name',
    key: 'installer_name',
  },
  {
    title: '安装人手机',
    dataIndex: 'installer_phone',
    key: 'installer_phone',
  },
  {
    title: '维护人名字',
    dataIndex: 'maintenance_name',
    key: 'maintenance_name',
  },
  {
    title: '维护人手机',
    dataIndex: 'maintenance_phone',
    key: 'maintenance_phone',
  },
  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },


])
// 将宽带服务分成2个一组
const groupedItems = (item) => {
  const chunkSize = 2;
  const result = [];
  for (let i = 0; i < item.length; i += chunkSize) {
    const chunk = item.slice(i, i + chunkSize);
    result.push(chunk);
  }
  // console.log("result", result)
  return result;
};
//资源勘察字段
const sourceExplorate = reactive([
  {
    title: '物业联系人',
    dataIndex: 'pmc_contact_name',
    key: 'pmc_contact_name',
  },

  {
    title: '物业联系人电话',
    dataIndex: 'pmc_contact_phone',
    key: 'pmc_contact_phone',
  },
  {
    title: '代理管控价格',
    dataIndex: 'monopolized_prices',
    key: 'monopolized_prices',
  },
  {
    title: '是否有电源',
    dataIndex: 'power',
    key: 'power',
  },
  {
    title: '是否有空间',
    dataIndex: 'space',
    key: 'space',
  },
  {
    title: '是否需要布线',
    dataIndex: 'need_line',
    key: 'need_line',
  },
  {
    title: '弱电井是否可用',
    dataIndex: 'weak_current_well',
    key: 'weak_current_well',
  },

  {
    title: '是否代理管控',
    dataIndex: 'is_monopolized',
    key: 'is_monopolized',
  },

  {
    title: '信号强度',
    dataIndex: 'provider_resources',
    key: 'provider_resources',
  },

  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },

])
//故障维修字段
const faultOrder = [
  {
    title: '故障描述',
    dataIndex: 'fault_desc',
    key: 'fault_desc',
  },
  {
    title: '处理结果',
    dataIndex: 'process_result',
    key: 'process_result',
  },
]

//弹框显示
const modalShow = reactive({
  workOrderView: false,
  handleWorkOrder: false,
  isDeleteModalVisible: false,
  buttonShow: 'none' //模态框底部按钮的显示
})
let urlFlag = "" //判断是增加数据还是编辑数据





const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;

  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let installDevices = ref([])

//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  for (let key in workTableData) {
    workTableData[key] = [];
  }



};

const formRef = ref()



const lookOrderRef = ref({
  show: true
})

//查看工单
const lookWorkView = (record) => {
  baseOrFlowMessageActiveKey.value = '1'
  handleRecordData(record)
  modalShow.workOrderView = true
}


//处理工作内容工单
const handleWorkContent = (record) => {
  modalShow.buttonShow = 'none'
  lookOrderRef.value.show = true
  handleWorkOrderRef.value.show = false
  modalShow.handleWorkOrder = true;
  handleRecordData(record)
  arriveTimeRef.value.arrival_time = ''
  appointmentTimeRef.value.schedule_time = ''

};
//定义处理工单字段
const handleWorkOrderRef = ref({
  id: '',
  status: '',
  remote_result: "",
  show: true
})
//网工处理工单
const handleWorkOrder = (record) => {
  modalShow.buttonShow = "inline";
  lookOrderRef.value.show = false
  handleWorkOrderRef.value.show = true
  handleWorkOrderRef.value.id = record.id;
  handleWorkOrderRef.value.remote_result = ''
  modalShow.handleWorkOrder = true
  handleRecordData(record)
}

const handleRecordData = (record) => {
  for (let key in baseData) {
    baseData[key] = record[key]
  }
  let storeNameId = ""
  baseData.id = record['id']
  baseData.must = [String(record['customer_id']), Number(storeNameId)]
  baseData.product = record['product_service_id']
  baseData.flow_steps = record['flow_steps']
  // baseData.workType = "宽带接入"
  baseData.region = record['region']
  baseData.work_sn = record.work_sn
  tableSource.region = record['region']
  let internet = []
  let installWork = []
  let sourceExplorate = []
  let faultOrder = []
  let testReport = []

  activeKeyList = []
  record["work_contents"].forEach(item => {
    let fileData = []
    item.fileIds = []
    if (!activeKeyList.includes(item.type)) {
      activeKeyList.push(item.type)
    }
    if (item.data_attachments.length != 0) {
      item.data_attachments.forEach(item_file => {
        // item.fileIds.push(item_file.id)
        item.fileIds.push({
          file_id: item_file.id,
          owner_class: item_file.owner_class,
          owner_id: item.id
        })
        fileData.push({
          uid: item_file.id,
          name: item_file.name,
          status: 'done',
          type: item_file.type,
          url: lookPic + item_file.id
        })
      })
    }
    if (item.e_signatures.length != 0) {
      item.e_signatures.forEach(item_file => {
        fileData.push({
          uid: item_file.id,
          name: item_file.name,
          status: 'done',
          type: item_file.type,
          url: lookPic + item_file.id
        })
      })
    }
    item.fileList = fileData
    if (item.type == "宽带接入") {
      internet.push(item)
    }
    else if (item.type == "网关设备安装") {

      installWork.push(item)
    }
    else if (item.type == "资源勘查") {
      item.provider_resources.forEach(item1 => {
        if (item1.name == "电信") {
          item["provider_resources_available_telegraphy"] = item1.available == 1 ? true : false
          item["provider_resources_rssi_telegraphy"] = item1.rssi
        }
        else if (item1.name == "联通") {
          item["provider_resources_available_link"] = item1.available == 1 ? true : false
          item["provider_resources_rssi_link"] = item1.rssi
        }
        else if (item1.name == "移动") {
          item["provider_resources_available_move"] = item1.available == 1 ? true : false
          item["provider_resources_rssi_move"] = item1.rssi
        }
      })
      sourceExplorate.push(item)
    }

    else if (item.type == "故障维修") {
      faultOrder.push(item)

      console.log("faultOrder", faultOrder)
    }

    else if (item.type == "测试报告") {
      testReport.push(item)
    }
  })

  workTableData.internet = internet
  workTableData.installWork = installWork
  workTableData.sourceExplorate = sourceExplorate
  workTableData.faultOrder = faultOrder
  workTableData.testReport = testReport
  console.log(workTableData)

  //前后字段设定不一致导致需要重新设定字段
  workTableData.installWork.forEach((item) => {
    let worker_ids = []
    let worker_name = []
    item.workers.forEach((item1) => {
      worker_ids.push(item1.id)
      worker_name.push(item1.real_name)
    })
    item["worker_ids"] = worker_ids
    item["worker_name"] = worker_name
  })
  baseData.desc = record["desc"]
  console.log("workTableData", workTableData)

  activeKeyRef.value = activeKeyList[0]

  console.log("baseData.status", baseData.status)
}


//网工远程处理工单
const handleOk = () => {
  const data = {
    id: handleWorkOrderRef.value.id,
    success: handleWorkOrderRef.value.status == 'true' ? 1 : 0,
    remote_result: handleWorkOrderRef.value.remote_result
  }
  let checkFlag = true
  for (let key in data) {
    if (data[key] === '') {
      checkFlag = false
    }
  }
  if (checkFlag) {
    serviceAxios({
      url: url.handleWorkOrder,
      method: 'post',
      data: data
    }).then(result => {
      console.log('result', result)
      modalShow.handleWorkOrder = false
      message.success('处理成功')
      queryPage()
    }).catch(error => {
      console.log(error)
    })
  }
  else {
    message.error('请检查数据')
  }
}



const workRef = ref()
const internetRef = ref()
const sourceRef = ref()
const formRefDevice = ref()


//工作内容数据
const workTableData = reactive({
  internet: [],
  installWork: [],
  sourceExplorate: [],
  faultOrder: [],
  testReport: []
})






let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

//请求
const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryFields
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    let waitExamine = false  //判断是否有待审核的工作内容
    result.data.data.list.forEach(item => {
      // item.work_contents.filter(item=>)
      data.push({
        id: item.id,
        key: item.id,
        work_sn: item.work_sn,
        type: item.type,
        status: item.status,
        customer_name: item.customer_name,
        contract_user_name: item.contract_user_name,
        contract_user_address: item.contract_user_address,
        region: item.region,
        complete_address: item.region + item.contract_user_address,
        contract_user_contact_name: item.contract_user_contact_name,
        contract_user_phone_number: item.contract_user_phone_number,
        work_contents: item.work_contents,
        desc: item.desc,
        data_attachments: item.data_attachments,
        e_signatures: item.e_signatures,
        flow_steps: item.flow_steps
      })
    });
    tableSource.data = data;
    // console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}

// 获取工单类型
const getTicketsSelector = () => {
  serviceAxios(
    {
      url: url.ticketsType,
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      options.type = result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}

//获取工作内容
const getWorkTypeSelector = () => {
  serviceAxios(
    {
      url: url.workSelector,
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      options.workType = result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}

const handleChangeSelect = (dataIndex, value) => {

};

// <a-cascader v-model:value="value" :options="options" placeholder="Please select" />
const contractData = {}
const contractPage = () => {
  serviceAxios({
    url: url.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    result.data.data.list.forEach(item => {
      if (contractData[item.customer.id] != undefined) {

        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
        })
      }
      else {

        contractData[item.customer.id] = {}
        contractData[item.customer.id].children = []
        contractData[item.customer.id].brand = item.customer.name
        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
        })
      }
    })

    let opContract = []
    for (let key in contractData) {
      opContract.push({
        label: contractData[key].brand,
        value: key,
        children: contractData[key].children
      }
      )
    }

    options.contract = opContract
  }).catch((err) => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  })
}


queryPage()
contractPage()
getTicketsSelector()
getWorkTypeSelector()


//合同
const contractChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  if (selectedOptions != undefined) {
    console.log(value, selectedOptions)
    baseData.contractId = selectedOptions[1].contract_id
    baseData.customerId = selectedOptions[1].customer_id
    baseData.brand = selectedOptions[1].brand
    baseData.storeName = selectedOptions[1].storeName
    baseData.address = selectedOptions[1].address
    baseData.contactName = selectedOptions[1].contactName
    baseData.contactPhone = selectedOptions[1].contactPhone
    let product = []
    selectedOptions[1].productServices.forEach(
      item => {
        product.push({
          value: item.id,
          label: item.product.name
        })
      }
    )
    options.product = product
  }

  console.log("baseData", baseData)


}
const onOpenChange = (type, time, dataIndex, index) => {

  console.log("onOpenChange.status", time, dataIndex, index);

  if (time != null) {


    workTableData[type][index][dataIndex] = time


  }
  else {
    workTableData[type][index][dataIndex] = ""
  }

};









const getOutWorer = () => {
  serviceAxios({
    url: interfaces.outWorkerPage,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.real_name,
        value: item.id
      })
    })
    options.worker = data
  }).catch()
}
getOutWorer()

const getDeviceSelector = () => {
  serviceAxios({
    url: interfaces.deviceSelector,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      data.push({
        label: item.name,
        value: item.id
      })
    })
    options.device_selector = data
  })
}
getDeviceSelector()


const addDevice = (index) => {
  workTableData.installWork[index].devices.push({
    quantity: 1,
    device_id: null,
  })

};

const removeUser = (index1, item) => {
  const index = workTableData.installWork[index1].devices.indexOf(item);
  if (index !== -1) {
    workTableData.installWork[index1].devices.splice(index, 1);
  }
};

const getOrderStatusSelector = () => {
  serviceAxios({
    url: interfaces.orderStatusSelector,
    method: "get",
    params: {

    }
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      data.push({
        label: item.name,
        value: item.value
      })
    })
    options.order_status = data
  })
}
getOrderStatusSelector()


const options_attachment = () => {
  serviceAxios({
    url: interfaces.fileSelector,
    method: "get",
    params: {
    }
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      if (["一般资料附件", "电子签名附件"].includes(item.label)) {
        data.push({
          label: item.label,
          value: item.value
        })
      }

    })
    options.attachment = data
  })
}
options_attachment()


//保存订单
const saveOrder = (type, index) => {
  let submitUrl = ""
  let subData = ""
  let orderData = workTableData[type][index]
  console.log("orderData", orderData)

  let e_signature_ids = []
  let data_attachment_ids = []
  data_attachment_ids = orderData.fileList
  // orderData.fileList.forEach(fileItem => {

  //   if (fileItem.url != undefined) {
  //     if (fileItem.type == "attachment_data") {
  //       data_attachment_ids.push(fileItem.uid)
  //     }
  //     else if (fileItem.type == "attachment_e_signature") {
  //       e_signature_ids.push(fileItem.uid)
  //     }
  //     else {
  //     }
  //   }
  //   else {
  //     if (fileItem.response.data[0].file_type == "DataAttachment") {
  //       data_attachment_ids.push(fileItem.response.data[0].id)
  //     }
  //     else if (fileItem.response.data[0].file_type == "ESignatureAttachment") {
  //       e_signature_ids.push(fileItem.response.data[0].id)
  //     }
  //     else {

  //     }
  //   }

  // })
  if (type == "internet") {

    submitUrl = interfaces.outWorkInternet
    subData = {
      id: orderData.id,
      service_provider: orderData.service_provider,
      status: orderData.status,
      desc: orderData.desc,
      // data_attachment_ids: orderData.data_attachment_ids,
      // e_signature_ids: orderData.e_signature_ids,
      ad_number: orderData.ad_number,
      password: orderData.password,
      kd_number: orderData.kd_number,
      sn: orderData.sn,
      provider_customer_manager_name: orderData.provider_customer_manager_name,
      provider_customer_manager_phone: orderData.provider_customer_manager_phone,
      installer_name: orderData.installer_name,
      installer_phone: orderData.installer_phone,
      maintenance_name: orderData.maintenance_name,
      maintenance_phone: orderData.maintenance_phone,
      data_attachment_ids: data_attachment_ids,
      e_signature_ids: e_signature_ids,
    }
  }
  else if (type == "installWork") {
    submitUrl = interfaces.outWorkInstall

    subData = {
      id: orderData.id,
      status: orderData.status,
      desc: orderData.desc,
      // data_attachment_ids: orderData.data_attachment_ids,
      // e_signature_ids: orderData.e_signature_ids,
      transport_method: orderData.transport_method,
      express_number: orderData.express_number,
      express_receiver: orderData.express_receiver,
      express_received_time: orderData.express_received_time,
      devices: orderData.devices,
      outbound_time: orderData.outbound_time,
      receipt_time: orderData.receipt_time,
      plan_install_time: orderData.plan_install_time,
      arrival_time: orderData.arrival_time,
      complete_time: orderData.complete_time,
      data_attachment_ids: data_attachment_ids,
      e_signature_ids: e_signature_ids,
    }
  }
  else if (type == "sourceExplorate") {
    submitUrl = interfaces.outWorkSource
    orderData.provider_resources.forEach(item1 => {
      if (item1.name == "电信") {
        item1["available"] = orderData.provider_resources_available_telegraphy == true ? 1 : 0;
        item1["rssi"] = orderData.provider_resources_rssi_telegraphy;
      }
      else if (item1.name == "联通") {
        item1["available"] = orderData.provider_resources_available_link == true ? 1 : 0;
        item1["rssi"] = orderData.provider_resources_rssi_link;
      }
      else if (item1.name == "移动") {
        item1["available"] = orderData.provider_resources_available_move == true ? 1 : 0;
        item1["rssi"] = orderData.provider_resources_rssi_move;
      }
    })

    subData = {
      id: orderData.id,
      status: orderData.status,
      desc: orderData.desc,
      // data_attachment_ids: orderData.data_attachment_ids,
      // e_signature_ids: orderData.e_signature_ids,
      power: orderData.power,
      space: orderData.space,
      need_line: orderData.need_line,
      weak_current_well: orderData.weak_current_well,
      pmc_contact_name: orderData.pmc_contact_name,
      pmc_contact_phone: orderData.pmc_contact_phone,
      is_monopolized: orderData.is_monopolized,
      monopolized_prices: orderData.monopolized_prices,
      provider_resources: orderData.provider_resources,
      data_attachment_ids: data_attachment_ids,
      e_signature_ids: e_signature_ids,
      // provider_resources: [
      //   {
      //     "name": "电信",
      //     "available": orderData.provider_resources_available_telegraphy == true ? 1 : 0,
      //     "rssi": orderData.provider_resources_rssi_telegraphy,
      //   },
      //   {
      //     "name": "联通",
      //     "available": orderData.provider_resources_available_link == true ? 1 : 0,
      //     "rssi": orderData.provider_resources_rssi_link,
      //   },
      //   {
      //     "name": "移动",
      //     "available": orderData.provider_resources_available_move == true ? 1 : 0,
      //     "rssi": orderData.provider_resources_rssi_move,
      //   }
      // ]

    }
  }
  else if (type == 'faultOrder') {
    submitUrl = interfaces.outWorkRepair
    subData = {
      id: orderData.id,
      status: "",
      desc: orderData.desc,
      data_attachment_ids: data_attachment_ids,
      e_signature_ids: e_signature_ids,
      fault_desc: orderData.fault_desc,
      process_result: orderData.process_result,
      worker_ids: []
    }
  }

  else if (type == 'testReport') {
    submitUrl = interfaces.outWorkTestReport
    subData = {
      id: orderData.id,
      status: "",
      desc: orderData.desc,
      data_attachment_ids: [],
      e_signature_ids: [],
      line_policy: orderData.line_policy,
      line_access_mode: orderData.line_access_mode,
      worker_ids: []
    }
  }

  console.log('subData', subData)
  serviceAxios({
    url: submitUrl,
    method: "post",
    data: subData
  }).then(result => {
    if (result.data.message == "success") {
      message.success("保存成功")
      queryPage()
    }
    else {
      message.error("保存失败")
    }
  }).catch()
}

//提交订单
const submitOrder = async (workContentID, type, index) => {
  const response = await serviceAxios({
    url: interfaces.workContentSubmit,
    method: 'post',
    data: {
      work_content_ids: [workContentID]
    }
  })

  if (response.data.message == 'success') {
    message.success(response.data.message)
    console.log(workContentID, type, index)

    const res = await serviceAxios({
      url: interfaces.workContentGetById,
      method: 'post',
      params: {
        id: workContentID
      }
    })
    console.log('res', res)
    console.log(type, index)
    workTableData[type][index].status = res.data.data.status

  }
  else {
    message.error(response.data.message)
  }
}
//提交故障维修工单
const submitfaultOrder = async (workContentID, type, index, workOrderId) => {
  console.log('workTableData[type][index]', workTableData[type][index], workOrderId)
  const data = {
    id: workOrderId,
    on_site_result: workTableData[type][index]['on_site_result'],
    complete_time: null,
    data_attachments: workTableData[type][index]['fileList'],
    e_signatures: [
    ]
  }
  if (data.workOrderId === '' || data.on_site_result === '') {
    message.error("请检查数据")

  }
  else {
    const response = await serviceAxios({
      url: interfaces.faultOrderSubmit,
      method: 'post',
      data: data
    })

    if (response.data.message == 'success') {
      message.success('提交成功')
      modalShow.handleWorkOrder = false
    }
    else {
      message.error(response.data.message)
    }
  }
}
const examineWorkOrderRef = ref({
  id: 0,
  status: 0,
  desc: "",
  reject_reason: "",
  complete_time: ''
})

//处理审核工单
const examineWorkOrder = (record) => {
  console.log('record', record)
  modalShow.examineWorkOrder = true
  examineWorkOrderRef.value.id = record.id
  handleRecordData(record)
  examineWorkOrderRef.value.status = 'true'
  examineWorkOrderRef.value.desc = ''
  examineWorkOrderRef.value.reject_reason = ''
}

const examineHandleOk = () => {

  const data = {
    id: examineWorkOrderRef.value.id,
    status: examineWorkOrderRef.value.status == 'true' ? 1 : 0,
    desc: examineWorkOrderRef.value.desc,
    reject_reason: examineWorkOrderRef.value.reject_reason,
    complete_time: null
  }
  serviceAxios({
    url: interfaces.resultAudit,
    method: 'post',
    data: data
  }).then(result => {
    console.log(result)
    if (result.data.message = 'success') {
      modalShow.examineWorkOrder = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }

  })
}

const workOrderById = async (id) => {
  const response = await serviceAxios({
    url: interfaces.workGetById,
    method: 'get',
    params: {
      work_order_id: id
    }
  })
  return { data: response.data.data }
}
// serviceWebsocket.init_websocket(queryPage)

const sift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10
  }
  queryFields["status"] = searchData.status
  queryPage()
}

const resetSearch = () => {

  queryFields = {
    page_num: 1,
    page_size: 10
  }
  searchData.status = ""
  queryPage()
}


const filterOption = (input, option) => {

  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const fileHandleChange = (info) => {
  console.log(info)
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }

}

//定义预约字段
const appointmentTimeRef = ref({
  id: '',
  schedule_time: '',
})
const appointmentTime = (id) => {
  const data = {
    id: id,
    schedule_time: appointmentTimeRef.value.schedule_time
  }
  if (data.schedule_time != '') {
    serviceAxios({
      url: interfaces.appointmentTime,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message = 'success') {
        message.success('预约成功')
        baseData.status = '已预约'
        queryPage()

      }
      else {
        message.error(result.data.message)
      }

    })
  }
  else {
    message.error('请检查数据')
  }
}



//定义到达现场字段
const arriveTimeRef = ref({
  id: '',
  arrival_time: '',
})
//到达现场
const arriveTime = (id) => {
  const data = {
    id: id,
    arrival_time: null
  }
  if (data.arrival_time != '') {
    serviceAxios({
      url: interfaces.arriveTime,
      method: 'post',
      data: data
    }).then(result => {
      if (result.data.message = 'success') {
        message.success('上门成功')
        baseData.status = '已到店'
        modalShow.handleWorkOrder = false;
        queryPage()

      }
      else {
        message.error(result.data.message)
      }

    })
  }
  else {
    message.error("请检查数据")
  }
}

//刷新
const refresh = () => {
  queryPage()
}


const fileChange = (event, type, index, fileType) => {
  console.log(event, type, index, fileType)
  const fileList = []

  event.forEach(item => {
    fileList.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })

  console.log("fileList", fileList)
  workTableData[type][index].fileList = fileList
}





(async () => {
  options.service_provider = await selector.networkProvider();
})()
</script>
<style lang="less" scoped>
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.separator-line {
  border-bottom: 1px solid rgb(232, 61, 61);
  margin: 20px 0;
}

.commonTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.commonTable tr td {
  // width: 250px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.commonTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 15%;
}
.order-box {
  margin-top: 10px;
}
</style>