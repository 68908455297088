//选择器数据

import interfaces from "@/config"
import serviceAxios from "./serviceAxios"


//运营商
export const networkProvider = async () => {
  const response = serviceAxios({
    url: interfaces.networkProviderSelector,
    method: 'get',
  })
  const result = response.data.data.map(item => ({
    label: item.name,
    value: item.value
  }))
  return result
}

export const selector = {
  //运营商
  networkProvider: async function () {
    const response = await serviceAxios({
      url: interfaces.networkProviderSelector,
      method: 'get',
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },

  //品牌
  brand: async function () {
    const response = await serviceAxios({
      url: interfaces.customerPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))
    return result
  },

  //工单状态
  workOrderStatus: async function (orderType) {
    const response = await serviceAxios({
      url: interfaces.statusSelector,
      method: 'get',
      params: {
        type: orderType
      }
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },


  //工单类型
  workOrderType: async function () {
    const response = await serviceAxios({
      url: interfaces.ticketsSelector,
      method: 'get',

    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },

  //工作内容类型
  workOrderContentType: async function () {
    const response = await serviceAxios({
      url: interfaces.workSelector,
      method: 'get',

    })
    return response.data.data
  },


  //运维人员所属公司
  workOrderCompany: async function () {
    const response = await serviceAxios({
      url: interfaces.tenantPage,
      method: 'get',
      params: {
        type: "外包",
        page_num: 1,
        page_size: 99999
      }

    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))
    return result
  },

  store: async function () {
    const response = await serviceAxios({
      url: interfaces.customerUserPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  },
  role: async function () {
    const response = await serviceAxios({
      url: interfaces.rolePage,
      method: 'post',
      data: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  },

  //网工
  networkEngineer: async function () {
    const response = await serviceAxios({
      url: interfaces.queryNetworkEngineer,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.real_name,
      value: item.id
    }))

    return result;
  },
  //基础角色

  baseRole: async function () {

    const response = await serviceAxios({
      url: interfaces.rolePage,
      method: 'post',
      data: {
        page_num: 1,
        page_size: 99999,
        is_base: 1
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  }

}