<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
// 用户列表界面
<template>
  <a-modal :maskClosable="false"
           v-model:visible="showModal.show"
           title="添加项目"
           width="1000px"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="formData"
            ref="formRef"
            :rules=rules
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 14 }">
      <a-row :gutter="24">
        <template v-for="item in columns"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label=item.title
                         :name=item.dataIndex>

              <a-input v-if="['user_amount','load_rate'].includes(item.dataIndex)"
                       disabled=true
                       v-model:value="formData[item.dataIndex]" />

              <a-select v-else-if="['customer_name'].includes(item.dataIndex)"
                        v-model:value="treeValue"
                        show-search
                        placeholder="Select a person"
                        style="width: 200px"
                        :options="options.treeData"
                        :filter-option="filterOption"
                        @focus="handleFocus"
                        @blur="handleBlur"
                        @change="handleChange"></a-select>
              <a-input v-else
                       v-model:value="formData[item.dataIndex]" />
            </a-form-item>
          </a-col>
        </template>

      </a-row>
    </a-form>
  </a-modal>
  <a-modal :maskClosable="false"
           v-model:visible="columnShowModal"
           title="选择列显示"
           @ok="columnHandleOk"
           @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns"
                      :key="column.key"
                      :value="column.key"
                      :rules=rules>
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>

  <TableComponent :tableScrollY=300
                  :columns="filteredColumns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>

        <div class="right-buttons">

          <a-button class="addDataButton"
                    type="primary"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </TableComponent>

</template>
<script setup>
import { reactive, ref, computed } from "vue";
// import { useRouter } from "vue-router";

import serviceAxios from "../../utils/serviceAxios";
// import { toRaw } from "@vue/reactivity";
import TableComponent from '@/components/TableComponent.vue'
import { message } from "ant-design-vue";
import interfaces from "@/config";
// const [messageApi, contextHolder] = message.useMessage();

// const router = useRouter();
let formRef = ref();
const Y = 290;
let showModal = reactive({
  show: false,
});
const columns = [
  { title: "品牌", dataIndex: "customer_name", key: 1 },
  { title: "门店", dataIndex: "name", key: 2 },
  // { title: "身份证", dataIndex: "id_card", key: 3 },
  { title: "联系人", dataIndex: "contact_name", key: 4 },
  { title: "手机号", dataIndex: "phone_number", key: 5 },
  { title: "电话号", dataIndex: "telephone", key: 6 },
  { title: "地址", dataIndex: "address", key: 7 },
  { title: "备注", dataIndex: "desc", key: 8 },


];

let formData = reactive({
  id: "",
  key: "",
  customer_id: "",
  customer_name: "",
  customer: "",
  name: "",
  id_card: "",
  contact_name: "",
  phone_number: "",
  telephone: "",
  address: "",
  desc: "",

});
let dataSource = reactive({
  data: [],
  regional_name: []
});

let options = {
  treeData: [],
}
//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  size: "small",
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`

});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;

  pagination.current = current;
  pagination.pageSize = pageSize;
  searchFileds.page_num = current;
  searchFileds.page_size = pageSize;

  queryPage()
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

// table选择框
let rowData = [];
let selectedRowKey = ref()
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,

  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

//重置数据模型
const resetForm = () => {
  for (let key in formData) {
    formData[key] = "";
  }

};

//判断是添加还是修改数据
let url = "";
//添加
const addData = () => {
  resetForm();
  showModal.show = true;
  treeValue.value = ""
  url = interfaces.customerUserAdd;

  // formRef.value.resetFields();
};

const handleOk = () => {
  console.log("handleOk");
  // console.log(formRef.value)
  // console.log(formData)

  formRef.value
    .validate()
    .then(() => {
      serviceAxios({
        url: url,
        method: "post",
        data: formData,
      })
        .then(() => {
          message.success("success");
          queryPage(pagination.current, pagination.pageSize);
          selectedRowKey.value = [];



        })
        .catch(() => {
          message.error("请求异常，请联系管理员");
        });
    })
    .catch(() => {
      message.error("表单验证失败，请检查输入信息");
    });

  showModal.show = false;
};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

//编辑数据
const editData = () => {
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    treeValue.value = rowData[0]["customer_name"];
    url = interfaces.customerUserEdit;
    formData["id"] = rowData[0]["id"];
    formData["key"] = rowData[0]["key"];
    formData["customer_id"] = rowData[0]["customer_id"];
    columns.forEach((item) => {

      formData[item.dataIndex] = rowData[0][item.dataIndex];

    });
    showModal.show = true;
  }
};

//删除数据
const deleteData = () => {
  let deleteData = [];
  let jsonData = JSON.parse(JSON.stringify(rowData));
  jsonData.forEach((item) => {
    deleteData.push(item.id);
  });
  let params = {
    ids: deleteData,
    real_delete: 1,
  };

  serviceAxios({
    url: interfaces.customerUserDelete,
    method: "post",
    data: params,
  })
    .then((res) => {
      if (res.data.message == "success") {
        message.success("删除成功");
        queryPage();
        selectedRowKey.value = [];
      }
      else {
        message.error(res.data.message);
      }


    })
    .catch((res) => {
      console.log(res);
    });
};



let treeValue = ref()
const queryTree = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      page_num: 1,
      page_size: 9999
    }
  }).then((result) => {

    if (result.status == 200) {
      let blockTree = []
      console.log("result", result);
      result.data.data.list.forEach((item) => {

        blockTree.push({
          id: item.id,
          key: item.id,
          value: item.name + '(' + item.id + ')',
          title: item.name + '(' + item.id + ')',
        })

        console.log(item)
      })
      console.log(22)
      options.treeData = blockTree
      console.log("options.treeData ", options.treeData)
    }


  }).catch(() => { message.error("请求异常，请联系管理员") })
}
queryTree()

let searchFileds = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

// queryPage(pagination.current,pagination.pageSize);
//查询数据
const queryPage = () => {
  serviceAxios({
    url: interfaces.customerUserPage,
    method: "get",
    params: searchFileds
  })
    .then((result) => {
      if (result.status == 200) {
        console.log(result);
        pagination.total = result.data.data.total;

        let scpoeData = [];
        result.data.data.list.forEach((item) => {
          let dataObj = {};
          dataObj.id = item.id;
          dataObj.key = item.id;
          columns.forEach((item1) => {
            dataObj[item1.dataIndex] = item[item1.dataIndex]
          });
          dataObj.customer = item.customer;
          try {
            dataObj.customer_name = item.customer.name + "(" + item.customer.id + ")";
            dataObj.customer_id = item.customer.id;
          }
          catch {
            dataObj.customerName = "无";
            dataObj.customer_id = "";
          }
          scpoeData.push(dataObj);
        });
        dataSource.data = scpoeData;
        console.log("dataSource.data ", dataSource.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
queryPage();






const handleChange = (value, options) => {
  console.log(`selected ${value}`);
  console.log(`option ${options}`);
  let regex = /\((\d+)\)/; // 匹配括号内的数字，并捕获它们  
  let match = value.match(regex);
  console.log(typeof (match[1]))
  formData.customer_id = Number(match[1])
};
















// 用户选择的列键的数组
const selectedColumns = ref([1, 2, 3, 4, 5, 7,]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}
</style>