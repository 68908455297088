<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->

<template>

  <!-- style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" -->

  <a-table :scroll="ne"
           :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">

      <template v-if="['operationalStatus'].includes(column.dataIndex)">
        <div class="status-light">
          <a @click="operationalStatusClick(record.id)"
             v-if='record.operationalStatus'
             class="green-light"></a>
          <a @click="operationalStatusClick(record.id)"
             v-if='!record.operationalStatus'
             class="red-light"></a>

        </div>
      </template>

      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="toGlopt(record.regionId,record.id)">拓扑图</a>
          <a v-if='record.gateway.network_interfaces.length >0'
             class='editable-row-a'
             @click="toMonitoring(record.gateway.network_interfaces,record.id,record.gateway.id)">监控信息</a>
        </div>
      </template>

      <template v-else-if="column.dataIndex === 'router'">
        <a @click=toRouter(record.router)
           class='editable-row-a'>{{record.router}}</a>
      </template>
    </template>
    <template #title>

      <div class='searchDivContainer'
           style="background-color:white">

        <div class='search-left'>
          <a-input-group compact>
            <div class='searchLabel'>
              <label style=''>地区</label>
            </div>
            <a-tree-select :showArrow="false"
                           v-model:value="searchData['region']"
                           show-search
                           style="width: 150px"
                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                           placeholder="选择区域"
                           allow-clear
                           :tree-data="options['region']"
                           tree-node-filter-prop="label">
            </a-tree-select>
          </a-input-group>
          <a-input-group compact
                         style='margin-left:5px'>

            <a-input addonBefore='关键字'
                     v-model:value="searchData.keyword"
                     style="width: 200px"
                     placeholder="请输入关键字">
            </a-input>
          </a-input-group>

        </div>
        <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button>
        <div class="right-buttons">

        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, watch, onBeforeUnmount } from "vue";

import { message } from "ant-design-vue";
import dayjs from "dayjs";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
// import { storeAndGateway } from '@/store'
import interfaces from "@/config";
import { checkTree } from "@/utils/regionTree";
import check from "@/utils/checkLocalStorage";
import { decimalUtils } from "@/utils/decimalUtils";

const locale = zhCN;
const route = useRoute();
const router = useRouter();
// console.log("屏幕数据", document.body.clientWidth, document.body.clientHeight)
const defaultDate = ref(dayjs()); // 使用moment获取当前日期时间作为默认面板日期
console.log("defaultDate", defaultDate)
onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  const localMessage = check.checkData();
  console.log("localMessage", localMessage)
  // 检查用户信息是否存在  
  if (!localMessage) {
    // 如果不存在，则跳转到登录页面  
    router.push('/loginView');
  }

});
let timer = null;
onMounted(() => {
  timer = setInterval(queryPage
    , 30000);
})
onBeforeUnmount(() => {
  clearInterval(timer);
});
dayjs.locale("zh-cn");

//按钮的显示
let buttonShow = reactive({
  contractAdd: true,
  contractDelete: true,
  contractEdit: true,
  contractPage: true,
})

const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}







//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;

  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "id",
  "storeName",
  "brand",
  "officialName",
  "telephone",
  "contactName",
  "phone",
  "planBeginDate",
  "actualDate",
  "endDate",
  "region",
  "full_address",
  "paymentType",
  "duration",
  "payment_status",
  "paymentAmount",
  "invoice_type",
  "invoice_number",
  // "desc",
  // "productService",
  "create_time",
  // "owner",
  "operationalStatus",
  "operation"


]); // 初始选择所有列

const ne = reactive({
  x: "",
  y: ''
})





let showModal = reactive({
  show: false,
  advancedSearch: false,
  storeName: false,
  buttonShow: localStorage.getItem("name") == 'root' ? true : false, //控制删除和编辑按钮的显示
  message: false,
  isDeleteModalVisible: false,
  // test: true
});




//列名字段
const columns = [
  // { title: "ID", dataIndex: "id", key: "id", fixed: "left", width: 80 },
  { title: "门店名称", dataIndex: "storeName", key: "storeName", width: 240, ellipsis: true, },

  // {
  //   title: "品牌名称",
  //   dataIndex: "brand",
  //   key: "brand",
  //   width: 150,
  //   ellipsis: true,
  // },
  {
    title: "区域",
    dataIndex: "region",
    key: "region",
    // fixed: "left",
    width: 200,
    ellipsis: true,
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
    // fixed: "left",
    width: 200,
    ellipsis: true,
  },


  {
    title: "运行状态",
    dataIndex: "operationalStatus",
    key: "operationalStatus",
    width: 100,
  },
  {
    title: "路由管理",
    dataIndex: "router",
    key: "router",
    width: 100,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: 150,
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    fixed: "right",
    width: 100,
  },
];


//table数据源
let dataSource = reactive({
  data: [],


});



let searchData = reactive({

  region: null,
  keyword: ''

});






//页面筛选
const sift = () => {

  queryFields = {
    region_ids: searchData.region,
    keyword: searchData.keyword,
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryPage();
};

//重置筛选
const resetSift = () => {
  pagination.current = 1;
  pagination.pageSize = 10;
  let data = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryFields = data;
  queryPage();
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null;

    }
  }
};

let options = reactive({
  region: [], //地区

});


const getRegionTree = async () => {
  const tree = await checkTree()
  options.region = tree[0]["children"]
}




const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};


let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

const queryPage = () => {
  serviceAxios({
    url: interfaces.hostPage,
    timeout: 30000,
    method: "get",
    params: queryFields,
  })
    .then((res) => {
      if (res.data.message = 'success') {
        pagination.total = res.data.data.total;
        let data = [];
        res.data.data.list.forEach((item) => {
          data.push({
            id: item.id,
            key: item.id,
            brand: item.customer,
            storeName: item.name,
            region: item.region.friendly_name.data,
            regionId: item.region.full_path.data,
            operationalStatus: true,
            create_time: item.create_time,
            gatewayId: item.gateway != undefined ? item.gateway.id : null,
            gateway: item.gateway != undefined ? item.gateway : null,
            address: item.address,

            router: item.gateway != undefined ? item.gateway.vpn_ip : null
          });
        });

        let gatewayIdList = data.filter(item => item.gatewayId != null)
        let ids = []
        gatewayIdList.forEach(item => {
          ids.push(item.gatewayId)
        })

        //网关状态监测
        serviceAxios({
          url: interfaces.onlineStatus,
          method: 'post',
          data: {
            gateway_ids: ids
          }
        }).then(result => {
          if (result.data.message == 'success') {
            let enableGateList = data.filter(item => item.gatewayId != null)
            result.data.data.forEach(
              item => {
                let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.eth_number)
                gatewayStatus.forEach(i => {
                  i.operationalStatus = item.value == '正常' ? true : false
                })
              }
            )
            dataSource.data = data;
          }
          else {
            message.error(result.data.message)
          }
        }).catch(error => {
          console.log(error)
          message.error('请求异常')
        })

      }
      else {
        message.error(res.data.message)
      }

    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
};


getRegionTree();
queryPage();


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const toMonitoring = (networkInterfaces, storeId, gatewayId) => {
  let ids = []
  networkInterfaces.forEach(item => {
    ids.push(item.id)
  })
  ids = ids.join(',')
  // storeAndGateway.value.ids = ids
  // storeAndGateway.value.storeId = storeId

  router.push({

    path: "/show",
    query: { ids: ids, storeId: storeId, gatewayId: gatewayId }

  })
}

const toGlopt = (regionIds, storeId) => {
  console.log(regionIds, storeId)
  let region = regionIds.join(',')
  router.push({ path: "/glopt", query: { region: region, storeId: storeId } })
}

const checkOline = (ids) => {
  serviceAxios({
    url: interfaces.onlineStatus,
    method: 'post',
    data: {
      gateway_ids: ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let enableGateList = dataSource.data.filter(item => item.gatewayId != null)
      result.data.data.forEach(
        item => {
          let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.eth_number)
          gatewayStatus.forEach(i => {
            i.operationalStatus = item.value == '正常' ? true : false
          })
        }
      )
    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
    message.error('请求异常')
  })
}

//路由管理跳转
const toRouter = (url) => {
  window.open('http://' + url, '_blank')
}
</script>
<style lang="less" scoped >
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

.red-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(252, 4, 8);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
}

.searchLabel {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  background-color: #f5f4f4;
}
.search-left {
  display: flex;
}
</style>