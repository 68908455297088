<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <a-table :columns="columns"
           :row-selection="rowSelection"
           :data-source="dataSource">
    <template #bodyCell="{ column }">
      <template v-if="column.dataIndex === 'operation'">
        <a>查看</a>
        <a>删除</a>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'

const dataSource = ref([])

const columns = [
  {
    title: 'title',
    dataIndex: 'title',
  },

  {
    title: 'content',
    dataIndex: 'content',
  },

  {
    title: 'operation',
    dataIndex: 'operation',
  }
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: record => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};



const test = () => {
  for (let i = 0; i < 10; i++) {
    dataSource.value.push({
      key: i,
      title: 'title',
      content: 'content',
      operations: 'operations',
    })
  }
}

test()
</script>