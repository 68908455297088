//获取门店网关的运行状态
import serviceAxios from "./serviceAxios"
import interfaces from "@/config";


export const getStoreGatewayStatus = async()=>{
    try {
        // 第一步：获取门店列表
        const storeListResponse = await serviceAxios({
          url: interfaces.hostPage,
          method: 'get'
        });
        const storeList = storeListResponse.data.data.list;
        
        // 提取所有网关 ID
        const gateWayList = storeList.map(item => item.gateway.id);
    
        // 第二步：获取在线状态
        const onlineStatusResponse = await serviceAxios({
          url: interfaces.onlineStatus,
          method: 'post',
          data: {
            gateway_ids: gateWayList
          }
        });
        const onlineStatusData = onlineStatusResponse.data.data;
    
        // 第三步：筛选出符合条件的门店 ID
        const goodStoreIds = onlineStatusData
          .filter(item => item.value === '正常')
          .map(item => {
            const matchingStore = storeList.find(store => store.gateway.id === item.gateway_id);
            if (matchingStore) {
              return matchingStore.id;
            }
            return null;
          })
          .filter(id => id !== null);
    
        // 返回结果
        return { goodStoreIds };
      } catch (error) {
        console.error('Error in goodStoreStatus:', error);
        throw error;
      }
}