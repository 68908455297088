<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.addOrEdit"
           title="人才信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 13
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in modalData"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.dataIndex">
              <a-select v-if="['is_disabled','telant'].includes(item.dataIndex)"
                        v-model:value="baseData[item.dataIndex]"
                        show-search
                        placeholder="请选择数据"
                        style="width: 200px"
                        :options="options[item.dataIndex]"
                        :filter-option="filterOption"
                        @change="handleChangeSelect(item.dataIndex,$event)"></a-select>
              <a-select v-else-if="['tag_ids'].includes(item.dataIndex)"
                        mode="multiple"
                        v-model:value="baseData[item.dataIndex]"
                        show-search
                        placeholder="请选择数据"
                        style="width: 200px"
                        :options="options['tagSelector']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect(item.dataIndex,$event)"></a-select>
              <a-tree-select v-else-if="['region_ids'].includes(item.dataIndex)"
                             v-model:value="baseData[item.dataIndex]"
                             show-search
                             style="width: 100%"
                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                             placeholder="选择区域"
                             allow-clear
                             multiple
                             :tree-data="treeData"
                             tree-node-filter-prop="label">

              </a-tree-select>
              <div v-else-if="['id_card_front_image_id','id_card_back_image_id','id_card_hand_image_id'].includes(item.dataIndex)">

                <!-- <a-upload v-model:file-list="fileList[item.dataIndex]"
                          :data="{file_type:'id_card_image'}"
                          :action="uploadUrl"
                          list-type="picture"
                          @change="fileHandleChange"
                          :headers="headers">
                  <a-button>
                    <upload-outlined></upload-outlined>
                    上传
                  </a-button>
                </a-upload> -->

                <uploadComponent :fileIds='fileList[item.dataIndex]'
                                 maxCount=1
                                 :fileType='item.title'
                                 :freshFlag='uploadFresh'
                                 @update:fileIds='(event)=>fileChange(event,item.dataIndex)'></uploadComponent>
              </div>
              <a-input v-else
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="columns"
           :scroll="ne"
           :data-source="tableSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a @click="editData(record.key)">编辑</a>
          <a @click="deleteData(record.key)">删除</a>
        </div>
      </template>

    </template>

    <template #title>
      <div class='searchDivContainer'>
        <a-select v-model:value="searchData.searchFiled"
                  show-search
                  placeholder="Select a person"
                  style="width:150px"
                  :options="options.search"
                  @change="handleChangeSearch"></a-select>
        <a-input v-if="['real_name','phone'].includes(searchData.searchFiled)"
                 v-model:value="searchData.input"
                 style="width:150px"
                 :placeholder="`请输入数据`"></a-input>
        <a-tree-select v-if="['region_id'].includes(searchData.searchFiled)"
                       style="width:150px"
                       v-model:value="searchData.region"
                       show-search
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder="请选择地区"
                       allow-clear
                       :tree-data="treeData"
                       tree-node-filter-prop="label">
        </a-tree-select>
        <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button @click="resetSearch"
                  style="margin: 5px">重置</a-button>
        <div class='right-buttons'>
          <!-- <a-cascader v-model:value="cascaderData.casc" :options="options" placeholder="Please select" change-on-select />
        <a-input v-model:value="cascaderData.value" style="width:150px"></a-input>
        <a-button type="primary" @click="searchCasc">搜索</a-button> -->
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.edit"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'
import { checkTree } from "../../utils/regionTree";
import serviceAxios from "@/utils/serviceAxios";
import check from "../../utils/checkLocalStorage";
import { selector } from '@/utils/selector';
import { message } from "ant-design-vue";
import { reactive, ref, onMounted } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import encrypt from "../../utils/encrypt"

import { useScreenSize } from '@/utils/useScreenSize';

const { windowWidth, windowHeight } = useScreenSize();
const ne = reactive({
  x: "",
  y: ''
})
const tableHeight = () => {
  // const searchDivContainer = document.querySelector('.searchDivContainer');
  // const divHeight = searchDivContainer.offsetHeight + 3;
  // const searchTableContainer = document.querySelector('.ant-table-title');
  // const searchTableHeight = searchTableContainer.offsetHeight;
  ne.y = windowHeight.value - 328
  // console.log(windowHeight.value, divHeight, searchTableHeight)
}
const uploadFresh = ref(true)
onMounted(() => {
  tableHeight()

})


const uploadUrl = process.env.VUE_APP_URL + interfaces.commonUpload
const lookPic = process.env.VUE_APP_URL + interfaces.fileGet
const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
  // "Content-Type": "multipart/form-data"
})


onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  const localMessage = check.checkData();
  console.log("localMessage", localMessage)
  // 检查用户信息是否存在  
  if (!localMessage) {
    // 如果不存在，则跳转到登录页面  
    router.push('/login');
  }
});

const fileList = reactive({
  id_card_front_image_id: [],
  id_card_back_image_id: [],
  id_card_hand_image_id: [],
})
const url = {
  add: interfaces.outWorkAdd,
  delete: interfaces.outWorkDelete,
  edit: interfaces.outWorkEdit,
  page: interfaces.outWorkerPage,
}




//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


//区域树
let treeData = reactive()
const getTreeData = async () => {
  // let region = JSON.parse(localStorage.getItem("region"))
  treeData = await checkTree()
  console.log("treeData", treeData)
}
getTreeData()

const value = ref([]);



const filterOption = (input, option) => {

}
// table列名数据
const columns = reactive([
  {
    title: "id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "真实姓名",
    dataIndex: "real_name",
    key: "real_name"
  },
  {
    title: "帐号",
    dataIndex: "username",
    key: "username"
  },
  {
    title: "负责区域",
    dataIndex: "region_name_briefly",
    key: "region_name_briefly",
    width: 300,
    ellipsis: true,
  },
  {
    title: "标签",
    dataIndex: "tag_name_briefly",
    key: "tag_name_briefly",
    width: 150,
    ellipsis: true,
  },
  {
    title: "所属公司",
    dataIndex: "telant",
    key: "telant"
  },
  {
    title: "是否禁用",
    dataIndex: "is_disabled_name",
    key: "is_disabled_name"
  },
  // {
  //   title: "操作",
  //   dataIndex: "operation",
  //   key: "operation"
  // }
]);

//增加或者编辑的模态框规则
const rules = {
  real_name: [
    {
      required: true,
    },
  ],
  username: [
    {
      required: true,
    },
  ],
  password: [
    {
      required: false,
    },
  ],
  is_disabled: [
    {
      required: true,
    },
  ]

}
//table数据源
const tableSource = reactive(
  {
    data: []
  }
)
//选择框数据
const options = reactive({
  brand: "",
  is_disabled: [{ value: 1, label: "是" }, { value: 0, label: "否" }],
  tagSelector: [],
  telant: [],
  search: [{ value: "real_name", label: "姓名" }, { value: "phone", label: "手机号(帐号)" }, { value: "region_id", label: "地区" }]
})

// 搜索框数据
const cascaderData = reactive({
  casc: [],
  value: "",
})

// 搜索框数据
const searchData = reactive({
  searchFiled: "", //搜索字段值
  region: "", //地区
  input: "", //输入框
  brand: "",
  name: "",
  model: "",
  quantity: "",
  desc: "",

})

// 搜索框数据
const searchData2 = reactive({
});

// 数据模型
const baseData = reactive({
  id: "",
  real_name: "",
  username: "",
  password: "",
  hashed_password: "",
  tag_ids: [], //标签

  region_ids: [],
  bank_name: "",
  bank_card_no: "",
  id_card: "",
  id_card_front_id: "",
  id_card_back_id: "",
  id_card_hand_image_id: "",

  "id_card_front_image_id": "",
  "id_card_back_image_id": "",
  "id_card_hand_image_id": "",

  telant: "",
  is_disabled: 1,
})

//弹框字段数据
const modalData = reactive([{
  title: "真实姓名",
  dataIndex: "real_name",
  key: "real_name"
},
{
  title: "手机号",
  dataIndex: "username",
  key: "username"
},
{
  title: "密码",
  dataIndex: "password",
  key: "password"
},
{
  title: "负责区域",
  dataIndex: "region_ids",
  key: "region_ids"
},
{
  title: "标签",
  dataIndex: "tag_ids",
  key: "tag_ids"
},
{
  title: "银行名称",
  dataIndex: "bank_name",
  key: "bank_name"
},
{
  title: "银行卡号",
  dataIndex: "bank_card_no",
  key: "bank_card_no"
},
{
  title: "身份证号",
  dataIndex: "id_card",
  key: "id_card"
},

{
  title: "身份证人像面",
  dataIndex: "id_card_front_image_id",
  key: "id_card_front_image_id"
},
{
  title: "身份证国徽面",
  dataIndex: "id_card_back_image_id",
  key: "id_card_back_image_id"
},
{
  title: "手持身份证照片",
  dataIndex: "id_card_hand_image_id",
  key: "id_card_hand_image_id"
},
{
  title: "所属公司",
  dataIndex: "telant",
  key: "telant"
},
{
  title: "是否禁用",
  dataIndex: "is_disabled",
  key: "is_disabled"
}

])

//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
})
let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
//获取人才信息
const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {
      let region_ids = []
      let tag_ids = []
      let tag_name = []
      let tag_name_briefly = ""

      let region_name = []
      let region_name_briefly = ""

      //区域字段处理
      if (item.regions.length != 0) {
        item.regions.forEach(item1 => {
          region_ids.push(item1.id)
          region_name.push(item1.name)
        })
      }
      region_name_briefly = region_name.length > 1 ? region_name.join(" ") : region_name[0]

      //标签字段处理
      if (item.talent_tags.length != 0) {
        item.talent_tags.forEach(item1 => {
          tag_ids.push(item1.id)
          tag_name.push(item1.name)
        })
      }
      tag_name_briefly = tag_name.length > 1 ? tag_name.join(" ") : tag_name[0]

      data.push({
        id: item.id,
        key: item.id,

        real_name: item.real_name,
        username: item.username,
        is_disabled_name: item.is_disabled == 1 ? "是" : "否",
        is_disabled: item.is_disabled,
        telant: item.tenant_id,  //所属公司
        // operation: item.operation,
        bank_card_no: item.bank_card_no,
        bank_name: item.bank_name,
        id_card: item.id_card,
        id_card_front_image_id: item.id_card_front_image_id,
        id_card_back_image_id: item.id_card_back_image_id,
        id_card_hand_image_id: item.id_card_hand_image_id,
        regions: item.regions,
        talent_tags: item.talent_tags,
        region_ids: region_ids,
        tag_ids: tag_ids,
        tag_name: tag_name,
        tag_name_briefly: tag_name_briefly,
        region_name: region_name,
        region_name_briefly: region_name_briefly,
        hashed_password: item.hashed_password
      })
      // region_ids:item.region_ids,
      // tag_ids:item.tag_ids,

    });
    tableSource.data = data;
    console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })

}
queryPage()
//重置数据模型
const resetForm = () => {
  uploadFresh.value = !uploadFresh.value
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }

  for (let key in fileList) {
    // console.log(key);
    fileList[key] = [];
  }
  baseData['tag_ids'] = []
  baseData['region_ids'] = []
  baseData.is_disabled = 0
};

const formRef = ref()


//编辑数据
const editData = () => {
  uploadFresh.value = !uploadFresh.value
  if (selectedRowKey.value.length === 1) {
    urlFlag = url.edit
    modalShow.addOrEdit = true;
    for (let key in baseData) {
      baseData[key] = rowData[0][key]
    }

    for (let key in fileList) {
      if (rowData[0][key] != null) {
        fileList[key] = [
          {
            file_id: rowData[0][key].id,
            owner_class: rowData[0][key].owner_class,
            owner_id: rowData[0][key].owner_id
          }
        ]
      }
      else {
        fileList[key] = []
      }

    }


  } else {
    message.error("请选择一条数据进行编辑");
  }

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};

//删除数据
const deleteData = () => {
  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    modalShow.isDeleteModalVisible = true;

  }
};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        queryPage()
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const addOrEditHandleOk = () => {
  // baseData.ModalText = "The modal will be closed after two seconds";
  // baseData.confirmLoading = true;
  console.log(baseData);
  let data = {
    //后端所需数据
  }
  formRef.value
    .validate()
    .then(() => {
      let fileImage = {
        id_card_front_image_id: [],
        id_card_back_image_id: [],
        id_card_hand_image_id: [],
      }

      console.log(fileList)
      for (let key in fileList) {
        if (fileList[key].length > 0) {
          fileImage[key] = urlFlag == url.add ? fileList[key] : [fileList[key][0].file_id]

        }
      }

      console.log('fileImage', fileImage)
      let datas = {
        id: baseData.id,
        real_name: baseData.real_name,
        username: baseData.username,
        hashed_password: baseData.password == '' || baseData.password == null ? baseData.hashed_password : encrypt.addUserCrypto(baseData.password),
        tenant_id: baseData.telant,
        tag_ids: baseData.tag_ids,
        is_disabled: baseData.is_disabled,
        phone: baseData.phone,
        region_ids: baseData.region_ids,
        bank_name: baseData.bank_name,
        bank_card_no: baseData.bank_card_no,
        id_card: baseData.id_card,
        id_card_front_image_id: fileImage['id_card_front_image_id'][0] == undefined ? null : fileImage['id_card_front_image_id'][0],
        id_card_back_image_id: fileImage['id_card_back_image_id'][0] == undefined ? null : fileImage['id_card_back_image_id'][0],
        id_card_hand_image_id: fileImage['id_card_hand_image_id'][0] == undefined ? null : fileImage['id_card_hand_image_id'][0]

        // desc: baseData.desc == null ? '' : baseData.desc,
      }
      serviceAxios({
        url: urlFlag,
        method: "post",
        data: datas
      }).then(result => {
        if (result.data.message == 'success') {
          modalShow.addOrEdit = false
          selectedRowKey.value = []
          message.success("操作成功")
          queryPage()
        }
        else {
          message.error(result.data.message)
        }


      }).catch(err => {
        console.log(err)
        message.error("处理数据发生异常，请联系管理员")
      })
    })
    .catch((err) => {
      console.log(err);
      message.error("数据输入错误");
    });
};


const getTicketsSelector = () => {
  serviceAxios(
    {
      url: "",
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      return result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}


const getBrand = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.name,
        });
      });
      options.brand = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};


const handleChangeSelect = (dataIndex, value) => {

};

const fileHandleChange = (info) => {
  console.log(info)
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }

}
const tagSelector = () => {
  serviceAxios({
    url: interfaces.tagPage,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999,
      category: '外勤人员'
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.tagSelector = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
}

const handleChangeSearch = (value) => {
  searchData.region = [], //地区
    searchData.input = '' //输入框
}

//搜索点击事件
const sift = () => {
  queryField = {
    page_num: 1,
    queryField: 10
  }
  if (searchData.searchFiled != "region_id") {
    queryField[searchData.searchFiled] = searchData.input

  }
  else {
    queryField[searchData.searchFiled] = searchData.region

  }
  queryPage()
}


//重置搜索
const resetSearch = () => {
  searchData.region = [] //地区
  searchData.input = '' //输入框
  searchData.searchFiled = "" //搜索字段值
  queryField = {
    page_num: 1,
    queryField: 10
  }
  queryPage()
}



tagSelector()


const fileChange = (event, fileType) => {
  console.log('12312312', event, fileType)
  const file = []

  event.forEach(item => {
    file.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })

  console.log("file", file)
  fileList[fileType] = file

}

(async () => {
  options.telant = await selector.workOrderCompany()
  console.log('options.telant', options.telant)
})()
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}
</style>