<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <a-modal :maskClosable="false"
           v-model:open="isDeleteModalVisible"
           title="确认删除"
           :ok-text="okText"
           :cancel-text="cancelText"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <a-modal :maskClosable="false"
           v-model:open="columnShowModal"
           title="选择列显示"
           @ok="columnHandleOk"
           @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns"
                      :key="column.key"
                      :value="column.key">
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.show"
           title="添加产品"
           width="1000px"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="formData"
            ref="formRef"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in columns"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.dataIndex">
              <a-select v-if="['product_type','product_sub_type','billing_mode'].includes(item.dataIndex)"
                        v-model:value=formData[item.dataIndex]
                        show-search
                        placeholder="Select a person"
                        style="width: 200px"
                        :options="options[item.dataIndex]"
                        :filter-option="filterOption"
                        @focus="handleFocus"
                        @blur="handleBlur"
                        @change="handleChange"></a-select>

              <a-tree v-else-if="['region'].includes(item.dataIndex)"
                      :height="300"
                      v-model:expandedKeys="expandedKeys"
                      v-model:selectedKeys="selectedKeys"
                      v-model:checkedKeys="checkedKeys"
                      checkable
                      defaultExpandAll=false
                      :tree-data="options[item.dataIndex]"
                      @check="handleCheck">
                <template #title="{ title, key }">
                  <span v-if="key === '0-0-1-0'"
                        style="color: #1890ff">{{ title }}</span>
                  <template v-else>{{ title }}</template>
                </template>
              </a-tree>
              <a-select v-model:value=formData[item.dataIndex]
                        v-else-if="['customer_names'].includes(item.dataIndex)"
                        mode="multiple"
                        style="width: 100%"
                        placeholder="Please select"
                        :options=options[item.dataIndex]
                        @change="handleChangeCustomer"></a-select>
              <a-input v-model:value=formData[item.dataIndex]
                       v-else></a-input>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>
  <a-tabs v-model:activeKey="activeKey"
          @change='tabChange'>
    <a-tab-pane v-for="(item) in options.product_sub_type"
                :key="item.value"
                :tab="item.name">
    </a-tab-pane>

  </a-tabs>
  <TableComponent :columns="filteredColumns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['customer_names'].includes(column.dataIndex)">
        <div>
          <a-tooltip>
            <template #title>{{record.customer_label}}</template>
            {{record.customer_names}}
          </a-tooltip>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <a-button type="primary"
                  @click="columnShowModal = true">筛选列显示</a-button>
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </TableComponent>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed, watch } from "vue";

import serviceAxios from "../../../utils/serviceAxios";

import { message } from "ant-design-vue";
import interfaces from "@/config";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

dayjs.locale("zh-cn");

//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (pagination) => {
  const { current, pageSize } = pagination;
  getData(current, pageSize);
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "name",
  "product_type",
  "product_sub_type",
  "customer_names",
  // "region",
  "billing_mode",
  "unit_price",
  "info",
  "desc",
]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

let showModal = reactive({
  show: false,
});
const columns = [
  { title: "产品名称", dataIndex: "name", key: "name" },
  {
    title: "所属类型",
    dataIndex: "product_type",
    key: "product_type",
  },
  { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type" },
  { title: "客户", dataIndex: "customer_names", key: "customer_names" },

  { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode" },
  { title: "收费价格", dataIndex: "unit_price", key: "unit_price" },
  { title: "备注", dataIndex: "info", key: "info" },
  { title: "描述", dataIndex: "desc", key: "desc" },
  { title: "地区", dataIndex: "region", key: "region" },
];
console.log(JSON.stringify(columns));
let dataSource = reactive({});
let formData = reactive({
  name: "",
  product_type: "",
  product_sub_type: "",
  customer_names: "",
  customer_ids: "",
  region: "",
  billing_mode: "",
  unit_price: "",
  info: "",
  desc: "",
});
let options = reactive({
  product_type: "",
  product_sub_type: "",
  region: "",
  customer_names: "",
  billing_mode: "",
  product_sub_type_option: "'信息化工程'"
});

//获取产品类型
const productTypeSelector = () => {
  serviceAxios({
    url: interfaces.product_type_selector,
    method: "get",
    params: {},
  })
    .then((result) => {
      if (result.status == 200) {
        options.product_type = result.data.data;
        console.log(options.product_type);
      } else {
        message.error("获取产品类型发生异常");
      }
    })
    .catch((err) => {
      message.error("发生异常错误，请联系管理员");
      console.log(err);
    });
};
//获取园区产品类型
const industryProductTypeSelector = () => {
  serviceAxios({
    url: interfaces.product_building_product_sub_type,
    method: "get",
    params: {},
  })
    .then((result) => {
      if (result.status == 200) {
        options.product_sub_type = result.data.data;
      } else {
        message.error("获取行业产品类型发生异常");
      }
    })
    .catch((err) => {
      message.error("发生异常错误，请联系管理员");
      console.log(err);
    });
};
const tabChange = (activeKey) => {
  console.log(activeKey);
  options.product_sub_type_option = activeKey
  getData()
};

const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.name,
        key: item.id,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.name,
        key: item.id,
      });
    }
  });
  return response;
};
const regionTree = () => {
  serviceAxios({
    url: interfaces.reginalTree,
    method: "get",
  })
    .then((result) => {
      options.region = clearRegionalTree(result.data.data);
      console.log("options.region", options.region)
    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
};


//重置数据
const resetForm = () => {
  for (let key in formData) {
    // console.log(key);
    formData[key] = "";
  }
  formData.region = "";
  formData.customer_names = []
  formData.customer_ids = []
  checkedKeys.value = []
  console.log("reset")
};
let url = ""
//添加
const addData = () => {
  showModal.show = true;
  url = interfaces.productAdd
  resetForm()

};

//编辑数据
const editData = () => {
  console.log();
  url = interfaces.productEdit
  showModal.show = true;
  for (let key in formData) {
    formData[key] = rowData[0][key];
  }
  formData.info = ""
  formData.customer_names = rowData[0].customer_ids
  let region = []
  rowData[0].regions.forEach(item => {
    region.push(item.id)
  })
  console.log("regionregion", region)
  checkedKeys.value = region
  formData.id = rowData[0].id
};
let isDeleteModalVisible = ref();
const deleteData = () => {
  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    isDeleteModalVisible.value = true;
  }
};


//删除
const handleOkDelete = () => {
  // 执行删除操作
  console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: interfaces.productDelete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(getData())
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
  isDeleteModalVisible.value = false;
};
const handleCancelDelete = () => {
  // 取消删除操作，关闭模态框
  isDeleteModalVisible.value = false;
};


const handleOk = () => {
  const data = {
    id: formData.id,
    name: formData.name,
    product_type: formData.product_type,
    customer_ids: formData.customer_names,
    region_ids: formData.region,
    product_sub_type: formData.product_sub_type,
    billing_mode: formData.billing_mode,
    unit_price: formData.unit_price,
    info: {},
    desc: formData.desc

  }
  serviceAxios({
    url: url,
    method: "post",
    data: data,
  })
    .then(() => {
      showModal.show = false;

      showModal.show = false;
      getData();
      selectedRowKey.value = [];
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
  console.log("datadata", data)

  // formRef.value.resetFields()
};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

// const filter = (inputValue, path) => {
//   return path.some(
//     (option) =>
//       option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
//   );
// };

const handleChangeCustomer = (value) => {
  console.log(`selected ${value}`);
};

const getCustomer_names = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "园区产品",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.customer_names = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};

const getBilling_mode = () => {
  serviceAxios({
    url: interfaces.product_billing_mode,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.billing_mode = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};

const getData = () => {
  serviceAxios({

    url: interfaces.productPage,
    method: "get",
    params: {
      product_type: "行业信息化",
      product_sub_type: options.product_sub_type_option,
      page_num: pagination.current,
      page_size: pagination.pageSize,
    },
  })
    .then((res) => {
      let data = [];

      res.data.data.list.forEach((item) => {
        let customer_name = "";
        let customer_label = [];
        let customer_ids = [];
        item.customers.forEach((cus) => {
          customer_label.push(cus.name);
          customer_ids.push(cus.id);
        });
        if (item.customers.length > 1) {
          customer_name =
            item.customers[0].name + "等" + item.customers.length + "位客户";
        } else if (item.customers.length == 1) {
          customer_name = item.customers[0].name;
        }
        data.push({
          id: item.id,
          key: item.id,
          name: item.name,
          product_type: item.product_type,
          product_sub_type: item.product_sub_type,
          billing_mode: item.billing_mode,
          unit_price: item.unit_price,
          info: item.info,
          desc: item.desc,
          customer_names: customer_name,
          customer_label: customer_label,
          customer_ids: customer_ids,
          customers: item.customers,
          regions: item.regions,
        });
      });
      dataSource.data = data;
      console.log(dataSource.data);
    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
};
// getData();
getCustomer_names();
regionTree();
industryProductTypeSelector();
productTypeSelector();
getBilling_mode();





// 监听 change 事件
// function handleTreeSelectChange(value, label, extra) {
//   console.log("value", value, label, extra);

// }


const expandedKeys = ref(['0-0-0', '0-0-1']);
const selectedKeys = ref(['0-0-0', '0-0-1']);
const checkedKeys = ref(['0-0-0', '0-0-1']);
watch(expandedKeys, () => {
  console.log('expandedKeys', expandedKeys);
});
watch(selectedKeys, () => {
  console.log('selectedKeys', selectedKeys);
});
watch(checkedKeys, () => {
  console.log('checkedKeys', checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node)
  formData.region = checked
};
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}
.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}
</style>