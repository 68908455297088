import { message } from 'ant-design-vue'; 
import serviceAxios from '@/utils/serviceAxios'; 
import interfaces from '@/config';

// 二级门店信息  品牌-门店
export async function storeMessage (has_gateway) {
  //has_gateway :有3个取值 null， 全部，1，配置过网关的， 0没配置过网关的
  console.log('has_gateway', has_gateway)
  let contractData = {};

  // 返回axios调用的Promise
  try {
    const result = await serviceAxios({
      url: interfaces.customerUserPage,
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
        has_gateway: has_gateway,
      }
    });
    result.data.data.list.forEach(item => {
      if (contractData[item.customer.id] != undefined) {

        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.id,
          label: item.name,
          brand: item.customer.name,
          storeName: item.name,
          address: item.address,
          region: item.region_id,
        })
      }
      else {

        contractData[item.customer.id] = {}
        contractData[item.customer.id].children = []
        contractData[item.customer.id].brand = item.customer.name
        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.id,
          label: item.name,
          brand: item.customer.name,
          storeName: item.name,
          address: item.address,
          region: item.region_id,
        })
      }
    })


    let opContract = []
    for (let key in contractData) {
      opContract.push({
        label: contractData[key].brand,
        value: key,
        children: contractData[key].children
      }
      )
    }
    // ... (构建opContract)
    console.log("contractData", opContract);
    return opContract;
  } catch (err) {
    message.error("请求异常，请联系管理员");
    console.log(err);
    return await Promise.reject({ error: true, message: "请求异常，请联系管理员" });
  }
}