<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/require-valid-default-prop -->
<template>
  <!-- :data="fileData" -->
  <a-input addonBefore='文件备注'
           v-if='props.inputShow'
           v-model:value="aliasRef"
           style='width:300px;margin:5px' />
  <a-upload v-model:file-list="fileList"
            :action="uploadUrl"
            list-type="picture"
            :headers="headers"
            :before-upload="beforeUpload"
            :data='{type:props.fileType,alias:aliasRef}'
            @change="handleChange"
            :fileIds="props.fileIds"
            :fresh="props.freshFlag"
            :max-count="props.maxCount">

    <a-button v-if='props.isButtonShow'
              style='margin:5px'>
      <upload-outlined></upload-outlined>
      {{props.buttonName}}
    </a-button>
  </a-upload>
</template>

<script setup>
import serviceAxios from '@/utils/serviceAxios'
import interfaces from '@/config'
import { ref, reactive, watch } from 'vue'
import { message } from "ant-design-vue";
const props = defineProps({
  fileType: {
    type: String,
    default: "data_attachment",
  },
  // fileList: {
  //   type: Array,
  //   // eslint-disable-next-line vue/require-valid-default-prop
  //   default: [{
  //     uid: '-1',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   }]
  // },
  fileIds: {
    type: Array,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: [],
  },
  buttonName: {
    type: String,
    default: "上传",
  },

  ///v1/oss/file_type/selector
  fileType: {
    type: String,
    default: "一般文件",
  },

  //刷新组件
  freshFlag: {
    type: Boolean,
    default: false,
  },
  //上传按钮的显示
  isButtonShow: {
    type: Boolean,
    default: true,
  },
  maxCount: {
    type: Number,
  },
  inputShow: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits(['update:fileIds']);
let isImage = true
const handleChange = (info) => {

  console.log('fileList', fileList.value)
  console.log(info)
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
    emit('update:fileIds', info.fileList);
  }
  if (info.file.status === 'done') {
    console.log('done_info', info)
    // eslint-disable-next-line vue/no-mutating-props

    emit('update:fileIds', info.fileList);
    message.success(`${info.file.name} 上传成功`);

    let fileAlias = aliasRef.value == undefined ? "" : `(${aliasRef.value})`

    fileList.value[fileList.value.length - 1].name = fileList.value[fileList.value.length - 1].name + fileAlias
  }
  else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }




}

const uploadUrl = process.env.VUE_APP_URL + interfaces.ossUpload
const lookPic = process.env.VUE_APP_URL + interfaces.ossGet
const headers = {
  authorization: "Bearer " + localStorage.getItem("token")
}

// 定义文件别名
const aliasRef = ref()
//文件的类型 可以从/v1/file/get_file_type_selector接口获取
const fileData = {
  file_type: props.fileType,
}

//文件
const fileList = ref([
  //   {
  //   uid: '-1',
  //   name: 'image.png',
  //   status: 'done',
  //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  // }

])

// 动态获取图片 URL 的函数
const getProxyImageUrl = async (originalUrl, file) => {
  try {
    const response = await serviceAxios({
      url: originalUrl,
      method: 'get',
      headers: headers,
      params: {
        file_id: file.file_id,
        owner_class: file.owner_class,
        owner_id: file.owner_id,
      },
      responseType: 'blob' // 设置响应类型为 blob
    });
    // 创建 Blob URL
    const blob = new Blob([response.data], { type: 'image/png' }); //
    const url = URL.createObjectURL(blob);
    console.log('url', url)
    return url;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};

const getImageInfo = async (file) => {
  const response = await serviceAxios({
    url: interfaces.ossGetFileInfo,
    method: 'get',
    params: {
      file_id: file.file_id
    }
  })
  const data = response.data.data
  let fileAlias = data.alias == '' || data.alias == 'undefined' ? "" : `(${data.alias})`
  return {
    name: data.name + fileAlias,
    id: data.id
  }
}
// 更新 fileList 中的 url 和 thumbUrl
const updateFileListUrls = async () => {
  fileList.value = []
  for (const file of props.fileIds) {
    // const url = lookPic;
    const responseUrl = await getProxyImageUrl(lookPic, file);
    const responsrImageInfo = await getImageInfo(file)
    fileList.value.push(
      {
        uid: responsrImageInfo.id,
        name: responsrImageInfo.name,
        status: 'done',
        url: responseUrl,
        isOld: true, //用于判断是否是新上传的图片
      }
    )
  }
};


updateFileListUrls()


//刷新组件
watch(() => props.freshFlag, (newVal, oldVal) => {
  console.log('新旧数据', newVal, oldVal)


  updateFileListUrls();

});



const beforeUpload = (file) => {

  return new Promise((resolve) => {
    console.log('beforeUpload triggered', file);
    isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error(`${file.name} 不是一个图片文件`);
      return false
    }
    console.log('beforeUpload isImage', isImage)
    // aliasRef.value = ""
    return resolve(true);
  });

};

</script>
