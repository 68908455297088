<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <a-modal :maskClosable="false"
           v-model:open="isDeleteModalVisible"
           title="确认删除"
           :ok-text="okText"
           :cancel-text="cancelText"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <a-modal :maskClosable="false"
           v-model:open="columnShowModal"
           title="选择列显示"
           @ok="columnHandleOk"
           @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns"
                      :key="column.key"
                      :value="column.key">
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.show"
           title="产品信息"
           width="800px"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in modalData"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.dataIndex">
              <a-select v-if="['product_type','product_sub_type','billing_mode','is_package'].includes(item.dataIndex)"
                        v-model:value="baseData[item.dataIndex]"
                        show-search
                        :placeholder="`请选择${item.title}`"
                        style="width: 200px"
                        :options="options[item.dataIndex]"
                        :filter-option="filterOption"
                        @focus="handleFocus"
                        @blur="handleBlur"
                        @change="handleChange"></a-select>

              <a-tree v-else-if="['region'].includes(item.dataIndex)"
                      :height="300"
                      v-model:expandedKeys="expandedKeys"
                      v-model:selectedKeys="selectedKeys"
                      v-model:checkedKeys="checkedKeys"
                      checkable
                      defaultExpandAll=false
                      :tree-data="options['region']"
                      @check="handleCheck">
              </a-tree>
              <!-- <a-tree-select v-else-if="['region'].includes(item.dataIndex)" v-model:value="baseData.region" show-search
                style="width: 200px" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :placeholder="`请选择${item.title}`" allow-clear multiple :tree-data="options['region']"
                :fieldNames="{children:'children', label:'title', value: 'key' }" tree-node-filter-prop="title">

              </a-tree-select> -->

              <a-input-number v-else-if="['unit_price'].includes(item.dataIndex)"
                              style="width:200px"
                              v-model:value="baseData[item.dataIndex]"
                              :step="0.01"
                              :controls="false"
                              :placeholder="`请输入${item.title}`"></a-input-number>
              <a-select v-else-if="['customer_ids'].includes(item.dataIndex)"
                        v-model:value="baseData[item.dataIndex]"
                        style="width:200px"
                        mode="multiple"
                        :placeholder="`请选择${item.title}`"
                        :options="options[item.dataIndex]"
                        @change="handleChangeCustomer"
                        :filter-option="filterOption"></a-select>
              <a-select v-else-if="['children_ids'].includes(item.dataIndex)"
                        v-model:value="baseData[item.dataIndex]"
                        :disabled="baseData.is_package==0?true:false"
                        style="width:200px"
                        mode="multiple"
                        :placeholder="`请选择${item.title}`"
                        :options="options[item.dataIndex]"
                        @change="handleChangeCustomer"
                        :filter-option="filterOption"></a-select>
              <a-input v-else
                       v-model:value="baseData[item.dataIndex]"
                       style="width:200px"
                       :placeholder="`请输入${item.title}`"></a-input>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>
  <a-tabs v-model:activeKey="activeKey"
          @change='tabChange'>
    <a-tab-pane v-for="(item) in options.product_sub_type"
                :key="item.value"
                :tab="item.label">
    </a-tab-pane>

  </a-tabs>
  <TableComponent :columns="filteredColumns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">

      <template v-if="['customer_names'].includes(column.dataIndex)">
        <div>
          <a-tooltip>
            <template #title>{{record.customer_label}}</template>
            {{record.customer_names}}
          </a-tooltip>
        </div>
      </template>
      <template v-else-if="column.dataIndex == 'operation'">
        <div class="editable-row-operations">
          <a style="margin:2px;color: #1884ea;"
             @click="editData(record)">编辑</a>
          <a style="margin:2px;color: #1884ea;"
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <a-cascader
          v-model:value="value"
          :options="options"
          placeholder="Please select"
          change-on-select
        />
        <a-input style="width:150px"></a-input>
        <a-button type="primary">搜索</a-button> -->
        <!-- <a-button type="primary" @click="columnShowModal = true">筛选列显示</a-button> -->
        <div class="right-buttons">
          <a-button v-if="buttonShow.productAdd"
                    class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <!-- <a-button v-if="buttonShow.productEdit" class="addDataButton" type="primary" @click="editData">编辑数据</a-button> -->
          <a-button v-if="buttonShow.productDelete"
                    class="addDataButton"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </TableComponent>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import serviceAxios from "@/utils/serviceAxios";
import { decimalUtils } from '@/utils/decimalUtils';
import removeNullValues from "@/utils/removeNullValues";
import check from "@/utils/checkLocalStorage";
import { checkTree } from "@/utils/regionTree";
import { message } from "ant-design-vue";
import interfaces from "@/config";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";



onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  const localMessage = check.checkData();
  console.log("localMessage", localMessage)
  // 检查用户信息是否存在  
  if (!localMessage) {
    // 如果不存在，则跳转到登录页面  
    router.push('/login');
  }
});


dayjs.locale("zh-cn");
const route = useRoute();
console.log("route", route.fullPath)
//按钮的显示
let buttonShow = reactive({
  productAdd: true,
  productDelete: true,
  productEdit: true,
  productPage: true,
})

const treeFiledRelations = { children: 'children', title: 'label', key: 'value' }
const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()




let formRef = ref()
//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage();

};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "name",
  "product_type",
  "product_sub_type",
  "customer_names",
  "regions_str",
  "billing_mode",
  "unit_price",
  "operation",
  "is_package_name",
  "children_name",
  "info",
  "desc",
]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

let showModal = reactive({
  show: false,
});
const columns = [
  { title: "产品名称", dataIndex: "name", key: "name", fixed: "left", width: "150px", ellipsis: true },
  { title: "所属类型", dataIndex: "product_type", key: "product_type", width: "120px", ellipsis: true },
  { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
  { title: "品牌", dataIndex: "customer_names", key: "customer_names", width: "150px", ellipsis: true },
  { title: "地区", dataIndex: "regions_str", key: "regions_str", width: "100px", width: "150px", ellipsis: true },
  { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode", width: "100px", ellipsis: true },
  { title: "收费金额", dataIndex: "unit_price", key: "unit_price", width: "100px", ellipsis: true },
  { title: "产品包", dataIndex: "is_package_name", key: "is_package_name", width: "100px", ellipsis: true },
  { title: "产品包服务", dataIndex: "children_name", key: "children_name", width: "200px", ellipsis: true },
  { title: "描述", dataIndex: "desc", key: "desc" },
  { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

];



//模态框模型

const modalData = reactive([

  { title: "产品名称", dataIndex: "name", key: "name" },
  { title: "所属类型", dataIndex: "product_type", key: "product_type", },
  { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type" },
  { title: "品牌", dataIndex: "customer_ids", key: "customer_ids" },
  { title: "地区", dataIndex: "region", key: "region" },
  { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode" },
  { title: "收费金额", dataIndex: "unit_price", key: "unit_price" },
  { title: "是否为套餐包", dataIndex: "is_package", key: "is_package" },
  { title: "子产品", dataIndex: "children_ids", key: "children_ids" },
  { title: "描述", dataIndex: "desc", key: "desc" },
])


const rules = {
  name: [{ required: true, message: "", }],
  product_type: [
    {
      required: true,
      message: "",
    },
  ],
  customer_ids: [
    {
      required: true,
      message: "",
    },
  ],
  is_package: [
    {
      required: true,
      message: "",
    },
  ],
  product_sub_type: [
    {
      required: true,
      message: "",
    },
  ],
  billing_mode: [
    {
      required: true,
      message: "",
    },
  ],
  unit_price: [
    {
      required: true,
      message: "",
    },
  ],
  region: [{
    required: true,
    message: "",
  },]
};
let dataSource = reactive({});
let baseData = reactive({
  name: "",
  product_type: "",
  product_sub_type: "",
  customer_names: "",
  customer_ids: "",
  region: "",
  billing_mode: "",
  unit_price: "",
  // info: "",
  is_package: "",
  children_ids: [],
  desc: "",
});
let options = reactive({
  product_type: "",
  product_sub_type: "",
  region: "",
  customer_ids: "",
  billing_mode: "",
  product_sub_type_option: "信息化工程",
  children_ids: [], //非产品包的产品
  is_package: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
});

//获取产品类型
const productTypeSelector = () => {
  serviceAxios({
    url: interfaces.product_type_selector,
    method: "get",
    params: {},
  })
    .then((result) => {
      if (result.status == 200) {
        let data = []
        result.data.data.forEach(item => {
          data.push(
            {
              label: item.name,
              value: item.value
            }
          )
        })
        options.product_type = data;
      } else {
        message.error("获取产品类型发生异常");
      }
    })
    .catch((err) => {
      message.error("发生异常错误，请联系管理员");
      console.log(err);
    });
};
//获取行业产品类型
const industryProductTypeSelector = () => {
  serviceAxios({
    url: interfaces.product_industry_product_sub_type,
    method: "get",
    params: {},
  })
    .then((result) => {
      if (result.data.message == 'success') {
        let data = []
        result.data.data.forEach(item => {
          data.push(
            {
              label: item.name,
              value: item.value
            }
          )
        })
        //AIOps 1
        //互联网接入 2
        //信息化工程 3 
        //IT维保 4
        //其他服务 5
        const orderMap = {
          "AIOps": 1,
          "互联网接入": 2,
          "信息化工程": 3,
          "IT维保": 4,
          "其他服务": 5
        };
        data.sort((a, b) => orderMap[a.label] - orderMap[b.label]);

        options.product_sub_type = data;
      } else {
        message.error(result.data.message);
      }
    })
    .catch((err) => {
      message.error("发生异常错误，请联系管理员");
      console.log(err);
    });
};

const tabChange = (activeKey) => {
  console.log(activeKey);
  queryField.product_sub_type = activeKey
  // options.product_sub_type_option = activeKey;

  queryPage();
};

const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
      });
    }
  });
  return response;
};

const getRegionTree = async () => {
  const tree = await checkTree()
  console.log("结构树数据", tree)
  options.region = clearRegionalTree(tree)
};

//重置数据
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
    if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
      baseData[key] = [];
    }
  }

  checkedKeys.value = [];
};
let url = "";
//添加
const addData = () => {
  url = interfaces.productAdd;
  resetForm();
  showModal.show = true;

};

//编辑数据
const editData = (record) => {
  console.log("record", record)
  url = interfaces.productEdit;
  showModal.show = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData.info = "";
  baseData.customer_ids = record.customer_ids.length == 0 ? ['全部'] : record.customer_ids;
  let region = [];
  record.regions.forEach((item) => {
    region.push(item.id);
  });
  console.log("regionregion", region);
  checkedKeys.value = region;
  baseData.region = region;
  baseData.id = record.id;

  baseData.children_ids = record.children_ids

};
let isDeleteModalVisible = ref();

let deleteFlag = reactive({
  flag: "",
  data: ""
}) //判断是勾选删除还是点击删除
const deleteData = (data) => {
  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    isDeleteModalVisible.value = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      isDeleteModalVisible.value = true;
      deleteFlag.flag = "much"
    }
  }

};

//删除
const handleOkDelete = () => {
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }


  serviceAxios({
    url: interfaces.productDelete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          queryNotPackage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  isDeleteModalVisible.value = false;
};
const handleCancelDelete = () => {
  // 取消删除操作，关闭模态框
  isDeleteModalVisible.value = false;
};

//提交
const handleOk = () => {
  formRef.value
    .validate().then(() => {
      let customer_ids = []
      if (baseData.customer_ids.includes("全部")) {
        customer_ids = []
      }
      else {
        customer_ids = baseData.customer_ids
      }
      let data = {
        id: baseData.id,
        name: baseData.name,
        product_type: baseData.product_type,
        customer_ids: customer_ids,
        region_ids: baseData.region,
        product_sub_type: baseData.product_sub_type,
        billing_mode: baseData.billing_mode,
        unit_price: baseData.unit_price,
        // info: {},
        operator_id: Number(localStorage.getItem("id")),
        is_package: baseData.is_package,
        children_ids: baseData.children_ids,
        desc: baseData.desc == null ? "" : baseData.desc,
      };
      // data = removeNullValues(data);
      serviceAxios({
        url: url,
        method: "post",
        data: data,
      })
        .then((result) => {
          if (result.data.message == 'success') {
            showModal.show = false;

            queryPage();
            selectedRowKey.value = [];
            message.success('成功')
          }
          else {
            message.error(result.data.message);
          }

        })
        .catch(() => {
          message.error("请求异常，请联系管理员");
        });
      console.log("datadata", data);
    }).catch((error) => {

      console.log("error", error)
      message.error("请检查输入的数据")
    })


};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};


const handleChangeCustomer = (value) => {
  console.log(`selected ${value}`);
};

const getCustomer_names = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = []
      data.push({
        label: "全部",
        value: "全部",
      });
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.customer_ids = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};

const getBilling_mode = () => {
  serviceAxios({
    url: interfaces.product_billing_mode,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.billing_mode = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  product_type: "行业信息化",
  product_sub_type: '',
})

//table数据查询
const queryPage = () => {
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: queryField
  })
    .then((res) => {
      let data = [];
      pagination.total = res.data.data.total;

      res.data.data.list.forEach((item) => {
        let customer_name = "";
        let customer_label = [];
        let customer_ids = [];
        let regions_str = []
        let children_ids = []
        let children_name = []
        item.customers.forEach((cus) => {
          customer_label.push(cus.name);
          customer_ids.push(cus.id);
        });
        item.regions.forEach(region => {
          regions_str.push(region.name)
        })
        if (item.is_package == 1) {
          item.children.forEach(child => {
            children_ids.push(child.id)
            children_name.push(child.name)
          })
          children_name = children_name.join(" ")
        }
        else {
          children_name = ""
        }
        regions_str = regions_str.join(" ")
        customer_name = customer_label.join(" ")
        data.push({
          id: item.id,
          key: item.id,
          name: item.name,
          product_type: item.product_type,
          product_sub_type: item.product_sub_type,
          billing_mode: item.billing_mode,
          unit_price: decimalUtils.ensureAtLeastTwoDecimals(item.unit_price),
          info: item.info,
          desc: item.desc,
          // children: item.children,
          children_ids: children_ids,
          children_name: children_name,
          is_package: item.is_package,
          is_package_name: item.is_package == 0 ? "否" : "是",
          customer_names: customer_name == '' ? "全部品牌" : customer_name,
          customer_label: customer_label,
          customer_ids: customer_ids,
          customers: item.customers,
          regions_str: regions_str,
          regions: item.regions,
        });
      });
      dataSource.data = data;
      console.log(dataSource.data);
    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
};
queryPage();
getCustomer_names();
getRegionTree();
industryProductTypeSelector();
productTypeSelector();
getBilling_mode();



const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};


//select的搜索事件
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


//查询非产品包的产品
const queryNotPackage = () => {
  let params = {
    is_package: 0,
    page_num: 1,
    page_size: 9999,
  }
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: params
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        })
      })
      options.children_ids = data
    })
}
queryNotPackage()
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}
.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}
.editable-row-operations {
  margin: 2px;
}
</style>