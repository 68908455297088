<!-- eslint-disable vue/no-v-model-argument -->


<template>

  <a-layout style="min-height: 100vh">

    <a-layout>
      <a-layout-sider class="homeViwe-sider">
        <img style="width: 150px; height:80px;;"
             src="@/assets/whiteSize.png"
             alt="Logo" />
        <a-menu style='width: 200px'
                theme="dark"
                id="dddddd"
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeysSide"
                mode="inline"
                :items="items.navigation_sides"
                @click="handleClickSide"
                @openChange="handleOpenChange"></a-menu>

      </a-layout-sider>
      <a-layout>
        <a-layout-header class="layout-header-demo">
          <BreadcrumbComponent style="margin-left:-30px" />
          <div class="header-right">
            <span class="user-info">{{companyName}}</span>
            <span class="user-info">{{ userName }}</span>
            <span class="user-info-logout"
                  @click="handleLogout">{{ "退出" }}</span>
          </div>
        </a-layout-header>

        <a-layout-content style="margin: 0 16px"
                          class="content">
          <!-- background: '#fff' -->
          <div :style="{ padding: '0px',  }">
            <router-view></router-view>
          </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"
                         class="footer">
          AIOPS ©2024 Created by Yuhe Information Technology
        </a-layout-footer>
      </a-layout>

    </a-layout>

  </a-layout>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { HomeTwoTone, AppstoreTwoTone, FileTextTwoTone } from '@ant-design/icons-vue';
import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';
import { ref, watch, reactive, h } from "vue";
// import router from "@/router";
import serviceAxios from "@/utils/serviceAxios";
import interfaces from "@/config";
import { message } from "ant-design-vue";
import { useRouter, useRoute } from 'vue-router';
let router = useRouter()
const userName = ref("")
const companyName = ref('')
// let breadcrumb_info = ref("");
const selectedKeysSide = ref();
const selectedKeysHeader = ref()
watch(selectedKeysSide, (selectedKeysSide) => {
  console.log("selectedKeysSide", selectedKeysSide)
  // localStorage.setItem("selectedKeysSide", selectedKeysSide)
})

const openKeys = ref([]);
const items = reactive({
  navigation_headers: [],
  navigation_sidesAll: {},
  navigation_sides: []

});

const handleClickSide = (menuInfo) => {
  if (menuInfo.key != undefined)
    console.log('click ', menuInfo);
  // breadcrumb_info.value = menuInfo.label;
  console.log("menuInfo", menuInfo)
  router.push(menuInfo.item.originItemValue.key);
};




const handleLogout = () => {
  let role = localStorage.getItem("role")
  localStorage.clear()
  router.push("/loginView")

};

let rules = {}
const clearMenu = (data) => {
  let childrenData = []
  if (data != undefined) {

    data.forEach((item) => {
      let scopData = {
        id: String(item.id),
        key: item.id,
        is_bottom: item.is_bottom,
        title: item.title,
        label: item.label,
        keyPath: item.key
      }
      if (item.children.length != 0) {
        scopData.children = clearMenu(item.children)
      }
      childrenData.push(scopData)


      if (item.is_bottom == 1 && item.rules != undefined) {
        rules[item.key] = []
        item.rules.forEach((rule) => {
          rules[item.key].push(rule.rule_path)
        })
      }
    }
    )
    return childrenData

  }
  else {
    return []
  }
}

//查询用户权限菜单
const queryUserMenu = () => {
  serviceAxios({
    url: interfaces.systemMe,
    method: "get",
    // data:''
  })
    .then((result) => {

      let headersData = [];
      let sidesdate = [];



      localStorage.setItem("id", result.data.id)
      localStorage.setItem("role", result.data.role.name)
      localStorage.setItem("sides", JSON.stringify(sidesdate))
      localStorage.setItem("userName", result.data.username)
      localStorage.setItem("companyName", result.data.tenant.name)
      userName.value = result.data.username
      companyName.value = result.data.tenant.name


      console.log("sidesdate", sidesdate)
      items.navigation_sides = specialRules




    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err)
    });
};
//要求特定的路由
let specialRules = [

  {
    "id": "90",
    "key": "/homePageData",
    "label": "首页",
    "order": 0,
    "title": "首页",
    icon: () => h(HomeTwoTone),

    "is_bottom": 1,
    "parent_id": 25,

  },
  {
    "id": "91",
    "key": "/门店列表",
    "label": "门店信息",
    "order": 1,
    icon: () => h(AppstoreTwoTone),
    "title": "门店信息",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {
        "id": "92",
        "key": "/customerView",
        "label": "门店列表",
        "order": 0,
        "title": "门店列表",
        "is_bottom": 1,
        "parent_id": 91,

      },
      // {
      //   "id": "93",
      //   "key": "/storeMessage",
      //   "label": "运行状态",
      //   "order": 1,
      //   "title": "运行状态",
      //   "is_bottom": 1,
      //   "parent_id": 91,

      // },
      {
        "id": "94",
        "key": "/glopt",
        "label": "拓扑数据",
        "order": 2,
        "title": "拓扑数据",
        "is_bottom": 1,
        "parent_id": 91,

      }
    ]
  },
  {
    "id": "95",
    "key": "/gongdandemo",
    "label": "工单管理",
    "order": 2,
    icon: () => h(FileTextTwoTone),
    "title": "工单管理",
    "is_bottom": 0,
    "parent_id": 25,
    "children": [
      {
        "id": "96",
        "key": "/ticketView",
        "label": "工单列表",
        "order": 2,
        "title": "工单列表",
        "is_bottom": 1,
        "parent_id": 95,

      },
      {
        "id": "98",
        "key": "/addTicket",
        "label": "添加工单",
        "order": 3,
        "title": "添加工单",
        "is_bottom": 1,
        "parent_id": 95,
      },

    ]
  },
  {
    "id": "99",
    "key": "/accountManage",
    "label": "账户管理",
    "order": 4,
    icon: () => h(AppstoreTwoTone),
    "title": "账户管理",
    "is_bottom": 1,
    "parent_id": 0,
  }

]


//检查权限
const checkPermissios = () => {
  if (localStorage.getItem("id") == null) {
    queryUserMenu()
  }
  else {
    userName.value = localStorage.getItem("userName")
    companyName.value = localStorage.getItem("companyName")
    items.navigation_sides = specialRules

  }
}
checkPermissios();

watch(openKeys, (val) => {
  localStorage.setItem("openKeys", JSON.stringify(val))
});
const handleOpenChange = (keys) => {
  console.log("openkeys", keys)
  if (keys.length > 1) {
    openKeys.value = [keys[keys.length - 1]]; // 仅保留最后一个打开的key
  } else {
    openKeys.value = keys; // 否则直接更新 openKeys
  }
}
</script>
<style lang="less" scoped>
.ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  color: white;
  background-color: #48466d;
}

.layout-header-demo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #10092c;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
}

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto; /* 使得用户名和退出按钮位于最右边 */
}

.user-info {
  margin-right: 10px; /* 根据需要调整用户名和退出按钮之间的间距 */
  font-size: 17px;
  color: rgb(134, 134, 136);
}

.user-info-logout {
  margin-right: 10px; /* 根据需要调整用户名和退出按钮之间的间距 */
  font-size: 17px;
  cursor: pointer;
  color: rgb(134, 134, 136);
}

.homeViwe-sider {
  background-color: #10092c;
}

.footer {
  height: 30px;
}
</style>